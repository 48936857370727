import { takeLatest, call, put, select } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import { ActionTypes as sharedActionTypes } from 'shared/constants';
import networkLayer from '../utils/networkLayer';
import utils, { isAfter } from '../utils';
import notification from '../utils/notifications';
import Api from '../api';
import { history, projectsAll } from 'shared/routes/urlLocations';
import IsUser from 'shared/utils/user/IsUser';
import { projectEdit, notifications } from 'shared/routes/urlLocations';


const API_URL = `${utils.config.apiProjectUrl}/projects/search`;

function* getPublishedProjects({ params }) {
  yield put({
    type: ActionTypes.INVESTOR_GET_PUBLISHED_PROJECTS_REQUEST_STARTED
  });
  yield put({ type: ActionTypes.SHOW_SPINNER });

  const { response, request, status } = yield call(networkLayer.getJson, API_URL, {
    params: {
      projection: params.projection || 'short',
      sort: params.sort || 'publishedDate,desc',
      page: params.page || '0',
      size: params.size || '12'
    }
  });

  if (request.status && request.status === 200) {
    yield put({
      type: ActionTypes.INVESTOR_GET_PUBLISHED_PROJECTS_REQUEST_SUCCESS,
      payload: response
    });
  } else if (request && request.status === 401) {
    yield call(notification, { type: 'error', data: response, status });
    yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
  } else {
    yield put({
      type: ActionTypes.INVESTOR_GET_PUBLISHED_PROJECTS_REQUEST_FAILURE
    });
    console.log({ ...response });
  }

  yield put({
    type: ActionTypes.INVESTOR_GET_PUBLISHED_PROJECTS_REQUEST_FINISHED
  });
  yield put({ type: ActionTypes.HIDE_SPINNER });
}

function* patchInvestorData({ values }) {
  values.userType = 'INVESTOR';
  yield put({ type: ActionTypes.INVESTOR_PATCH_INFO_STARTED });
  yield put({ type: ActionTypes.SHOW_SPINNER });
  try {
    const { response, request, status } = yield call(
      networkLayer.putJson,
      `${utils.config.apiUserUrl}/user/me`,
      {
        data: { ...values }
      }
    );
    if ((request.status && request.status === 200) || 204) {
      yield put({
        type: ActionTypes.INVESTOR_PATCH_INFO_SUCCESS,
        payload: response
      });
      yield call(notification, {
        type: 'success',
        data: { code: 1 },
        staticNotification: true
      });

      yield put({ type: sharedActionTypes.USER_INFORMATION_REQUEST });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({ type: ActionTypes.INVESTOR_PATCH_INFO_FAILURE });
      yield call(notification, { type: 'error', data: response });
    }
  } catch (e) {
    console.log(e);
  } finally {
    yield put({ type: ActionTypes.INVESTOR_PATCH_INFO_FINISHED });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* getProjectDetails({ id }) {
  const { 
    user: {
      info: { userType } 
    } 
  } = yield select();

  try {
    yield put({ type: ActionTypes.INVESTOR_GET_PROJECT_DETAILS_STARTED });

    const { response, request, status } = yield call(
      Api.Projects.getProjectDetails,
      `${utils.config.apiProjectUrl}/projects/${id}?projection=details`
    );

    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.INVESTOR_GET_PROJECT_DETAILS_SUCCESS,
        payload: response
      });
      // Only for link on notification page
      // TODO - remove this after add handling on BE
      // Redirect to projectEdit view after click on ${projectDetails} link PROJECT_REJECTED notification
      const isUser = new IsUser(userType);      
      if (isUser.allIssuers && response.endDate) {
        const endDate = response.endDate;
        const isProjectFinished = isAfter(new Date(), endDate);
        if (history.location && history.location.state){
          if ((history.location.state.from === notifications) && !isProjectFinished) {
            yield history.replace({ pathname: `${projectEdit}/${id}` });
          }
        }
      }

    } else if (request && request.status === 401) {
      yield call(notification, {
        type: 'error',
        data: {
          description: 'You have not permission to look this project!'
        }
      });
      yield history.push(projectsAll);
    } else {
      yield put({ type: ActionTypes.INVESTOR_GET_PROJECT_DETAILS_FAILURE, payload: response });
    }
  } catch (error) {
    yield put({ type: ActionTypes.INVESTOR_GET_PROJECT_DETAILS_FAILURE });
    yield call(notification, {
      type: 'error',
      data: 'Project details loading error'
    });
    yield history.push(projectsAll);
  } finally {
    yield put({ type: ActionTypes.INVESTOR_GET_PROJECT_DETAILS_FINISHED });
  }
}

export default function* watchInvestorUser() {
  yield takeLatest(ActionTypes.INVESTOR_GET_PUBLISHED_PROJECTS_REQUEST, getPublishedProjects);
  yield takeLatest(ActionTypes.INVESTOR_PATCH_INFO, patchInvestorData);
  yield takeLatest(ActionTypes.INVESTOR_GET_PROJECT_DETAILS, getProjectDetails);
}
