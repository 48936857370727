import { config } from '../utils';
import NetworkLayer from '../utils/networkLayer';

class ActiveRecord {
  /**
   * Fetching lists
   *
   * @param url
   * @param params
   * @returns {*}
   */

  fetchList = (url, params) => {
    const request = {
      params
    };
    return NetworkLayer.getJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  /**
   * Remove item by id/id lists
   *
   * @param url
   * @returns {*}
   */

  remove = url => {
    return NetworkLayer.removeJson(url)
      .then(response => response)
      .catch(e => e);
  };
}

export default ActiveRecord;
