import keyMirror from 'fbjs/lib/keyMirror';

export const ActionTypes = keyMirror({
  PROMPT_VISIBILITY: undefined,

  CHECK_CONFIRM_EMAIL_TOKEN_REQUEST_STARTED: undefined,
  CHECK_CONFIRM_EMAIL_TOKEN_REQUEST: undefined,
  CHECK_CONFIRM_EMAIL_TOKEN_REQUEST_SUCCESS: undefined,
  CHECK_CONFIRM_EMAIL_TOKEN_REQUEST_FAILURE: undefined,
  CHECK_CONFIRM_EMAIL_TOKEN_REQUEST_FINISHED: undefined,

  CHECK_USED_TOKEN_REQUEST_STARTED: undefined,
  CHECK_USED_TOKEN_REQUEST: undefined,
  CHECK_USED_TOKEN_REQUEST_FAILURE: undefined,
  CHECK_USED_TOKEN_REQUEST_SUCCESS: undefined,
  CHECK_USED_TOKEN_REQUEST_FINISHED: undefined,

  RESTORE_PASSWORD_REQUEST: undefined,
  RESTORE_PASSWORD_REQUEST_STARTED: undefined,
  RESTORE_PASSWORD_REQUEST_SUCCESS: undefined,
  RESTORE_PASSWORD_REQUEST_FAILURE: undefined,
  RESTORE_PASSWORD_REQUEST_FINISHED: undefined,

  FORGOT_PASSWORD_REQUEST: undefined,
  FORGOT_PASSWORD_REQUEST_STARTED: undefined,
  FORGOT_PASSWORD_REQUEST_SUCCESS: undefined,
  FORGOT_PASSWORD_REQUEST_FAILURE: undefined,
  FORGOT_PASSWORD_REQUEST_FINISHED: undefined,
  FORGOT_PASSWORD_RESET_FORM: undefined,

  USER_LOGIN_REQUEST: undefined,
  USER_LOGIN_REQUEST_STARTED: undefined,
  USER_LOGIN_REQUEST_FINISHED: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,

  PROJECT_APPROVE_REQUEST: undefined,
  PROJECT_APPROVE_REQUEST_STARTED: undefined,
  PROJECT_APPROVE_REQUEST_SUCCESS: undefined,
  PROJECT_APPROVE_REQUEST_FAILURE: undefined,
  PROJECT_APPROVE_REQUEST_FINISHED: undefined,

  PROJECT_REJECT_REQUEST: undefined,
  PROJECT_REJECT_REQUEST_STARTED: undefined,
  PROJECT_REJECT_REQUEST_SUCCESS: undefined,
  PROJECT_REJECT_REQUEST_FAILURE: undefined,
  PROJECT_REJECT_REQUEST_FINISHED: undefined,

  USER_LOGOUT_REQUEST: undefined,
  USER_LOGOUT_REQUEST_STARTED: undefined,
  USER_LOGOUT_REQUEST_FINISHED: undefined,
  USER_LOGOUT_REQUEST_SUCCESS: undefined,
  USER_LOGOUT_REQUEST_FAILURE: undefined,

  CONFIRM_EMAIL_REQUEST_STARTED: undefined,
  CONFIRM_EMAIL_REQUEST: undefined,
  CONFIRM_EMAIL_REQUEST_SUCCESS: undefined,
  CONFIRM_EMAIL_REQUEST_FAILURE: undefined,
  CONFIRM_EMAIL_REQUEST_FINISHED: undefined,

  INVESTOR_CONFIRM_REQUEST_STARTED: undefined,
  INVESTOR_CONFIRM_REQUEST: undefined,
  INVESTOR_CONFIRM_REQUEST_SUCCESS: undefined,
  INVESTOR_CONFIRM_REQUEST_FAILURE: undefined,
  INVESTOR_CONFIRM_REQUEST_FINISHED: undefined,

  ISSUER_REGISTRATION_REQUEST_START: undefined,
  ISSUER_REGISTRATION_REQUEST: undefined,
  ISSUER_REGISTRATION_REQUEST_FINISHED: undefined,
  ISSUER_REGISTRATION_SUCCESS: undefined,
  ISSUER_REGISTRATION_ERROR: undefined,

  CHANGE_PASSWORD_STARTED: undefined,
  CHANGE_PASSWORD: undefined,
  CHANGE_PASSWORD_SUCCESS: undefined,
  CHANGE_PASSWORD_FAILURE: undefined,
  CHANGE_PASSWORD_FINISHED: undefined,

  SP_UPDATE_LOGO_STARTED: undefined,
  SP_UPDATE_LOGO: undefined,
  SP_UPDATE_LOGO_SUCCESS: undefined,
  SP_UPDATE_LOGO_FAILURE: undefined,
  SP_UPDATE_LOGO_FINISHED: undefined,

  SP_PUT_LOGO_URL_STARTED: undefined,
  SP_PUT_LOGO_URL: undefined,
  SP_PUT_LOGO_URL_SUCCESS: undefined,
  SP_PUT_LOGO_URL_FAILURE: undefined,
  SP_PUT_LOGO_URL_FINISHED: undefined,

  GET_PLATFORM_DETAILS_STARTED: undefined,
  GET_PLATFORM_DETAILS: undefined,
  GET_PLATFORM_DETAILS_SUCCESS: undefined,
  GET_PLATFORM_DETAILS_FAILURE: undefined,
  GET_PLATFORM_DETAILS_FINISHED: undefined,

  DELETE_NOTIFICATION_STARTED: undefined,
  DELETE_NOTIFICATION: undefined,
  DELETE_NOTIFICATION_SUCCESS: undefined,
  DELETE_NOTIFICATION_FAILURE: undefined,
  DELETE_NOTIFICATION_FINISHED: undefined,

  UPLOAD_NOTIFICATIONS_STARTED: undefined,
  UPLOAD_NOTIFICATIONS: undefined,
  UPLOAD_NOTIFICATIONS_SUCCESS: undefined,
  UPLOAD_NOTIFICATIONS_FAILURE: undefined,
  UPLOAD_NOTIFICATIONS_FINISHED: undefined,

  MARK_NOTIFICATION_AS_READ_STARTED: undefined,
  MARK_NOTIFICATION_AS_READ: undefined,
  MARK_NOTIFICATION_AS_READ_SUCCESS: undefined,
  MARK_NOTIFICATION_AS_READ_FAILURE: undefined,
  MARK_NOTIFICATION_AS_READ_FINISHED: undefined,

  INVESTOR_REGISTRATION_REQUEST_START: undefined,
  INVESTOR_REGISTRATION_REQUEST: undefined,
  INVESTOR_REGISTRATION_REQUEST_FINISHED: undefined,
  INVESTOR_REGISTRATION_SUCCESS: undefined,
  INVESTOR_REGISTRATION_ERROR: undefined,

  INVESTOR_COMPANY_REGISTRATION_REQUEST_STARTED: undefined,
  INVESTOR_COMPANY_REGISTRATION_REQUEST: undefined,
  INVESTOR_COMPANY_REGISTRATION_REQUEST_FINISHED: undefined,
  INVESTOR_COMPANY_REGISTRATION_SUCCESS: undefined,
  INVESTOR_COMPANY_REGISTRATION_ERROR: undefined,

  INVESTOR_INDIVIDUAL_REGISTRATION_REQUEST_STARTED: undefined,
  INVESTOR_INDIVIDUAL_REGISTRATION_REQUEST: undefined,
  INVESTOR_INDIVIDUAL_REGISTRATION_REQUEST_FINISHED: undefined,
  INVESTOR_INDIVIDUAL_REGISTRATION_SUCCESS: undefined,
  INVESTOR_INDIVIDUAL_REGISTRATION_ERROR: undefined,

  INVESTOR_GET_PROJECT_DETAILS_STARTED: undefined,
  INVESTOR_GET_PROJECT_DETAILS: undefined,
  INVESTOR_GET_PROJECT_DETAILS_SUCCESS: undefined,
  INVESTOR_GET_PROJECT_DETAILS_FAILURE: undefined,
  INVESTOR_GET_PROJECT_DETAILS_FINISHED: undefined,
  INVESTOR_CLEAR_PROJECT_DETAILS: undefined,

  REMOVE_PROJECT_STARTED: undefined,
  REMOVE_PROJECT: undefined,
  REMOVE_PROJECT_SUCCESS: undefined,
  REMOVE_PROJECT_FAILURE: undefined,
  REMOVE_PROJECT_FINISHED: undefined,

  PROJECT_ALL_DATA_GET_REQUEST: undefined,
  PROJECT_ALL_DATA_GET_REQUEST_STARTED: undefined,
  PROJECT_ALL_DATA_GET_REQUEST_SUCCESS: undefined,
  PROJECT_ALL_DATA_GET_REQUEST_FAILURE: undefined,
  PROJECT_ALL_DATA_GET_REQUEST_FINISHED: undefined,

  PROJECT_ALL_DATA_CLEAR: undefined,

  PROJECT_REJECTIONS_HISTORY_GET_REQUEST: undefined,
  PROJECT_REJECTIONS_HISTORY_GET_REQUEST_STARTED: undefined,
  PROJECT_REJECTIONS_HISTORY_GET_REQUEST_SUCCESS: undefined,
  PROJECT_REJECTIONS_HISTORY_GET_REQUEST_FAILURE: undefined,
  PROJECT_REJECTIONS_HISTORY_GET_REQUEST_FINISHED: undefined,

  PROJECT_REJECTIONS_HISTORY_CLEAR: undefined,

  PROJECTS_SEARCH_GET_RESULT: undefined,
  PROJECTS_SEARCH_GET_RESULT_STARTED: undefined,
  PROJECTS_SEARCH_GET_RESULT_SUCCESS: undefined,
  PROJECTS_SEARCH_GET_RESULT_FAILURE: undefined,
  PROJECTS_SEARCH_GET_RESULT_FINISHED: undefined,

  PROJECTS_SEARCH_FILTERS_SET: undefined,

  PROJECTS_SEARCH_SET_TAG: undefined,

  PROJECTS_SEARCH_TOGGLE_SEARCH_BAR: undefined,

  ADVISOR_REGISTRATION_REQUEST_STARTED: undefined,
  ADVISOR_REGISTRATION_REQUEST: undefined,
  ADVISOR_REGISTRATION_REQUEST_SUCCESS: undefined,
  ADVISOR_REGISTRATION_REQUEST_FAILURE: undefined,
  ADVISOR_REGISTRATION_REQUEST_FINISHED: undefined,

  ADVISOR_UPLOAD_CV_FILE_REQUEST_STARTED: undefined,
  ADVISOR_UPLOAD_CV_FILE_REQUEST: undefined,
  ADVISOR_UPLOAD_CV_FILE_REQUEST_SUCCESS: undefined,
  ADVISOR_UPLOAD_CV_FILE_REQUEST_FAILURE: undefined,
  ADVISOR_UPLOAD_CV_FILE_REQUEST_FINISHED: undefined,

  ADVISOR_CREATE_CV_FILE_REQUEST_STARTED: undefined,
  ADVISOR_CREATE_CV_FILE_REQUEST: undefined,
  ADVISOR_CREATE_CV_FILE_REQUEST_SUCCESS: undefined,
  ADVISOR_CREATE_CV_FILE_REQUEST_FAILURE: undefined,
  ADVISOR_CREATE_CV_FILE_REQUEST_FINISHED: undefined,

  PRIVATE_BANKER_REGISTRATION_REQUEST_STARTED: undefined,
  PRIVATE_BANKER_REGISTRATION_REQUEST: undefined,
  PRIVATE_BANKER_REGISTRATION_REQUEST_SUCCESS: undefined,
  PRIVATE_BANKER_REGISTRATION_REQUEST_FAILURE: undefined,
  PRIVATE_BANKER_REGISTRATION_REQUEST_FINISHED: undefined,

  DELETE_PRIVATE_BANKER_STARTED: undefined,
  DELETE_PRIVATE_BANKER: undefined,
  DELETE_PRIVATE_BANKER_SUCCESS: undefined,
  DELETE_PRIVATE_BANKER_FAILURE: undefined,
  DELETE_PRIVATE_BANKER_FINISHED: undefined,

  STRATEGIC_PARTNER_REQUEST_STARTED: undefined,
  STRATEGIC_PARTNER_REQUEST: undefined,
  STRATEGIC_PARTNER_REQUEST_SUCCESS: undefined,
  STRATEGIC_PARTNER_REQUEST_FAILURE: undefined,
  STRATEGIC_PARTNER_REQUEST_FINISHED: undefined,

  STRATEGIC_PARTNER_FILES_UPLOAD: undefined,
  STRATEGIC_PARTNER_FILES_UPLOAD_SUCCESS: undefined,

  STRATEGIC_PARTNER_KYC_CHECK_REQUEST_STARTED: undefined,
  STRATEGIC_PARTNER_KYC_CHECK_REQUEST: undefined,
  STRATEGIC_PARTNER_KYC_CHECK_REQUEST_SUCCESS: undefined,
  STRATEGIC_PARTNER_KYC_CHECK_REQUEST_FAILURE: undefined,
  STRATEGIC_PARTNER_KYC_CHECK_REQUEST_FINISHED: undefined,

  INVESTOR_PATCH_INFO_STARTED: undefined,
  INVESTOR_PATCH_INFO_SUCCESS: undefined,
  INVESTOR_PATCH_INFO: undefined,
  INVESTOR_PATCH_INFO_FAILURE: undefined,
  INVESTOR_PATCH_INFO_FINISHED: undefined,

  INVESTOR_GET_PUBLISHED_PROJECTS_REQUEST_STARTED: undefined,
  INVESTOR_GET_PUBLISHED_PROJECTS_REQUEST: undefined,
  INVESTOR_GET_PUBLISHED_PROJECTS_REQUEST_SUCCESS: undefined,
  INVESTOR_GET_PUBLISHED_PROJECTS_REQUEST_FAILURE: undefined,
  INVESTOR_GET_PUBLISHED_PROJECTS_REQUEST_FINISHED: undefined,

  SP_WORKERS_LIST_REQUEST: undefined,
  SP_WORKERS_LIST_REQUEST_STARTED: undefined,
  SP_WORKERS_LIST_REQUEST_SUCCESS: undefined,
  SP_WORKERS_LIST_REQUEST_FAILURE: undefined,
  SP_WORKERS_LIST_REQUEST_FINISHED: undefined,

  SP_WORKER_CREATE: undefined,
  SP_WORKER_CREATE_STARTED: undefined,
  SP_WORKER_CREATE_SUCCESS: undefined,
  SP_WORKER_CREATE_FAILURE: undefined,
  SP_WORKER_CREATE_FINISHED: undefined,

  SP_WORKER_ACTIVATION_REQUEST: undefined,
  SP_WORKER_ACTIVATION_REQUEST_STARTED: undefined,
  SP_WORKER_ACTIVATION_REQUEST_SUCCESS: undefined,
  SP_WORKER_ACTIVATION_REQUEST_FAILURE: undefined,
  SP_WORKER_ACTIVATION_REQUEST_FINISHED: undefined,

  SP_ADMINS_LIST_REQUEST: undefined,
  SP_ADMINS_LIST_REQUEST_STARTED: undefined,
  SP_ADMINS_LIST_REQUEST_SUCCESS: undefined,
  SP_ADMINS_LIST_REQUEST_FAILURE: undefined,
  SP_ADMINS_LIST_REQUEST_FINISHED: undefined,

  SP_ADMIN_CREATE: undefined,
  SP_ADMIN_CREATE_STARTED: undefined,
  SP_ADMIN_CREATE_SUCCESS: undefined,
  SP_ADMIN_CREATE_FAILURE: undefined,
  SP_ADMIN_CREATE_FINISHED: undefined,

  SP_ADMIN_ACTIVATION_REQUEST: undefined,
  SP_ADMIN_ACTIVATION_REQUEST_STARTED: undefined,
  SP_ADMIN_ACTIVATION_REQUEST_SUCCESS: undefined,
  SP_ADMIN_ACTIVATION_REQUEST_FAILURE: undefined,
  SP_ADMIN_ACTIVATION_REQUEST_FINISHED: undefined,

  SP_ADMIN_KYC_REQUEST: undefined,
  SP_ADMIN_KYC_REQUEST_STARTED: undefined,
  SP_ADMIN_KYC_REQUEST_SUCCESS: undefined,
  SP_ADMIN_KYC_REQUEST_FAILURE: undefined,
  SP_ADMIN_KYC_REQUEST_FINISHED: undefined,

  SP_INVESTOR_REGISTRATION: undefined,
  SP_INVESTOR_REGISTRATION_STARTED: undefined,
  SP_INVESTOR_REGISTRATION_SUCCESS: undefined,
  SP_INVESTOR_REGISTRATION_FAILURE: undefined,
  SP_INVESTOR_REGISTRATION_FINISHED: undefined,

  SP_INVESTORS_LIST_FETCH: undefined,
  SP_INVESTORS_LIST_FETCH_STARTED: undefined,
  SP_INVESTORS_LIST_FETCH_SUCCESS: undefined,
  SP_INVESTORS_LIST_FETCH_FAILURE: undefined,
  SP_INVESTORS_LIST_FETCH_FINISHED: undefined,

  SP_ISSUER_REGISTRATION: undefined,
  SP_ISSUER_REGISTRATION_STARTED: undefined,
  SP_ISSUER_REGISTRATION_SUCCESS: undefined,
  SP_ISSUER_REGISTRATION_FAILURE: undefined,
  SP_ISSUER_REGISTRATION_FINISHED: undefined,

  SP_ISSUERS_LIST_FETCH: undefined,
  SP_ISSUERS_LIST_FETCH_STARTED: undefined,
  SP_ISSUERS_LIST_FETCH_SUCCESS: undefined,
  SP_ISSUERS_LIST_FETCH_FAILURE: undefined,
  SP_ISSUERS_LIST_FETCH_FINISHED: undefined,

  SP_COMPLIANCE_OFFICER_APPROVE_CONTACT: undefined,
  SP_COMPLIANCE_OFFICER_APPROVE_CONTACT_STARTED: undefined,
  SP_COMPLIANCE_OFFICER_APPROVE_CONTACT_SUCCESS: undefined,
  SP_COMPLIANCE_OFFICER_APPROVE_CONTACT_FAILURE: undefined,
  SP_COMPLIANCE_OFFICER_APPROVE_CONTACT_FINISHED: undefined,

  SP_COMPLIANCE_OFFICER_REJECT_CONTACT: undefined,
  SP_COMPLIANCE_OFFICER_REJECT_CONTACT_STARTED: undefined,
  SP_COMPLIANCE_OFFICER_REJECT_CONTACT_SUCCESS: undefined,
  SP_COMPLIANCE_OFFICER_REJECT_CONTACT_FAILURE: undefined,
  SP_COMPLIANCE_OFFICER_REJECT_CONTACT_FINISHED: undefined,

  ONE_TIME_SECURITY_CODE_REQUEST: undefined,
  ONE_TIME_SECURITY_CODE_REQUEST_STARTED: undefined,
  ONE_TIME_SECURITY_CODE_REQUEST_FINISHED: undefined,
  ONE_TIME_SECURITY_CODE_REQUEST_SUCCESS: undefined,
  ONE_TIME_SECURITY_CODE_REQUEST_FAILURE: undefined,

  REFRESH_TOKEN_REQUEST_STARTED: undefined,
  REFRESH_TOKEN_REQUEST: undefined,
  REFRESH_TOKEN_REQUEST_SUCCESS: undefined,
  REFRESH_TOKEN_REQUEST_FAILURE: undefined,
  REFRESH_TOKEN_REQUEST_FINISHED: undefined,

  ISSUER_PROJECT_CREATION_REQUEST_STARTED: undefined,
  ISSUER_PROJECT_CREATION_REQUEST: undefined,
  ISSUER_PROJECT_CREATION_REQUEST_SUCCESS: undefined,
  ISSUER_PROJECT_CREATION_REQUEST_FAILURE: undefined,
  ISSUER_PROJECT_CREATION_REQUEST_FINISHED: undefined,

  ISSUER_ADD_PROJECT_FILES_REQUEST_STARTED: undefined,
  ISSUER_ADD_PROJECT_FILES_REQUEST: undefined,
  ISSUER_ADD_PROJECT_FILES_REQUEST_SUCCESS: undefined,
  ISSUER_ADD_PROJECT_FILES_REQUEST_FAILURE: undefined,
  ISSUER_ADD_PROJECT_FILES_REQUEST_FINISHED: undefined,

  ISSUER_PROJECT_EDIT_FILES_STARTED: undefined,
  ISSUER_PROJECT_EDIT_FILES: undefined,
  ISSUER_PROJECT_EDIT_FILES_SUCCESS: undefined,
  ISSUER_PROJECT_EDIT_FILES_FAILURE: undefined,
  ISSUER_PROJECT_EDIT_FILES_FINISHED: undefined,

  ISSUER_PROJECT_CREATE_PUBLISH_STARTED: undefined,
  ISSUER_PROJECT_CREATE_PUBLISH: undefined,
  ISSUER_PROJECT_CREATE_PUBLISH_SUCCESS: undefined,
  ISSUER_PROJECT_CREATE_PUBLISH_FAILURE: undefined,
  ISSUER_PROJECT_CREATE_PUBLISH_FINISHED: undefined,

  ISSUER_PROJECT_EDIT_PUBLISH_STARTED: undefined,
  ISSUER_PROJECT_EDIT_PUBLISH: undefined,
  ISSUER_PROJECT_EDIT_PUBLISH_SUCCESS: undefined,
  ISSUER_PROJECT_EDIT_PUBLISH_FAILURE: undefined,
  ISSUER_PROJECT_EDIT_PUBLISH_FINISHED: undefined,

  ISSUER_PROJECT_EDIT_STARTED: undefined,
  ISSUER_PROJECT_EDIT: undefined,
  ISSUER_PROJECT_EDIT_LOCK: undefined,
  ISSUER_PROJECT_EDIT_SUCCESS: undefined,
  ISSUER_PROJECT_EDIT_FAILURE: undefined,
  ISSUER_PROJECT_EDIT_FINISHED: undefined,

  ISSUER_PROJECT_GET_FULL_REQUEST_STARTED: undefined,
  ISSUER_PROJECT_GET_FULL_REQUEST_SUCCESS: undefined,
  ISSUER_PROJECT_GET_FULL_REQUEST: undefined,
  ISSUER_PROJECT_GET_FULL_REQUEST_FAILURE: undefined,
  ISSUER_PROJECT_GET_FULL_REQUEST_FINISHED: undefined,

  ISSUER_PROJECT_SAVE_FILE_LINK: undefined,
  ISSUER_PROJECT_SAVE_FILE_LINKS: undefined,

  ISSUER_PROJECT_UPDATE_FILE: undefined,
  ISSUER_PROJECT_UPDATE_FILES: undefined,

  ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE_STARTED: undefined,
  ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE_SUCCESS: undefined,
  ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE: undefined,
  ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE_FAILURE: undefined,
  ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE_FINISHED: undefined,

  ISSUER_PROJECT_REMOVE_PICTURE: undefined,

  ISSUER_CLEAR_FILE_FIELD_STARTED: undefined,
  ISSUER_CLEAR_FILE_FIELD_SUCCESS: undefined,
  ISSUER_CLEAR_FILE_FIELD: undefined,
  ISSUER_CLEAR_FILE_FIELD_FAILURE: undefined,
  ISSUER_CLEAR_FILE_FIELD_FINISHED: undefined,

  ISSUER_PROJECT_DELETE_FILE: undefined,
  ISSUER_PROJECT_EDIT_DELETE_FILES: undefined,
  ISSUER_PROJECT_CREATE_DELETE_FILES: undefined,

  ISSUER_FETCH_SAVED_PROJECTS: undefined,
  ISSUER_FETCH_SAVED_PROJECTS_SUCCESS: undefined,
  ISSUER_FETCH_SAVED_PROJECTS_FAILURE: undefined,

  ISSUER_FETCH_PUBLISHED_PROJECTS: undefined,
  ISSUER_FETCH_PUBLISHED_PROJECTS_SUCCESS: undefined,
  ISSUER_FETCH_PUBLISHED_PROJECTS_FAILURE: undefined,

  ISSUER_FETCH_PROJECTS_STARTED: undefined,
  ISSUER_FETCH_PROJECTS_FINISHED: undefined,

  ISSUER_GET_SAVED_PROJECTS: undefined,
  ISSUER_GET_SAVED_PROJECTS_STARTED: undefined,
  ISSUER_GET_SAVED_PROJECTS_SUCCESS: undefined,
  ISSUER_GET_SAVED_PROJECTS_FAILURE: undefined,
  ISSUER_GET_SAVED_PROJECTS_FINISHED: undefined,

  ISSUER_GET_ONGOING_PROJECTS: undefined,
  ISSUER_GET_ONGOING_PROJECTS_STARTED: undefined,
  ISSUER_GET_ONGOING_PROJECTS_SUCCESS: undefined,
  ISSUER_GET_ONGOING_PROJECTS_FAILURE: undefined,
  ISSUER_GET_ONGOING_PROJECTS_FINISHED: undefined,

  ISSUER_GET_FINISHED_PROJECTS: undefined,
  ISSUER_GET_FINISHED_PROJECTS_STARTED: undefined,
  ISSUER_GET_FINISHED_PROJECTS_SUCCESS: undefined,
  ISSUER_GET_FINISHED_PROJECTS_FAILURE: undefined,
  ISSUER_GET_FINISHED_PROJECTS_FINISHED: undefined,

  ISSUER_GET_PENDING_PROJECTS: undefined,
  ISSUER_GET_PENDING_PROJECTS_STARTED: undefined,
  ISSUER_GET_PENDING_PROJECTS_SUCCESS: undefined,
  ISSUER_GET_PENDING_PROJECTS_FAILURE: undefined,
  ISSUER_GET_PENDING_PROJECTS_FINISHED: undefined,

  SP_ISSUER_GET_SAVED_PROJECTS: undefined,
  SP_ISSUER_GET_SAVED_PROJECTS_STARTED: undefined,
  SP_ISSUER_GET_SAVED_PROJECTS_SUCCESS: undefined,
  SP_ISSUER_GET_SAVED_PROJECTS_FAILURE: undefined,
  SP_ISSUER_GET_SAVED_PROJECTS_FINISHED: undefined,

  SP_ISSUER_GET_ONGOING_PROJECTS: undefined,
  SP_ISSUER_GET_ONGOING_PROJECTS_STARTED: undefined,
  SP_ISSUER_GET_ONGOING_PROJECTS_SUCCESS: undefined,
  SP_ISSUER_GET_ONGOING_PROJECTS_FAILURE: undefined,
  SP_ISSUER_GET_ONGOING_PROJECTS_FINISHED: undefined,

  SP_ISSUER_GET_FINISHED_PROJECTS: undefined,
  SP_ISSUER_GET_FINISHED_PROJECTS_STARTED: undefined,
  SP_ISSUER_GET_FINISHED_PROJECTS_SUCCESS: undefined,
  SP_ISSUER_GET_FINISHED_PROJECTS_FAILURE: undefined,
  SP_ISSUER_GET_FINISHED_PROJECTS_FINISHED: undefined,

  SP_ISSUER_GET_PENDING_PROJECTS: undefined,
  SP_ISSUER_GET_PENDING_PROJECTS_STARTED: undefined,
  SP_ISSUER_GET_PENDING_PROJECTS_SUCCESS: undefined,
  SP_ISSUER_GET_PENDING_PROJECTS_FAILURE: undefined,
  SP_ISSUER_GET_PENDING_PROJECTS_FINISHED: undefined,

  ISSUER_PROJECT_CREATE_SAVE_FILE_STARTED: undefined,
  ISSUER_PROJECT_CREATE_SAVE_FILE: undefined,
  ISSUER_PROJECT_CREATE_SAVE_FILE_SUCCESS: undefined,
  ISSUER_PROJECT_CREATE_SAVE_FILE_FAILURE: undefined,
  ISSUER_PROJECT_CREATE_SAVE_FILE_FINISHED: undefined,

  ISSUER_PROJECT_CREATE_ADD_FILE_LINK: undefined,

  ISSUER_PROJECT_EDIT_SAVE_FILE_STARTED: undefined,
  ISSUER_PROJECT_EDIT_SAVE_FILE: undefined,
  ISSUER_PROJECT_EDIT_SAVE_FILE_SUCCESS: undefined,
  ISSUER_PROJECT_EDIT_SAVE_FILE_FAILURE: undefined,
  ISSUER_PROJECT_EDIT_SAVE_FILE_FINISHED: undefined,

  ISSUER_PROJECT_EDIT_ADD_FILE_LINK: undefined,

  ISSUER_PROJECT_CREATE_SAVE_FILES_LINKS: undefined,
  ISSUER_PROJECT_CREATE_SAVE_FILES_LINKS_STARTED: undefined,
  ISSUER_PROJECT_CREATE_SAVE_FILES_LINKS_SUCCESS: undefined,
  ISSUER_PROJECT_CREATE_SAVE_FILES_LINKS_FAILURE: undefined,
  ISSUER_PROJECT_CREATE_SAVE_FILES_LINKS_FINISHED: undefined,

  ISSUER_PROJECT_CREATE_ADD_FILES_LINKS: undefined,

  ISSUER_PROJECT_EDIT_SAVE_FILES_LINKS: undefined,
  ISSUER_PROJECT_EDIT_SAVE_FILES_LINKS_STARTED: undefined,
  ISSUER_PROJECT_EDIT_SAVE_FILES_LINKS_SUCCESS: undefined,
  ISSUER_PROJECT_EDIT_SAVE_FILES_LINKS_FAILURE: undefined,
  ISSUER_PROJECT_EDIT_SAVE_FILES_LINKS_FINISHED: undefined,

  ISSUER_PROJECT_EDIT_ADD_FILES_LINKS: undefined,

  ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS_STARTED: undefined,
  ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS_SUCCESS: undefined,
  ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS: undefined,
  ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS_FAILURE: undefined,
  ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS_FINISHED: undefined,

  ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS_STARTED: undefined,
  ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS_SUCCESS: undefined,
  ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS: undefined,
  ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS_FAILURE: undefined,
  ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS_FINISHED: undefined,

  ISSUER_PROJECT_CREATE_CLEAR_FIELDS: undefined,

  ISSUER_PROJECT_SAVE_VIDEO_LINKS_STARTED: undefined,
  ISSUER_PROJECT_SAVE_VIDEO_LINKS_SUCCESS: undefined,
  ISSUER_PROJECT_SAVE_VIDEO_LINKS: undefined,
  ISSUER_PROJECT_SAVE_VIDEO_LINKS_FAILURE: undefined,
  ISSUER_PROJECT_SAVE_VIDEO_LINKS_FINISHED: undefined,

  ISSUER_PROJECT_SAVE_PREVIEW_LINKS_STARTED: undefined,
  ISSUER_PROJECT_SAVE_PREVIEW_LINKS_SUCCESS: undefined,
  ISSUER_PROJECT_SAVE_PREVIEW_LINKS: undefined,
  ISSUER_PROJECT_SAVE_PREVIEW_LINKS_FAILURE: undefined,
  ISSUER_PROJECT_SAVE_PREVIEW_LINKS_FINISHED: undefined,

  ISSUER_PROJECT_ADD_PREVIEW_LINKS: undefined,
  ISSUER_RESET_SAVING_STATUS: undefined,

  ISSUER_PROJECT_ADD_VIDEO_LINKS: undefined,
  ISSUER_PROJECT_CREATE_ADD_VIDEO: undefined,
  ISSUER_PROJECT_EDIT_ADD_VIDEO: undefined,

  AUTH_TOKEN: undefined,

  SIGN_NDA_STARTED: undefined,
  SIGN_NDA_SUCCESS: undefined,
  SIGN_NDA: undefined,
  SIGN_NDA_FAILURE: undefined,
  SIGN_NDA_FINISHED: undefined,

  IS_SIGN_NDA_STARTED: undefined,
  IS_SIGN_NDA_SUCCESS: undefined,
  IS_SIGN_NDA: undefined,
  IS_SIGN_NDA_FAILURE: undefined,
  IS_SIGN_NDA_FINISHED: undefined,

  GET_COUNTRIES_REQUEST: undefined,
  GET_COUNTRIES_SUCCESS: undefined,

  GET_INDUSTRY_SECTOR_STARTED: undefined,
  GET_INDUSTRY_SECTOR_SUCCESS: undefined,
  GET_INDUSTRY_SECTOR: undefined,
  GET_INDUSTRY_SECTOR_FAILURE: undefined,
  GET_INDUSTRY_SECTOR_FINISHED: undefined,

  GET_REGIONS_STARTED: undefined,
  GET_REGIONS_SUCCESS: undefined,
  GET_REGIONS: undefined,
  GET_REGIONS_FAILURE: undefined,
  GET_REGIONS_FINISHED: undefined,

  GET_PRODUCT_CATEGORIES_STARTED: undefined,
  GET_PRODUCT_CATEGORIES_SUCCESS: undefined,
  GET_PRODUCT_CATEGORIES: undefined,
  GET_PRODUCT_CATEGORIES_FAILURE: undefined,
  GET_PRODUCT_CATEGORIES_FINISHED: undefined,

  GET_TARGET_STARTED: undefined,
  GET_TARGET_SUCCESS: undefined,
  GET_TARGET: undefined,
  GET_TARGET_FAILURE: undefined,
  GET_TARGET_FINISHED: undefined,

  SHOW_SPINNER: undefined,
  HIDE_SPINNER: undefined,
  HIDE_DELAY_SPINNER: undefined,

  CONNECT_WEBSOCKET_NOTIFICATIONS: undefined,
  DISCONNECT_WEBSOCKET_NOTIFICATIONS: undefined,

  GET_UNREAD_NOTIFICATIONS_COUNT_STARTED: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT_FAILURE: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT_FINISHED: undefined,

  ISSUER_RESET_FILES_SECTION: undefined,

  TOGGLE_INVEST_MODAL: undefined,
  TOGGLE_INVEST_PRIVATE_BANKERS_MODAL: undefined,
  TOGGLE_ISSUER_CONTACT_MODAL: undefined,

  TOGGLE_INVEST_CODE_MODAL: undefined,

  TOGGLE_INVEST_WITH_CODE_CONFIRM_MODAL: undefined,

  TOGGLE_PRIVATE_BANKER_MODAL: undefined,

  MODAL_TOGGLE: undefined,

  GET_PRIVATE_BANKERS_LIST_STARTED: undefined,
  GET_PRIVATE_BANKERS_LIST_SUCCESS: undefined,
  GET_PRIVATE_BANKERS_LIST: undefined,
  GET_PRIVATE_BANKERS_LIST_FAILURE: undefined,
  GET_PRIVATE_BANKERS_LIST_FINISHED: undefined,

  REGISTER_PAYMENT_CARD_STARTED: undefined,
  REGISTER_PAYMENT_CARD_SUCCESS: undefined,
  REGISTER_PAYMENT_CARD: undefined,
  REGISTER_PAYMENT_CARD_FAILURE: undefined,
  REGISTER_PAYMENT_CARD_FINISHED: undefined,

  SAVE_PAYMENT_CARD_STARTED: undefined,
  SAVE_PAYMENT_CARD_SUCCESS: undefined,
  SAVE_PAYMENT_CARD: undefined,
  SAVE_PAYMENT_CARD_FAILURE: undefined,
  SAVE_PAYMENT_CARD_FINISHED: undefined,

  GET_PAYMENT_CARDS_LIST_STARTED: undefined,
  GET_PAYMENT_CARDS_LIST_SUCCESS: undefined,
  GET_PAYMENT_CARDS_LIST: undefined,
  GET_PAYMENT_CARDS_LIST_FAILURE: undefined,
  GET_PAYMENT_CARDS_LIST_FINISHED: undefined,

  CREATE_PAYMENT_BANK_ACCOUNT_STARTED: undefined,
  CREATE_PAYMENT_BANK_ACCOUNT_SUCCESS: undefined,
  CREATE_PAYMENT_BANK_ACCOUNT: undefined,
  CREATE_PAYMENT_BANK_ACCOUNT_FAILURE: undefined,
  CREATE_PAYMENT_BANK_ACCOUNT_FINISHED: undefined,

  GET_PAYMENT_BANK_ACCOUNTS_LIST_STARTED: undefined,
  GET_PAYMENT_BANK_ACCOUNTS_LIST_SUCCESS: undefined,
  GET_PAYMENT_BANK_ACCOUNTS_LIST: undefined,
  GET_PAYMENT_BANK_ACCOUNTS_LIST_FAILURE: undefined,
  GET_PAYMENT_BANK_ACCOUNTS_LIST_FINISHED: undefined,

  GET_WALLET_BALANCE_STARTED: undefined,
  GET_WALLET_BALANCE_SUCCESS: undefined,
  GET_WALLET_BALANCE: undefined,
  GET_WALLET_BALANCE_FAILURE: undefined,
  GET_WALLET_BALANCE_FINISHED: undefined,

  INVEST_WITH_CODE: undefined,

  INVEST_TO_PROJECT_STARTED: undefined,
  INVEST_TO_PROJECT_SUCCESS: undefined,
  INVEST_TO_PROJECT: undefined,
  INVEST_TO_PROJECT_FAILURE: undefined,
  INVEST_TO_PROJECT_FINISHED: undefined,

  INVEST_PROJECT_PRIVATE_BANKERS_STARTED: undefined,
  INVEST_PROJECT_PRIVATE_BANKERS_SUCCESS: undefined,
  INVEST_PROJECT_PRIVATE_BANKERS: undefined,
  INVEST_PROJECT_PRIVATE_BANKERS_FAILURE: undefined,
  INVEST_PROJECT_PRIVATE_BANKERS_FINISHED: undefined,
  VALIDATE_INVESTMENT_CODE: undefined,
  SET_INVESTMENT_WITH_CODE_DETAILS: undefined,
  CLEAR_INVESTMENT_WITH_CODE_DETAILS: undefined,

  GET_SUBSCRIPTIONS_LIST_STARTED: undefined,
  GET_SUBSCRIPTIONS_LIST_SUCCESS: undefined,
  GET_SUBSCRIPTIONS_LIST: undefined,
  GET_SUBSCRIPTIONS_LIST_FAILURE: undefined,
  GET_SUBSCRIPTIONS_LIST_FINISHED: undefined,

  PAY_FOR_SUBSCRIPTION_STARTED: undefined,
  PAY_FOR_SUBSCRIPTION_SUCCESS: undefined,
  PAY_FOR_SUBSCRIPTION: undefined,
  PAY_FOR_SUBSCRIPTION_FAILURE: undefined,
  PAY_FOR_SUBSCRIPTION_FINISHED: undefined,

  CHANGE_AUTORENEWABLE_SUBSCRIPTION_STARTED: undefined,
  CHANGE_AUTORENEWABLE_SUBSCRIPTION_SUCCESS: undefined,
  CHANGE_AUTORENEWABLE_SUBSCRIPTION: undefined,
  CHANGE_AUTORENEWABLE_SUBSCRIPTION_FAILURE: undefined,
  CHANGE_AUTORENEWABLE_SUBSCRIPTION_FINISHED: undefined,

  GET_PAYMENT_FOR_PUBLISHING_STARTED: undefined,
  GET_PAYMENT_FOR_PUBLISHING_SUCCESS: undefined,
  GET_PAYMENT_FOR_PUBLISHING: undefined,
  GET_PAYMENT_FOR_PUBLISHING_FAILURE: undefined,
  GET_PAYMENT_FOR_PUBLISHING_FINISHED: undefined,

  GET_ONGOING_SP_PROJECTS_STARTED: undefined,
  GET_ONGOING_SP_PROJECTS_SUCCESS: undefined,
  GET_ONGOING_SP_PROJECTS: undefined,
  GET_ONGOING_SP_PROJECTS_FAILURE: undefined,
  GET_ONGOING_SP_PROJECTS_FINISHED: undefined,

  GET_FINISHED_SP_PROJECTS_STARTED: undefined,
  GET_FINISHED_SP_PROJECTS_SUCCESS: undefined,
  GET_FINISHED_SP_PROJECTS: undefined,
  GET_FINISHED_SP_PROJECTS_FAILURE: undefined,
  GET_FINISHED_SP_PROJECTS_FINISHED: undefined,

  GET_PENDING_SP_PROJECTS: undefined,
  GET_PENDING_SP_PROJECTS_STARTED: undefined,
  GET_PENDING_SP_PROJECTS_SUCCESS: undefined,
  GET_PENDING_SP_PROJECTS_FAILURE: undefined,
  GET_PENDING_SP_PROJECTS_FINISHED: undefined,

  GET_REJECTED_SP_PROJECTS: undefined,
  GET_REJECTED_SP_PROJECTS_STARTED: undefined,
  GET_REJECTED_SP_PROJECTS_SUCCESS: undefined,
  GET_REJECTED_SP_PROJECTS_FAILURE: undefined,
  GET_REJECTED_SP_PROJECTS_FINISHED: undefined,

  GET_PROJECT_DATA_ROOM_REQUEST_LIST: undefined,
  GET_PROJECT_DATA_ROOM_REQUEST_LIST_STARTED: undefined,
  GET_PROJECT_DATA_ROOM_REQUEST_LIST_SUCCESS: undefined,
  GET_PROJECT_DATA_ROOM_REQUEST_LIST_FAILURE: undefined,
  GET_PROJECT_DATA_ROOM_REQUEST_LIST_FINISHED: undefined,

  PROJECT_DATA_ROOM_REQUEST_APPROVE: undefined,
  PROJECT_DATA_ROOM_REQUEST_APPROVE_STARTED: undefined,
  PROJECT_DATA_ROOM_REQUEST_APPROVE_SUCCESS: undefined,
  PROJECT_DATA_ROOM_REQUEST_APPROVE_FAILURE: undefined,
  PROJECT_DATA_ROOM_REQUEST_APPROVE_FINISHED: undefined,

  PROJECT_DATA_ROOM_REQUEST_REJECT: undefined,
  PROJECT_DATA_ROOM_REQUEST_REJECT_STARTED: undefined,
  PROJECT_DATA_ROOM_REQUEST_REJECT_SUCCESS: undefined,
  PROJECT_DATA_ROOM_REQUEST_REJECT_FAILURE: undefined,
  PROJECT_DATA_ROOM_REQUEST_REJECT_FINISHED: undefined,

  PROJECT_ACCEPT_WARNING_AND_DISCLAIMER: undefined,
  PROJECT_ACCEPT_WARNING_AND_DISCLAIMER_STARTED: undefined,
  PROJECT_ACCEPT_WARNING_AND_DISCLAIMER_SUCCESS: undefined,
  PROJECT_ACCEPT_WARNING_AND_DISCLAIMER_FAILURE: undefined,
  PROJECT_ACCEPT_WARNING_AND_DISCLAIMER_FINISHED: undefined,

  SEND_DATA_ROOM_ACCESS_REQUEST: undefined,
  SEND_DATA_ROOM_ACCESS_REQUEST_STARTED: undefined,
  SEND_DATA_ROOM_ACCESS_REQUEST_SUCCESS: undefined,
  SEND_DATA_ROOM_ACCESS_REQUEST_FAILURE: undefined,
  SEND_DATA_ROOM_ACCESS_REQUEST_FINISHED: undefined,

  SEND_ISSUER_CONTACT_REQUEST: undefined,
  SEND_ISSUER_CONTACT_REQUEST_STARTED: undefined,
  SEND_ISSUER_CONTACT_REQUEST_SUCCESS: undefined,
  SEND_ISSUER_CONTACT_REQUEST_FAILURE: undefined,
  SEND_ISSUER_CONTACT_REQUEST_FINISHED: undefined,

  GET_ALL_STATISTIC_STARTED: undefined,
  GET_ALL_STATISTIC_SUCCESS: undefined,
  GET_ALL_STATISTIC: undefined,
  GET_ALL_STATISTIC_FAILURE: undefined,
  GET_ALL_STATISTIC_FINISHED: undefined,

  GET_ALL_TAGS_STARTED: undefined,
  GET_ALL_TAGS_SUCCESS: undefined,
  GET_ALL_TAGS: undefined,
  GET_ALL_TAGS_FAILURE: undefined,
  GET_ALL_TAGS_FINISHED: undefined,

  TOGGLE_SP_REQUIRED_INFO_MODAL: undefined,

  ADD_MONEY_FROM_CARD_TO_WALLET_STARTED: undefined,
  ADD_MONEY_FROM_CARD_TO_WALLET_SUCCESS: undefined,
  ADD_MONEY_FROM_CARD_TO_WALLET: undefined,
  ADD_MONEY_FROM_CARD_TO_WALLET_FAILURE: undefined,
  ADD_MONEY_FROM_CARD_TO_WALLET_FINISHED: undefined,

  ADD_MONEY_FROM_BANK_ACCOUNT_TO_WALLET_STARTED: undefined,
  ADD_MONEY_FROM_BANK_ACCOUNT_TO_WALLET_SUCCESS: undefined,
  ADD_MONEY_FROM_BANK_ACCOUNT_TO_WALLET: undefined,
  ADD_MONEY_FROM_BANK_ACCOUNT_TO_WALLET_FAILURE: undefined,
  ADD_MONEY_FROM_BANK_ACCOUNT_TO_WALLET_FINISHED: undefined,

  REPAY_MONEY_STARTED: undefined,
  REPAY_MONEY_SUCCESS: undefined,
  REPAY_MONEY: undefined,
  REPAY_MONEY_FAILURE: undefined,
  REPAY_MONEY_FINISHED: undefined,

  TOGGLE_REPAY_MODAL: undefined,

  TOGGLE_ACCESS_DATAROOM_MODAL: undefined,

  MODAL_REJECT_PROJECT_TOGGLE: undefined,

  REST_BANK_CREDENTIALS: undefined,

  USER_CV_CREATE_REQUEST: undefined,
  USER_CV_CREATE_REQUEST_STARTED: undefined,
  USER_CV_CREATE_REQUEST_SUCCESS: undefined,
  USER_CV_CREATE_REQUEST_FAILURE: undefined,
  USER_CV_CREATE_REQUEST_FINISHED: undefined,

  USER_CV_UPDATE_REQUEST: undefined,
  USER_CV_UPDATE_REQUEST_STARTED: undefined,
  USER_CV_UPDATE_REQUEST_SUCCESS: undefined,
  USER_CV_UPDATE_REQUEST_FAILURE: undefined,
  USER_CV_UPDATE_REQUEST_FINISHED: undefined,

  USER_CV_GET_REQUEST: undefined,
  USER_CV_GET_REQUEST_STARTED: undefined,
  USER_CV_GET_REQUEST_SUCCESS: undefined,
  USER_CV_GET_REQUEST_FAILURE: undefined,
  USER_CV_GET_REQUEST_FINISHED: undefined,

  KYC_USER_STATUS_STARTED: undefined,
  KYC_USER_STATUS_SUCCESS: undefined,
  KYC_USER_STATUS: undefined,
  KYC_USER_STATUS_FAILURE: undefined,
  KYC_USER_STATUS_FINISHED: undefined,

  KYC_DOCUMENTS_STATUS_STARTED: undefined,
  KYC_DOCUMENTS_STATUS_SUCCESS: undefined,
  KYC_DOCUMENTS_STATUS: undefined,
  KYC_DOCUMENTS_STATUS_FAILURE: undefined,
  KYC_DOCUMENTS_STATUS_FINISHED: undefined,

  SAVE_KYC_DOCUMENTS_STARTED: undefined,
  SAVE_KYC_DOCUMENTS_SUCCESS: undefined,
  SAVE_KYC_DOCUMENTS: undefined,
  SAVE_KYC_DOCUMENTS_FAILURE: undefined,
  SAVE_KYC_DOCUMENTS_FINISHED: undefined,

  SET_USER_KYC_STATUS_STARTED: undefined,
  SET_USER_KYC_STATUS_SUCCESS: undefined,
  SET_USER_KYC_STATUS: undefined,
  SET_USER_KYC_STATUS_FAILURE: undefined,
  SET_USER_KYC_STATUS_FINISHED: undefined,

  SET_USER_STEPPER_STATUS_STARTED: undefined,
  SET_USER_STEPPER_STATUS_SUCCESS: undefined,
  SET_USER_STEPPER_STATUS: undefined,
  SET_USER_STEPPER_STATUS_FAILURE: undefined,
  SET_USER_STEPPER_STATUS_FINISHED: undefined,

  ASIDE_NAV_TOGGLE: undefined,

  ASIDE_NAV_SET_STATUS: undefined,

  GET_SP_ISSUERS_CREATION_DOCUMENTS: undefined,
  GET_SP_ISSUERS_CREATION_DOCUMENTS_STARTED: undefined,
  GET_SP_ISSUERS_CREATION_DOCUMENTS_SUCCESS: undefined,
  GET_SP_ISSUERS_CREATION_DOCUMENTS_FAILURE: undefined,
  GET_SP_ISSUERS_CREATION_DOCUMENTS_FINISHED: undefined,

  GET_SP_INVESTORS_CREATION_DOCUMENTS: undefined,
  GET_SP_INVESTORS_CREATION_DOCUMENTS_STARTED: undefined,
  GET_SP_INVESTORS_CREATION_DOCUMENTS_SUCCESS: undefined,
  GET_SP_INVESTORS_CREATION_DOCUMENTS_FAILURE: undefined,
  GET_SP_INVESTORS_CREATION_DOCUMENTS_FINISHED: undefined,

  GET_ADVISOR_FINISHED_PROJECTS_STARTED: undefined,
  GET_ADVISOR_FINISHED_PROJECTS_SUCCESS: undefined,
  GET_ADVISOR_FINISHED_PROJECTS: undefined,
  GET_ADVISOR_FINISHED_PROJECTS_FAILURE: undefined,
  GET_ADVISOR_FINISHED_PROJECTS_FINISHED: undefined,

  GET_ADVISOR_SUCCESSFULLY_FINISHED_PROJECTS_STARTED: undefined,
  GET_ADVISOR_SUCCESSFULLY_FINISHED_PROJECTS_SUCCESS: undefined,
  GET_ADVISOR_SUCCESSFULLY_FINISHED_PROJECTS: undefined,
  GET_ADVISOR_SUCCESSFULLY_FINISHED_PROJECTS_FAILURE: undefined,
  GET_ADVISOR_SUCCESSFULLY_FINISHED_PROJECTS_FINISHED: undefined,

  GET_ADVISOR_ONGOING_PROJECTS_STARTED: undefined,
  GET_ADVISOR_ONGOING_PROJECTS_SUCCESS: undefined,
  GET_ADVISOR_ONGOING_PROJECTS: undefined,
  GET_ADVISOR_ONGOING_PROJECTS_FAILURE: undefined,
  GET_ADVISOR_ONGOING_PROJECTS_FINISHED: undefined,

  GET_INVESTORS_FINISHED_PROJECTS_STARTED: undefined,
  GET_INVESTORS_FINISHED_PROJECTS_SUCCESS: undefined,
  GET_INVESTORS_FINISHED_PROJECTS: undefined,
  GET_INVESTORS_FINISHED_PROJECTS_FAILURE: undefined,
  GET_INVESTORS_FINISHED_PROJECTS_FINISHED: undefined,

  GET_INVESTORS_FINISHED_FAVORITES_PROJECTS_STARTED: undefined,
  GET_INVESTORS_FINISHED_FAVORITES_PROJECTS_SUCCESS: undefined,
  GET_INVESTORS_FINISHED_FAVORITES_PROJECTS: undefined,
  GET_INVESTORS_FINISHED_FAVORITES_PROJECTS_FAILURE: undefined,
  GET_INVESTORS_FINISHED_FAVORITES_PROJECTS_FINISHED: undefined,

  GET_INVESTORS_SUCCESSFULLY_FINISHED_PROJECTS_STARTED: undefined,
  GET_INVESTORS_SUCCESSFULLY_FINISHED_PROJECTS_SUCCESS: undefined,
  GET_INVESTORS_SUCCESSFULLY_FINISHED_PROJECTS: undefined,
  GET_INVESTORS_SUCCESSFULLY_FINISHED_PROJECTS_FAILURE: undefined,
  GET_INVESTORS_SUCCESSFULLY_FINISHED_PROJECTS_FINISHED: undefined,

  GET_INVESTORS_ONGOING_PROJECTS_STARTED: undefined,
  GET_INVESTORS_ONGOING_PROJECTS_SUCCESS: undefined,
  GET_INVESTORS_ONGOING_PROJECTS: undefined,
  GET_INVESTORS_ONGOING_PROJECTS_FAILURE: undefined,
  GET_INVESTORS_ONGOING_PROJECTS_FINISHED: undefined,

  GET_INVESTORS_ONGOING_FAVORITES_PROJECTS_STARTED: undefined,
  GET_INVESTORS_ONGOING_FAVORITES_PROJECTS_SUCCESS: undefined,
  GET_INVESTORS_ONGOING_FAVORITES_PROJECTS: undefined,
  GET_INVESTORS_ONGOING_FAVORITES_PROJECTS_FAILURE: undefined,
  GET_INVESTORS_ONGOING_FAVORITES_PROJECTS_FINISHED: undefined,

  GET_NDA_TEMPLATE_STARTED: undefined,
  GET_NDA_TEMPLATE_SUCCESS: undefined,
  GET_NDA_TEMPLATE: undefined,
  GET_NDA_TEMPLATE_FAILURE: undefined,
  GET_NDA_TEMPLATE_FINISHED: undefined,

  GET_TEMPLATE: undefined,

  PROJECT_FIRST_TIME_VIEW_CHECK_STARTED: undefined,
  PROJECT_FIRST_TIME_VIEW_CHECK_SUCCESS: undefined,
  PROJECT_FIRST_TIME_VIEW_CHECK: undefined,
  PROJECT_FIRST_TIME_VIEW_CHECK_FINISHED: undefined,
  PROJECT_FIRST_TIME_VIEW_CHECK_FAILURE: undefined,

  GET_LAST_ACTIVE_SUBSCRIPTION_STARTED: undefined,
  GET_LAST_ACTIVE_SUBSCRIPTION_SUCCESS: undefined,
  GET_LAST_ACTIVE_SUBSCRIPTION: undefined,
  GET_LAST_ACTIVE_SUBSCRIPTION_FINISHED: undefined,
  GET_LAST_ACTIVE_SUBSCRIPTION_FAILURE: undefined,

  GET_SUBSCRIPTION_HISTORY_STARTED: undefined,
  GET_SUBSCRIPTION_HISTORY_SUCCESS: undefined,
  GET_SUBSCRIPTION_HISTORY: undefined,
  GET_SUBSCRIPTION_HISTORY_FINISHED: undefined,
  GET_SUBSCRIPTION_HISTORY_FAILURE: undefined,

  TOGGLE_FIRST_TIME_VIEW_MODAL: undefined,

  CLEAR_KYC_DOCUMENTS_SECTION: undefined,
  SAVE_SP_ISSUERS_CREATION_DOCUMENT: undefined,
  SAVE_SP_INVESTORS_CREATION_DOCUMENT: undefined,

  INVESTOR_UPDATE_LIKE_PROJECT_STARTED: undefined,
  INVESTOR_UPDATE_LIKE_PROJECT: undefined,
  INVESTOR_UPDATE_LIKE_PROJECT_SUCCESS: undefined,
  INVESTOR_UPDATE_LIKE_PROJECT_FAILURE: undefined,
  INVESTOR_UPDATE_LIKE_PROJECT_FINISHED: undefined,

  INVESTOR_UPDATE_FAVORITE_PROJECT_STARTED: undefined,
  INVESTOR_UPDATE_FAVORITE_PROJECT: undefined,
  INVESTOR_UPDATE_FAVORITE_PROJECT_SUCCESS: undefined,
  INVESTOR_UPDATE_FAVORITE_PROJECT_FAILURE: undefined,
  INVESTOR_UPDATE_FAVORITE_PROJECT_FINISHED: undefined,

  GET_PROJECT_INVESTMENTS_LIST: undefined,
  GET_PROJECT_INVESTMENTS_LIST_STARTED: undefined,
  GET_PROJECT_INVESTMENTS_LIST_SUCCESS: undefined,
  GET_PROJECT_INVESTMENTS_LIST_FAILURE: undefined,
  GET_PROJECT_INVESTMENTS_LIST_FINISHED: undefined,

  UPDATE_SP_PLATFORM_CONFIG: undefined,
  UPDATE_SP_PLATFORM_CONFIG_STARTED: undefined,
  UPDATE_SP_PLATFORM_CONFIG_SUCCESS: undefined,
  UPDATE_SP_PLATFORM_CONFIG_FAILURE: undefined
});
