import { call, fork } from 'redux-saga/effects';
import watchLoginIssuer from './loginSaga';
import registrationWatch from './registrationSaga';
import { watchCheckConfirmToken, watchConfirmationEmail } from './confirmEmailSaga';
import watchLogOut from './logOutSaga';
import watchForgotPassword from './forgotPasswordSaga';
import { watchCheckUsedToken, watchRestorePassword } from './restorePasswordSaga';
import watchCountriesSaga from './countriesSaga';
import { watchOneTimeSecurityCodeRequest } from './oneTimeSecurityCodeSaga';
import watchRefreshToken from './refreshTokenSaga';
import { watchUserData } from './userDataSaga';
import { watchConfirmInvestor } from './confirmInvestorSaga';
import {
  watchIssuerCreateProjectPublish,
  watchIssuerProjectCreateUploadFilesLinks,
  watchIssuerProjectCreation
} from './issuerProjectCreationSaga';
import watchIssuerProjects from './userProjectsSaga';
import watchGetTargetSaga from './targetSaga';

import {
  issuerGetFullProjectWatch,
  issuerProjectEditWatch,
  watchIssuerEditProjectPublish,
  watchIssuerEditProjectUploadFilesLinks,
  watchGetFileCategories
} from './issuerProjectEditSaga';
import {
  watchSaveCreateProjectFile,
  watchSaveEditProjectFile,
  watchIssuerProjectRemoveFile
} from './saveFileSaga';
import {
  watchSaveCreateProjectFiles,
  watchSaveEditProjectFiles,
  watchSaveProjectVideoLinks,
  watchSaveProjectPreviewLinks
} from './saveFilesSaga';
import watchInvestorUser from './investorUserSaga';
import watchChangePasswordSaga from './changePasswordSaga';
import watchNotifications from './notificationsSaga';
import watchStrategicPartnersSaga from './strategicPartnersSaga';
import watchSPUpdateLogo from './spSettingsSaga';
import watchGetPlatformDetails from './firstLoadUnauthSaga';
import { watchSignNDA } from './signNDASaga';
import registerPaymentCardWatch from './paymentsSaga';
import watchSearchSaga from './searchSaga';
import watchSPProjectsSaga from './SPProjectsSaga';
import watchStatistics from './statisticsSaga';
import { watchTagsSaga } from './tagsSaga';
import watchCurrencies from './currenciesSaga';
import watchUserPlatformSaga from './userPlatformSaga';
import watchSpinnerSaga from './spinnerSaga';
import watchGetTemplate from './templateSaga';
import watchProjectDataRoomAccess from './projectDataRoomAccessSaga';
import watchProjectInvestments from './projectInvestmentsSaga';
import watchIssuerContactDirectly from './issuerContactDirectlySaga';
import watchProjectAcceptWarningAndDisclaimer from './warningAndDisclaimerSaga';
import watchGetRegions from './getRegions';
import watchSpPlatformConfigSaga from './spPlatformConfigSaga';

function SagaException({ message }) {
  this.message = message;
  this.name = 'Unhandled error: ';
}

function forkAutoRestarting(fn, ...args) {
  return fork(function*() {
    while (true) {
      try {
        yield call(fn, ...args);
      } catch (e) {
        console.error('SagaException :', e);
        throw new SagaException(e);
      }
    }
  });
}

export default function* rootSaga() {
  yield [
    forkAutoRestarting(watchSPProjectsSaga),
    forkAutoRestarting(registerPaymentCardWatch),
    forkAutoRestarting(watchGetPlatformDetails),
    forkAutoRestarting(watchGetTargetSaga),
    forkAutoRestarting(watchIssuerProjectRemoveFile),
    forkAutoRestarting(issuerProjectEditWatch),
    forkAutoRestarting(watchSaveProjectPreviewLinks),
    forkAutoRestarting(watchIssuerEditProjectUploadFilesLinks),
    forkAutoRestarting(watchGetFileCategories),
    forkAutoRestarting(issuerGetFullProjectWatch),
    forkAutoRestarting(registrationWatch),
    forkAutoRestarting(watchSpPlatformConfigSaga),
    forkAutoRestarting(watchLoginIssuer),
    forkAutoRestarting(watchConfirmationEmail),
    forkAutoRestarting(watchLogOut),
    forkAutoRestarting(watchForgotPassword),
    forkAutoRestarting(watchCheckUsedToken),
    forkAutoRestarting(watchRestorePassword),
    forkAutoRestarting(watchCheckConfirmToken),
    forkAutoRestarting(watchCountriesSaga),
    forkAutoRestarting(watchChangePasswordSaga),
    forkAutoRestarting(watchOneTimeSecurityCodeRequest),
    forkAutoRestarting(watchRefreshToken),
    forkAutoRestarting(watchUserData),
    forkAutoRestarting(watchSignNDA),
    forkAutoRestarting(watchConfirmInvestor),
    forkAutoRestarting(watchIssuerProjects),
    forkAutoRestarting(watchIssuerProjectCreation),
    forkAutoRestarting(watchIssuerProjectCreateUploadFilesLinks),
    forkAutoRestarting(watchIssuerCreateProjectPublish),
    forkAutoRestarting(watchIssuerEditProjectPublish),
    forkAutoRestarting(watchSaveCreateProjectFile),
    forkAutoRestarting(watchSaveEditProjectFile),
    forkAutoRestarting(watchSaveCreateProjectFiles),
    forkAutoRestarting(watchSaveEditProjectFiles),
    forkAutoRestarting(watchSaveProjectVideoLinks),
    forkAutoRestarting(watchInvestorUser),
    forkAutoRestarting(watchNotifications),
    forkAutoRestarting(watchStrategicPartnersSaga),
    forkAutoRestarting(watchSPUpdateLogo),
    forkAutoRestarting(watchSearchSaga),
    forkAutoRestarting(watchStatistics),
    forkAutoRestarting(watchTagsSaga),
    forkAutoRestarting(watchCurrencies),
    forkAutoRestarting(watchGetTemplate),
    forkAutoRestarting(watchProjectDataRoomAccess),
    forkAutoRestarting(watchProjectInvestments),
    forkAutoRestarting(watchUserPlatformSaga),
    forkAutoRestarting(watchSpinnerSaga),
    forkAutoRestarting(watchIssuerContactDirectly),
    forkAutoRestarting(watchProjectAcceptWarningAndDisclaimer),
    forkAutoRestarting(watchGetRegions)
  ];
}
