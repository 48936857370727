import { takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import patternSaga from './patternSaga';
import Api from '../api';

const projectTargetSagaConfig = {
  requestFunction: Api.Projects.getTargetData,
  requestUrl: `${utils.config.apiConfigUrl}/TARGET`
};

export default function* watchGetTargetSaga() {
  yield takeLatest(ActionTypes.GET_TARGET, patternSaga, projectTargetSagaConfig);
}
