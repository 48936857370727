import React, { Component as BasicComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Api from '../api';
import { login } from 'shared/routes/urlLocations';

export default class privateRoute extends BasicComponent {
  constructor() {
    super();
  }

  render() {
    const { component: Component, location, ...rest } = this.props;
    return (
      <Route
          {...rest}
          render={route => Api.User.isAuthenticated ? (
            <Component route={route} />
          ) : (
            <Redirect
                to={{
                  pathname: login,
                  state: { from: location }
                }}
            />
          )
        }
      />
    );
  }
}
