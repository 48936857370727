import React, { Component } from 'react';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import WalletIcon from 'shared/components/Icons/WalletIcon';
import ClockIcon from '../Icons/ClockIcon';
import PCIcon from '../Icons/PCIcon';
import userStatus from 'shared/constants/userStatus';
import Animation from 'shared/components/Animation';
import withScrollbars from 'shared/hoc/withScrollbars';
import styled from 'styled-components';
import { ThemedAntIcon, ThemedButton, ThemedText } from 'shared/styled/components';

const ThemedGuides = styled.div`
  .guide__step-title {
    color: ${props => props.theme.platformPrimary};
  }
  .guide__step.done {
    .guide__step-number {
      border-color: ${props => props.theme.platformPrimary};

      i {
        color: ${props => props.theme.platformPrimary};
      }
    }
  }
  .guide__step.active {
    .guide__step-number {
      background: ${props => props.theme.platformPrimary};
      border-color: ${props => props.theme.platformPrimary};
    }
  }
`;

class Guide extends Component {
  state = {
    currentStep: {
      step: 0,
      status: 'active'
    }
  };

  componentDidMount() {
    const { status, renderStepStatus } = this.props;
    this.setState(() => ({
      currentStep: renderStepStatus(status)
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { status } = nextProps;
    const { asideNavSetStatus, renderStepStatus } = this.props;
    if (status === userStatus.WALLET_FILLED && status !== this.props.status) {
      asideNavSetStatus(true);
    }
    this.setState(() => ({
      currentStep: renderStepStatus(status)
    }));
  }

  scrollTo = element => {
    const {
      scrollbar: { scrollTo }
    } = this.props;
    scrollTo(element || 'top');
  };

  renderIcon(type) {
    switch (type) {
      case 'wallet':
        return <WalletIcon />;
      case 'pc':
        return <PCIcon />;
      default:
        'empty icon';
    }
  }

  renderStepClass(step) {
    const { currentStep } = this.state;
    let stepState = '';

    if (step === currentStep.step) {
      stepState = currentStep.status;
    } else {
      stepState = step > currentStep.step ? 'disable' : 'done';
    }

    return stepState;
  }

  renderStepIcon(status) {
    switch (status) {
      case 'done':
        return <ThemedAntIcon type="check" />;
      case 'pending':
        return <ClockIcon />;
      default:
        return null;
    }
  }

  render() {
    const {
      t, tReady, setUserStepperStatus, content, successStep, isUser
    } = this.props;
    const { currentStep } = this.state;
    const translationContext = isUser.allSP ? { context: 'sp' } : {};

    return (
      <ThemedGuides className="guide">
        <div className="guide__header">
          <div className="guide__icon">{this.renderIcon(content.icon)}</div>
          <div className="guide__description">{content.header}</div>
        </div>
        <div className="guide__body">
          {currentStep.step === successStep ? (
            <div className="guide__success">
              <div className="guide__success-sub-title">
                <ThemedText>{content.successSubTitle}</ThemedText>
              </div>
              <div className="guide__success-text">{content.successText(setUserStepperStatus)}</div>
              <div className="guide__success-button">
                <ThemedButton onClick={() => setUserStepperStatus()}
                    type="primary">
                  OK
                </ThemedButton>
              </div>
            </div>
          ) : content.steps ? (
            <div className="guide__steps">
              {content.steps.map((item, index) => (
                <div
                    className={`guide__step ${this.renderStepClass(index + 1)} `}
                    key={item.title + index}
                >
                  <div className="guide__step-wrap">
                    {this.renderStepClass(index + 1) == 'disable' ? (
                      <div className="guide__step-number">
                        {this.renderStepClass(index + 1) === 'done'
                        || this.renderStepClass(index + 1) === 'pending'
                          ? this.renderStepIcon(this.renderStepClass(index + 1))
                          : index + 1}
                      </div>
                    ) : (
                      <Link onClick={() => this.scrollTo(item.scrollTo)}
                          to={item.link}>
                        <div className="guide__step-number">
                          {this.renderStepClass(index + 1) === 'done'
                          || this.renderStepClass(index + 1) === 'pending'
                            ? this.renderStepIcon(this.renderStepClass(index + 1))
                            : index + 1}
                        </div>
                      </Link>
                    )}

                    {currentStep.step - 1 === index && (
                      <Animation
                          id={'guide-active-step'}
                          style={{ position: 'absolute', left: 12, top: 12 }}
                          type="pulse-stepper"
                      />
                    )}
                    <div className="guide__step-info">
                      {this.renderStepClass(index + 1) == 'disable' ? (
                        <React.Fragment>
                          {t(item.title, translationContext)}{' '}
                          {this.renderStepClass(index + 1) === 'pending' ? ' (Pending)' : ''}
                        </React.Fragment>
                      ) : (
                        <Link
                            className="guide__step-title"
                            onClick={() => this.scrollTo(item.scrollTo)}
                            to={item.link}
                        >
                          {t(item.title, translationContext)}{' '}
                          {this.renderStepClass(index + 1) === 'pending' ? ' (Pending)' : ''}
                        </Link>
                      )}

                      <div className="guide__step-description">
                        {this.renderStepClass(index + 1) === 'pending' && item.pendingDescription
                          ? t(item.pendingDescription, translationContext)
                          : t(item.description, translationContext)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </ThemedGuides>
    );
  }
}

export default compose(
  withScrollbars,
  withNamespaces('guides')
)(Guide);
