import { ActionTypes } from '../constants';
import initialState from '../store/initialState';

export default (state = initialState.NDA, { type, payload = {} }) => {
  switch (type) {
    case ActionTypes.SIGN_NDA_SUCCESS:
      return {
        ...state,
        url: payload.url
      };
    case ActionTypes.IS_SIGN_NDA_SUCCESS:
      return {
        ...state,
        url: null,
        signed: payload.signed
      };
    default:
      return state;
  }
};
