import React, { PureComponent } from 'react';
import Layout from 'antd/lib/layout';
import Sidebar from '../../components/Sidebar';
import Header from 'shared/components/Header';
import Content from 'shared/components/Content';
import Footer from 'shared/components/Footer';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import LogoutModal from 'shared/components/LogoutModal';
import AsideNav from '../../components/AsideNav';
import ScrollbarProvider from 'shared/providers/Scrollbar';
import AnimationsProvider from 'shared/providers/Animations';
import Styled from '../styled';
import THEMES from 'shared/constants/themes';
import ModalsProvider from 'shared/providers/Modals';
import ConfirmModalProvider from 'shared/providers/ConfirmModal';
import FullScreenSpinner from 'shared/components/FullScreenSpinner';
import ErrorBoundary from '../../components/ErrorBoundary';
import ContactUs from 'shared/components/ContactUs';
import debounce from 'lodash/debounce';

const subDomain = window.location.host.split('.')[0];
const desktopMinWidth = 1098;

class MainLayout extends PureComponent {
  scrollbar = React.createRef();

  state = {
    collapsed: false,
    logoutModalVisible: false,
    siderCollapsed: false
  };

  setLogoutModalVisible = status => {
    this.setState(() => ({
      logoutModalVisible: status
    }));
  };

  componentDidMount() {
    const { refreshToken, getUnreadNotificationsCount, addUserActivityListener } = this.props;
    window.addEventListener('resize', debounce(this.setMenuStatus));
    this.setMenuStatus();
    refreshToken();
    addUserActivityListener();
  }

  getSnapshotBeforeUpdate(nProps) {
    if (nProps.location.pathname !== this.props.location.pathname) {
      this.scrollbar.current.scrollToTop();
    }
    return null;
  }

  componentWillUnmount() {
    const { removeUserActivityListener, pushNotificationsDisconnect } = this.props;

    window.removeEventListener('resize', this.setMenuStatus);

    removeUserActivityListener();
  }

  setMenuStatus = () => {
    this.setState({
      siderCollapsed: window.innerWidth < desktopMinWidth
    });
  };

  render() {
    const {
      children,
      showSpinner,
      signOut,
      userType,
      type,
      permissions,
      familyName,
      givenName,
      phone,
      spLogo = { link: null, id: '' },
      isUserFetching,
      theme,
      location: { pathname },
      unread,
      exists,
      status,
      signNDA,
      NDA,
      isSignNDA,
      ndaSigned,
      cvCreated,
      showStepper,
      email,
      spPlatformConfig
    } = this.props;

    const { siderCollapsed } = this.state;

    const pageTitle = exists ? `${subDomain} portal` : 'FinBursa application';
    // TODO - Remove userTheme props && create context
    let userTheme = theme ? (theme === THEMES.BLACK ? THEMES.BLACK : THEMES.WHITE) : null;

    return (
      <React.Fragment>
        <ModalsProvider>
          <ConfirmModalProvider>
            <AnimationsProvider>
              <ScrollbarProvider
                  forwardRef={this.scrollbar}
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={200}
              >
                <Styled.MainLayout>
                  <Helmet
                      title={pageTitle}
                      meta={[{ property: 'og:site_name', content: pageTitle }]}
                  />
                  <Header
                      unread={unread}
                      setLogoutModalVisible={this.setLogoutModalVisible}
                      pathname={pathname}
                      isUserFetching={isUserFetching}
                      userTheme={userTheme}
                      exists={exists}
                      spPlatformConfig={spPlatformConfig}
                      user={{
                        givenName,
                        familyName,
                        userType,
                        type,
                        spLogo
                      }}
                  />
                  <Layout>
                    <Styled.Sider
                        width={190}
                        className={`ant-layout-sider-${
                          userTheme === THEMES.BLACK ? 'dark-theme' : 'white-theme'
                        }`}
                        collapsed={siderCollapsed}
                        collapsedWidth={64}
                    >
                      <ErrorBoundary>
                        <Sidebar
                            userType={userType}
                            userTheme={userTheme}
                            permissions={permissions}
                            pathname={pathname}
                            userStatus={status}
                            ndaUrl={NDA}
                            signNDA={signNDA}
                            isSignNDA={isSignNDA}
                            ndaSigned={ndaSigned}
                            cvCreated={cvCreated}
                        />
                      </ErrorBoundary>
                    </Styled.Sider>
                    <Layout>
                      <Content>{children}</Content>
                      {showStepper && <AsideNav userType={userType} />}
                    </Layout>
                    <Footer userTheme={userTheme}
                        spPlatformConfig={spPlatformConfig}
                        absolute />
                    <ContactUs
                        initialValues={{
                          name: givenName && familyName ? `${givenName} ${familyName}` : '',
                          phone: phone || null,
                          email: email
                        }}
                    />
                  </Layout>
                </Styled.MainLayout>
              </ScrollbarProvider>
            </AnimationsProvider>
          </ConfirmModalProvider>
        </ModalsProvider>
        <FullScreenSpinner showSpinner={showSpinner}
            simple={!!spPlatformConfig} />
        <LogoutModal
            isOpen={this.state.logoutModalVisible}
            onCancel={() => {
              this.setLogoutModalVisible(false);
            }}
            onSignOut={() => {
              this.setLogoutModalVisible(false);
              signOut();
            }}
        />
      </React.Fragment>
    );
  }
}

MainLayout.defaultProps = {
  children: '',
  showSpinner: false,
  user: {
    info: {
      userType: null,
      permissions: [],
      givenName: null,
      type: null,
      familyName: null,
      logo: null,
      theme: ''
    },
    strategicPartners: { exists: '' }
  }
};

MainLayout.propTypes = {
  children: PropTypes.node,
  refreshToken: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool,
  signOut: PropTypes.func.isRequired
};

export default MainLayout;
