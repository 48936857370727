import { takeLatest } from 'redux-saga/effects';
import { call, put, select } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import { convertCodeToMessage } from '../utils/responseCodeToMessage';
import notification from '../utils/notifications';

function* saveCreateProjectFiles({ link, field, fileType, categoryId }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  const {
    projects: { savingProject }
  } = yield select();
  const saveFileUrl = `${utils.config.apiProjectFilesUrl}/${savingProject.id || 'temp'}?type=${fileType}${categoryId ? `&categoryId=${categoryId}` : ''}`;
  try {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILES_LINKS_STARTED,
      payload: {
        requestFrom: 'saveFile'
      }
    });

    const { response, request, status } = yield call(Api.User.saveFile, saveFileUrl, link);

    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILES_LINKS_SUCCESS
        // ...response
      });
      yield put({
        type: ActionTypes.ISSUER_PROJECT_CREATE_ADD_FILES_LINKS,
        link: {
          link: response.link,
          description: link.description,
          id: response.id,
          name: link.name,
          type: fileType,
          size: response.size
        },
        field,
        fileType
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILES_LINKS_FAILURE,
        message: convertCodeToMessage(response.error.code)
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILES_LINKS_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILES_LINKS_FINISHED,
      payload: {
        requestFrom: 'saveFile'
      }
    });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* saveEditProjectFiles({ link, field, id, fileType, categoryId }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  const saveFileUrl = `${utils.config.apiProjectFilesUrl}/${id}?type=${fileType}${categoryId ? `&categoryId=${categoryId}` : ''}`;
  try {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILES_LINKS_STARTED,
      payload: {
        requestFrom: 'saveFile'
      }
    });

    const { response, request, status } = yield call(Api.User.saveFile, saveFileUrl, link);

    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILES_LINKS_SUCCESS
        //   ...result.data,
      });
      yield put({
        link: { ...response, description: link.description, id: response.id },
        type: ActionTypes.ISSUER_PROJECT_EDIT_ADD_FILES_LINKS,
        field,
        fileType
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILES_LINKS_FAILURE,
        message: convertCodeToMessage(response.error.code)
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILES_LINKS_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILES_LINKS_FINISHED,
      payload: {
        requestFrom: 'saveFile'
      }
    });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* saveProjectVideoLinks({ video, preview, id }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  const {
    projects: { savingProject, editingProject, uploadedVideo }
  } = yield select();
  const saveFileUrl = `${utils.config.apiProjectFilesUrl}/${
    savingProject.id ? savingProject.id : editingProject.id
  }?type=PROJECT_VIDEO`;
  try {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_SAVE_VIDEO_LINKS_STARTED,
      payload: {
        requestFrom: 'saveFile'
      }
    });
    const { response, request, status } = yield call(Api.User.saveFile, saveFileUrl, video);
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_SAVE_VIDEO_LINKS_SUCCESS
        //...result.data,
      });
      yield put({
        type: ActionTypes.ISSUER_PROJECT_ADD_VIDEO_LINKS,
        video: {
          link: response.link,
          id: response.id
        }
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_SAVE_VIDEO_LINKS_FAILURE,
        message: convertCodeToMessage(response)
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_SAVE_VIDEO_LINKS_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_SAVE_VIDEO_LINKS_FINISHED,
      payload: {
        requestFrom: 'saveFile'
      }
    });
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({ type: ActionTypes.ISSUER_PROJECT_SAVE_PREVIEW_LINKS, preview });
  }
}

function* saveProjectPreviewLinks({ preview }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });

  const {
    projects: { savingProject, editingProject, uploadedVideo }
  } = yield select();
  const saveFileUrl = `${utils.config.apiProjectFilesUrl}/${
    savingProject.id ? savingProject.id : editingProject.id
  }?type=PROJECT_VIDEO_PREVIEW`;
  try {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_SAVE_PREVIEW_LINKS_STARTED,
      payload: {
        requestFrom: 'saveFile'
      }
    });
    const { response, request, status } = yield call(Api.User.saveFile, saveFileUrl, preview);

    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_SAVE_PREVIEW_LINKS_SUCCESS
        //...result.data,
      });
      yield put({
        type: ActionTypes.ISSUER_PROJECT_ADD_PREVIEW_LINKS,
        previewPicture: {
          id: response.id,
          link: response.link
        }
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_SAVE_PREVIEW_LINKS_FAILURE,
        message: convertCodeToMessage(response)
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_SAVE_PREVIEW_LINKS_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_SAVE_PREVIEW_LINKS_FINISHED,
      payload: {
        requestFrom: 'saveFile'
      }
    });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

export function* watchSaveProjectVideoLinks() {
  yield takeLatest(ActionTypes.ISSUER_PROJECT_SAVE_VIDEO_LINKS, saveProjectVideoLinks);
}

export function* watchSaveProjectPreviewLinks() {
  yield takeLatest(ActionTypes.ISSUER_PROJECT_SAVE_PREVIEW_LINKS, saveProjectPreviewLinks);
}

export function* watchSaveCreateProjectFiles() {
  yield takeLatest(ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILES_LINKS, saveCreateProjectFiles);
}

export function* watchSaveEditProjectFiles() {
  yield takeLatest(ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILES_LINKS, saveEditProjectFiles);
}
