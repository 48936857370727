import React, { Component } from "react";

import setAuthorizationToken from "../utils/setAuthorizationToken";
import { history, login } from "shared/routes/urlLocations";

export default function withCheckUserActivity(WrappedComponent) {
  return class CheckUserActivity extends Component {
    constructor() {
      super();
      this.timer = null;
      this.removeUserActivityListener = this.removeUserActivityListener.bind(this);
      this.addUserActivityListener = this.addUserActivityListener.bind(this);
    }

    addUserActivityListener() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        setAuthorizationToken();
        history.push(login);
      }, 3600000);
      document.addEventListener("mousemove", this.addUserActivityListener);
    }

    removeUserActivityListener() {
      clearTimeout(this.timer);
      document.removeEventListener("mousemove", this.addUserActivityListener);
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          addUserActivityListener={this.addUserActivityListener}
          removeUserActivityListener={this.removeUserActivityListener}
        />
      );
    }
  };
}
