import ActiveRecord from './ActiveRecord';
import NetworkLayer from '../utils/networkLayer';

class Auth extends ActiveRecord {
  constructor() {
    super();
  }

  login() {}

  logout = (url, accessToken) => {
    const request = {
      data: {}
    };
    let tokenType = sessionStorage.getItem('token_type');
    if (tokenType) {
      request.headers = { 'X-Authorization': `${tokenType} ${accessToken}` };
    }

    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  forgotPassword = (url, email) => {
    const request = {
      data: {
        email
      }
    };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  checkUsedToken = url => {
    return NetworkLayer.getJson(url, {})
      .then(response => response)
      .catch(e => e);
  };
}

export default Auth;
