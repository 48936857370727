import React from 'react';
import { ThemedSvg } from 'shared/styled/components';

export default ({ style }) => {
  return (
    <ThemedSvg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 16 16"
    >
      <path
          className="st0"
          d="M14.8,0H1.2C0.5,0,0,0.4,0,1v11c0,0.5,0.5,1,1.2,1H5v1.5H2.5V16h11v-1.5H11V13h3.9c0.6,0,1.1-0.4,1.1-1V3.2V1
	C16,0.4,15.5,0,14.8,0z M13.9,10.3c0,0.3-0.2,0.5-0.5,0.5H2.6c-0.3,0-0.5-0.2-0.5-0.5V2.4c0-0.3,0.2-0.5,0.5-0.5h10.8
	c0.3,0,0.5,0.2,0.5,0.5V10.3z"
      />
    </ThemedSvg>
  );
};
