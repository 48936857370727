import styled from 'styled-components';
import SharedLayouts from 'shared/styled/layouts';
import Layout from 'antd/lib/layout';
const { Sider } = Layout;

const StyledSider = styled(Sider)`
  .submenu-item-name {
    margin: 0 0 0 10px !important;
  }
`;

export default {
  MainLayout: SharedLayouts.MainLayout,
  Sider: StyledSider
};
