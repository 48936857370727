import React from 'react';
import * as pathname from 'shared/routes/urlLocations';
import Icons from 'shared/components/Icons';

const projectsAll = {
  linkTo: pathname.projectsAll,
  IconComponent: Icons.allProject,
  name: 'All projects',
  permissionName: 'TAB_ALL_PROJECTS'
};

const myProjects = {
  linkTo: pathname.projectsMy,
  IconComponent: Icons.myProject,
  name: 'My projects',
  permissionName: 'TAB_MY_PROJECTS'
};

const advisorProjects = {
  linkTo: pathname.projectsMy,
  IconComponent: Icons.myProject,
  name: 'My projects',
  permissionName: 'TAB_MY_PROJECTS'
};

const investorsProjectProjects = {
  linkTo: pathname.projectsMy,
  IconComponent: Icons.myProject,
  name: 'My projects',
  permissionName: 'TAB_MY_PROJECTS'
};

const spInvestorsProjectProjects = {
  linkTo: pathname.projectsMy,
  IconComponent: Icons.myProject,
  name: 'My projects',
  permissionName: 'TAB_SP_PROJECTS'
};

const favoriteProjects = {
  linkTo: pathname.projectsFavorite,
  IconComponent: Icons.star,
  name: 'Favorites',
  permissionName: 'TAB_FAVORITE_PROJECTS'
};

const spProjects = {
  linkTo: pathname.spProjects,
  IconComponent: Icons.myProject,
  name: 'SP projects',
  permissionName: 'TAB_SP_PROJECTS'
};

const spIssuerProjects = {
  linkTo: pathname.projectsMy,
  IconComponent: Icons.myProject,
  name: 'My projects',
  permissionName: 'TAB_MY_PROJECTS'
};

const payments = {
  linkTo: '#',
  IconComponent: Icons.card,
  name: 'Payments',
  permissionName: 'TAB_PAYMENTS'
};

export default {
  issuer: [projectsAll, myProjects, payments],
  investor: [projectsAll, investorsProjectProjects, favoriteProjects, payments],
  advisor: [projectsAll, advisorProjects, payments],
  admin_strategic_partner: [
    projectsAll,
    spProjects,
    payments,
    {
      subMenu: true,
      IconComponent: Icons.user,
      name: 'User handling',
      inPath: pathname.userHandling,
      items: [
        {
          linkTo: pathname.SPAdmins,
          name: 'Admin users',
          permissionName: 'TAB_SP_ADMIN_USERS'
        },
        {
          linkTo: pathname.SPWorkers,
          name: 'Team members',
          permissionName: 'TAB_SP_ADMIN_WORKERS'
        },
        {
          linkTo: pathname.SPInvestorsList,
          name: 'Investors',
          permissionName: 'TAB_SP_ADMIN_INVESTORS'
        },
        {
          linkTo: pathname.SPIssuersList,
          name: 'Issuers',
          permissionName: 'TAB_SP_ADMIN_ISSUERS'
        }
      ]
    },
    {
      linkTo: pathname.spSettings,
      IconComponent: Icons.gear,
      name: 'Settings',
      permissionName: 'TAB_SP_ADMIN_SETTINGS'
    }
  ],
  issuer_strategic_partner: [projectsAll, spProjects, spIssuerProjects, payments],
  investor_strategic_partner: [
    projectsAll,
    spProjects,
    spInvestorsProjectProjects,
    favoriteProjects,
    payments
  ],
  worker_strategic_partner: [
    projectsAll,
    spProjects,
    {
      subMenu: true,
      IconComponent: Icons.user,
      name: 'User handling',
      inPath: pathname.userHandling,
      items: [
        {
          linkTo: pathname.SPWorkers,
          name: 'Team members',
          permissionName: 'TAB_SP_ADMIN_WORKERS'
        },
        {
          linkTo: pathname.SPInvestorsList,
          name: 'Investors',
          permissionName: 'TAB_SP_ADMIN_INVESTORS'
        },
        {
          linkTo: pathname.SPIssuersList,
          name: 'Issuers',
          permissionName: 'TAB_SP_ADMIN_ISSUERS'
        }
      ]
    }
  ],
  compliance_officer_strategic_partner: [
    projectsAll,
    spProjects,
    payments,
    {
      subMenu: true,
      IconComponent: Icons.user,
      name: 'User handling',
      inPath: pathname.userHandling,
      items: [
        {
          linkTo: pathname.SPWorkers,
          name: 'Team members',
          permissionName: 'TAB_SP_ADMIN_WORKERS'
        },
        {
          linkTo: pathname.SPInvestorsList,
          name: 'Investors',
          permissionName: 'TAB_SP_ADMIN_INVESTORS'
        },
        {
          linkTo: pathname.SPIssuersList,
          name: 'Issuers',
          permissionName: 'TAB_SP_ADMIN_ISSUERS'
        }
      ]
    },
    {
      linkTo: pathname.spSettings,
      IconComponent: Icons.gear,
      name: 'Settings',
      permissionName: 'TAB_SP_ADMIN_SETTINGS'
    }
  ]
};
