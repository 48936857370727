import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Layout from 'antd/lib/layout';
import Helmet from 'react-helmet';
import Footer from 'shared/components/Footer';
import { history, projectsAll } from 'shared/routes/urlLocations';
import bg from 'shared/images/main_bg.jpg';
import Header from 'shared/components/Header';
import THEMES from 'shared/constants/themes';
import FullScreenSpinner from 'shared/components/FullScreenSpinner';
import { HEADER_HEIGHT } from 'shared/constants/scroll';
import ScrollbarProvider from 'shared/providers/Scrollbar';
import ModalsProvider from 'shared/providers/Modals';
import ContactUs from 'shared/components/ContactUs';

const subDomain = window.location.host.split('.')[0];

class UnauthorizedLayout extends Component {
  scrollbar = React.createRef();

  componentDidMount() {
    if (sessionStorage.getItem('access_token')) {
      history.push(projectsAll);
    }
  }

  render() {
    const {
      children, showSpinner, exists, spPlatformConfig
    } = this.props;

    const backgroundImage = spPlatformConfig
      && spPlatformConfig.backgroundPicture
      && spPlatformConfig.backgroundPicture.link;

    const title = exists ? `${subDomain} portal` : 'FinBursa application';
    return (
      <React.Fragment>
        <ModalsProvider>
          <Helmet title={title}
              meta={[{ property: 'og:site_name', content: title }]} />
          <ScrollbarProvider
              forwardRef={this.scrollbar}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
          >
            <Layout
                style={{
                  backgroundImage: `url(${backgroundImage || bg})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: backgroundImage ? 'center' : 'center 56px',
                  backgroundColor: '#0D516D',
                  minHeight: '100vh',
                  height: 'auto',
                  paddingTop: backgroundImage ? 0 : HEADER_HEIGHT,
                  backgroundSize: backgroundImage ? 'cover' : 'unset'
                }}
            >
              <Header {...this.props}
                  userTheme={THEMES.WHITE} />
              <div className="ant-form-wrapper-center">{children}</div>
              <Footer spPlatformConfig={spPlatformConfig} />
            </Layout>
          </ScrollbarProvider>
          <FullScreenSpinner showSpinner={showSpinner}
              simple={!!spPlatformConfig} />
          <ContactUs />
        </ModalsProvider>
      </React.Fragment>
    );
  }
}

UnauthorizedLayout.defaultProps = {
  children: '',
  showSpinner: false
};

UnauthorizedLayout.propTypes = {
  children: PropTypes.node,
  showSpinner: PropTypes.bool
};

const mapDispatchToProps = {};

function mapStateToProps({
  showSpinner,
  spPlatform: { config: spPlatformConfig },
  user: {
    strategicPartners: { exists }
  }
}) {
  return {
    showSpinner,
    exists,
    spPlatformConfig
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UnauthorizedLayout)
);
