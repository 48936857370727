import ActiveRecord from './ActiveRecord';
import NetworkLayer from '../utils/networkLayer';

export default class extends ActiveRecord {
  getProjectsSearchResult(url, requestParams) {
    return NetworkLayer.postJson(url, { ...requestParams })
      .then(response => response)
      .catch(e => e);
  }
}
