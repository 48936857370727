import { ActionTypes } from '../constants/index';
import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import networkLayer from '../utils/networkLayer';
import config from '../utils/config';
import notification from '../utils/notifications';
import messages from '../locales/en/common.json';

const API_URL = config.apiUserUrl;

export function* oneTimeSecurityCodeRequest({ token }) {
  if (token) {
    const result = yield call(networkLayer.postJson, `${API_URL}/public/sms/resend/${token}`);

    if (result.status && result.status === 'success') {
      yield call(notification, {
        type: 'success',
        data: { description: messages.success[result.response.code] }
      });
    } else {
      yield call(notification, {
        type: 'error',
        message: 'Error!',
        data: "Secure code hasn't been sent!"
      });
      console.error(result.response.error.description);
    }
  } else {
    console.error('Refresh Security Code error: confirmationId was not passed!');
  }
}

export function* watchOneTimeSecurityCodeRequest() {
  yield takeLatest(ActionTypes.ONE_TIME_SECURITY_CODE_REQUEST, oneTimeSecurityCodeRequest);
}
