import { takeLatest, call, put } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import patternSaga from './patternSaga';
import { fetchUserInformation } from 'shared/actions/userDataActions';
import creationDocumentTypes from '../constants/creationDocumentTypes';
import * as pathname from 'shared/routes/urlLocations';
import notification from '../utils/notifications';

const adminsListSagaConfig = {
  requestFunction: Api.User.fetchUsersList,
  requestUrl: `${utils.config.apiUserUrl}/users/search/strategic-partner-users`,
  onFailure: {
    notification: true
  }
};

const adminRegistrationSagaConfig = {
  requestFunction: Api.User.spAdminCreate,
  requestUrl: `${utils.config.apiUserUrl}/register`,
  onSuccess: {
    trigAction: [fetchUserInformation()],
    redirectTo: pathname.SPAdmins,
    notification: {
      staticNotification: true,
      data: {
        code: '6'
      },
      field: 'email'
    }
  },
  onFailure: {
    notification: true
  },
  spinner: true
};

const adminActivationSagaConfig = {
  requestFunction: Api.User.spAdminActivation,
  redirectTo: pathname.SPAdmins,
  requestUrl: `${utils.config.apiUserUrl}/user`,
  onFailure: {
    notification: true
  },
  spinner: true
};

const adminKYCSagaConfig = {
  requestFunction: Api.User.getSPCompanyKYC,
  requestUrl: `${utils.config.apiUserUrl}/user/me/kyc-data`,
};

const workersListSagaConfig = {
  requestFunction: Api.User.fetchUsersList,
  requestUrl: `${utils.config.apiUserUrl}/users/search/sp-workers`,
  onFailure: {
    notification: true
  }
};

const workerRegistrationSagaConfig = {
  requestFunction: Api.User.spWorkerCreate,
  requestUrl: `${utils.config.apiUserUrl}/register`,
  onSuccess: {
    trigAction: [fetchUserInformation()],
    redirectTo: pathname.SPWorkers,
    notification: {
      staticNotification: true,
      data: {
        code: '6'
      },
      field: 'email'
    }
  },
  onFailure: {
    notification: true
  },
  spinner: true
};

const workerActivationSagaConfig = {
  requestFunction: Api.User.spWorkerActivation,
  redirectTo: pathname.SPWorkers,
  requestUrl: `${utils.config.apiUserUrl}/user`,
  onFailure: {
    notification: true
  },
  spinner: true
};

const investorRegistrationSagaConfig = {
  spinner: true,
  requestFunction: Api.Registration.SPContactRegistration,
  requestUrl: `${utils.config.apiUserUrl}/register`,
  onSuccess: {
    redirectTo: pathname.SPInvestorsList,
    notification: {
      staticNotification: true,
      data: {
        code: '4'
      }
    }
  },
  onFailure: {
    notification: true
  }
};

const issuerRegistrationSagaConfig = {
  spinner: true,
  requestFunction: Api.Registration.SPContactRegistration,
  requestUrl: `${utils.config.apiUserUrl}/register`,
  onSuccess: {
    redirectTo: pathname.SPIssuersList,
    notification: {
      staticNotification: true,
      data: {
        code: '5'
      }
    }
  },
  onFailure: {
    notification: true
  }
};

const investorsListSagaConfig = {
  requestFunction: Api.User.fetchUsersList,
  requestUrl: `${utils.config.apiUserUrl}/users/search/sp-investors`,
  onFailure: {
    notification: true
  }
};

const issuersListSagaConfig = {
  requestFunction: Api.User.fetchUsersList,
  requestUrl: `${utils.config.apiUserUrl}/users/search/sp-issuers`,
  onFailure: {
    notification: true
  }
};

const spComplianceOfficerApproveSagaConfig = {
  requestFunction: Api.Registration.SPComplianceOfficerApproveContact,
  requestUrl: `${utils.config.apiUserUrl}/contacts`,
  onFailure: {
    notification: true
  },
  spinner: true
};

const spComplianceOfficerRejectSagaConfig = {
  requestFunction: Api.Registration.SPComplianceOfficerRejectContact,
  requestUrl: `${utils.config.apiUserUrl}/contacts`,
  onFailure: {
    notification: true
  },
  spinner: true
};

const getSpCreationDocumentsSagaConfig = type => ({
  requestFunction: Api.User.fetchSpCreationDocuments,
  requestUrl: `${utils.config.apiSPCreationDocumentsUrl}?type=${type}`
});

function* saveSpCreationDocument({ file, type }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });

  const documentType =
    type === ActionTypes.SAVE_SP_ISSUERS_CREATION_DOCUMENT
      ? creationDocumentTypes.SP_ISSUER_CREATION_DOCUMENT
      : creationDocumentTypes.SP_INVESTOR_CREATION_DOCUMENT;

  const saveFileUrl = `${utils.config.apiSPCreationDocumentsUrl}?type=${documentType}`;

  const { request, response } = yield call(Api.User.saveFile, saveFileUrl, file);
  yield put({ type: ActionTypes.HIDE_SPINNER });

  if (request.status && request.status === 200) {
    yield call(notification, {
      type: 'success',
      data: {
        description: 'File was successfully uploaded.'
      }
    });
  } else {
    yield call(notification, {
      type: 'error',
      data: response
    });
  }
}

export default function* watchStrategicPartnersSaga() {
  yield takeLatest(ActionTypes.SP_ADMIN_CREATE, patternSaga, adminRegistrationSagaConfig);
  yield takeLatest(
    ActionTypes.SP_ADMIN_ACTIVATION_REQUEST,
    patternSaga,
    adminActivationSagaConfig
  );
  yield takeLatest(ActionTypes.SP_ADMINS_LIST_REQUEST, patternSaga, adminsListSagaConfig);
  yield takeLatest(ActionTypes.SP_ADMIN_KYC_REQUEST, patternSaga, adminKYCSagaConfig);
  yield takeLatest(ActionTypes.SP_WORKER_CREATE, patternSaga, workerRegistrationSagaConfig);
  yield takeLatest(
    ActionTypes.SP_WORKER_ACTIVATION_REQUEST,
    patternSaga,
    workerActivationSagaConfig
  );
  yield takeLatest(ActionTypes.SP_WORKERS_LIST_REQUEST, patternSaga, workersListSagaConfig);
  yield takeLatest(
    ActionTypes.SP_INVESTOR_REGISTRATION,
    patternSaga,
    investorRegistrationSagaConfig
  );
  yield takeLatest(ActionTypes.SP_ISSUER_REGISTRATION, patternSaga, issuerRegistrationSagaConfig);
  yield takeLatest(ActionTypes.SP_INVESTORS_LIST_FETCH, patternSaga, investorsListSagaConfig);
  yield takeLatest(ActionTypes.SP_ISSUERS_LIST_FETCH, patternSaga, issuersListSagaConfig);
  yield takeLatest(
    ActionTypes.SP_COMPLIANCE_OFFICER_APPROVE_CONTACT,
    patternSaga,
    spComplianceOfficerApproveSagaConfig
  );
  yield takeLatest(
    ActionTypes.SP_COMPLIANCE_OFFICER_REJECT_CONTACT,
    patternSaga,
    spComplianceOfficerRejectSagaConfig
  );
  yield takeLatest(
    ActionTypes.GET_SP_ISSUERS_CREATION_DOCUMENTS,
    patternSaga,
    getSpCreationDocumentsSagaConfig(creationDocumentTypes.SP_ISSUER_CREATION_DOCUMENT)
  );

  yield takeLatest(
    ActionTypes.GET_SP_INVESTORS_CREATION_DOCUMENTS,
    patternSaga,
    getSpCreationDocumentsSagaConfig(creationDocumentTypes.SP_INVESTOR_CREATION_DOCUMENT)
  );

  yield takeLatest(ActionTypes.SAVE_SP_ISSUERS_CREATION_DOCUMENT, saveSpCreationDocument);
  yield takeLatest(ActionTypes.SAVE_SP_INVESTORS_CREATION_DOCUMENT, saveSpCreationDocument);
}
