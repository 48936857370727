import { ActionTypes } from '../constants';
import initialState from '../store/initialState';

export default (state = initialState.notifications, action) => {
  switch (action.type) {
    case ActionTypes.UPLOAD_NOTIFICATIONS_SUCCESS:
      let payload = action.payload.content.map(date => {
        return Object.assign({}, date, {
          notifications: date.notifications.map(item =>
            Object.assign({}, item, { extended: false })
          )
        });
      });
      action.payload.content = payload;
      return { ...state, ...action.payload };
    case ActionTypes.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        unread: action.payload
      };
    case ActionTypes.MARK_NOTIFICATION_AS_READ:
      let content = state.content.map(date => {
        return Object.assign({}, date, {
          notifications: date.notifications.map(item => {
            return Object.assign({}, item, {
              extended: item.id === action.id ? true : false,
              read: item.id === action.id ? true : item.read
            });
          })
        });
      });
      return { ...state, content };
    default:
      return state;
  }
};
