import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import { ActionTypes as sharedActionTypes } from 'shared/constants';
import utils, { getQueryStringValue } from '../utils';
import Api from '../api';
import notification from '../utils/notifications';
import patternSaga from './patternSaga';
import { getPrivateBankersListAction } from '../actions/userDataActions';
import { togglePrivateBankerModal } from '../actions/modalsActions';

export function* initialUserInformation() {
  yield put({
    type: ActionTypes.SHOW_SPINNER
  });
  try {
    yield put({
      type: sharedActionTypes.USER_INFORMATION_REQUEST_STARTED,
      payload: {
        requestFrom: 'initialUserInformation'
      }
    });

    const { response, request, status } = yield call(
      Api.User.initiateUserInformation,
      `${utils.config.apiUserUrl}/user/me`
    );
    if (request.status && request.status === 200) {
      yield put({
        type: sharedActionTypes.USER_INFORMATION_REQUEST_SUCCESS,
        message: response
      });
      sessionStorage.setItem('email', response.email);
      sessionStorage.setItem('userType', response.userType);
    } else if (request && request.status === 401) {
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST
      });
    } else {
      yield put({
        type: sharedActionTypes.USER_INFORMATION_REQUEST_FAILURE,
        message: response.message
      });
    }
  } catch ({ message }) {
    yield put({
      type: sharedActionTypes.USER_INFORMATION_REQUEST_FAILURE,
      message
    });
  } finally {
    yield put({
      type: sharedActionTypes.USER_INFORMATION_REQUEST_FINISHED,
      payload: {
        requestFrom: 'initialUserInformation'
      }
    });
    yield put({
      type: ActionTypes.HIDE_SPINNER
    });
  }
}

function* KYCUserStatus({ id, projectionName }) {
  yield put({
    type: ActionTypes.SHOW_SPINNER
  });
  try {
    yield put({
      type: ActionTypes.KYC_USER_STATUS_STARTED,
      payload: {
        requestFrom: 'KYCUserStatus'
      }
    });
    const {
      location: { search }
    } = window;
    const projection = projectionName ? `?projectionName=${projectionName}` : search;
    const { response, request, status } = yield call(
      Api.User.KYCUserStatus,
      `${utils.config.apiUserUrl}/user/${id}${projection}`
    );

    if (request.status && (request.status === 204 || request.status === 200)) {
      delete response.links;
      yield put({
        type: ActionTypes.KYC_USER_STATUS_SUCCESS,
        payload: response
      });
    } else if (request && request.status === 401) {
      yield call(notification, {
        type: 'error',
        data: response.data,
        status
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST
      });
    } else {
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.KYC_USER_STATUS_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.KYC_USER_STATUS_FINISHED,
      payload: {
        requestFrom: 'KYCUserStatus'
      }
    });
    yield put({
      type: ActionTypes.HIDE_SPINNER
    });
  }
}

function* userCVDocumentUpload({ payload: { file } }) {
  yield put({
    type: ActionTypes.SHOW_SPINNER
  });
  const fileServiceUrl = `${utils.config.apiFilesUrl}/users?type=USER_CV_DOCUMENT`;
  const userServiceUrl = `${utils.config.apiUserUrl}/user/cvs/pdf`;
  try {
    yield put({
      type: ActionTypes.ADVISOR_UPLOAD_CV_FILE_REQUEST_STARTED,
      payload: {
        requestFrom: 'AdvisorUploadCVFile'
      }
    });
    const { request, response, status } = yield call(Api.User.uploadCVFile, fileServiceUrl, file);

    if (request.status && (request.status === 202 || request.status === 200)) {
      yield put({
        type: ActionTypes.ADVISOR_UPLOAD_CV_FILE_REQUEST_SUCCESS,
        payload: 'success'
      });
      const { id, link, name } = response;
      const documents = [{ id, link, name }];
      try {
        const { request: newReq } = yield call(Api.User.createCVFile, userServiceUrl, documents);
        if (
          newReq.status
          && (newReq.status === 201 || newReq.status === 202 || newReq.status === 200)
        ) {
          yield put({
            type: ActionTypes.USER_CV_GET_REQUEST
          });
          yield put({
            type: sharedActionTypes.USER_INFORMATION_REQUEST
          });
        }
      } catch ({ message }) {
        yield put({
          type: ActionTypes.ADVISOR_CREATE_CV_FILE_REQUEST_FAILURE,
          message
        });
        yield call(notification, {
          type: 'error',
          data: message
        });
      }
    }
    if (request && request.status === 401) {
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.ADVISOR_UPLOAD_CV_FILE_REQUEST_FAILURE,
      message
    });
    yield call(notification, {
      type: 'error',
      data: message
    });
  } finally {
    yield put({
      type: ActionTypes.ADVISOR_UPLOAD_CV_FILE_REQUEST_FINISHED,
      payload: {
        requestFrom: 'AdvisorUploadCVFile'
      }
    });
    yield put({
      type: ActionTypes.HIDE_SPINNER
    });
  }
}

function* KYCDocumentsSave({ payload: { files, name } }) {
  yield put({
    type: ActionTypes.SHOW_SPINNER
  });
  try {
    yield put({
      type: ActionTypes.SAVE_KYC_DOCUMENTS_STARTED,
      payload: {
        requestFrom: 'KYCDocumentsSave'
      }
    });
    const { request, response, status } = yield call(
      Api.User.KYCDocumentsSave,
      `${utils.config.apiPaymentsUrl}/kyc-documents?kycDocumentType=${name}`,
      files
    );

    if (request.status && (request.status === 202 || request.status === 200)) {
      yield put({
        type: ActionTypes.SAVE_KYC_DOCUMENTS_SUCCESS,
        payload: 'success'
      });
      document.location.reload(true);
    } else if (request && request.status === 401) {
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST
      });
    } else {
      yield put({
        type: ActionTypes.CLEAR_KYC_DOCUMENTS_SECTION,
        payload: {
          name
        }
      });
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.SAVE_KYC_DOCUMENTS_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.SAVE_KYC_DOCUMENTS_FINISHED,
      payload: {
        requestFrom: 'KYCDocumentsSave'
      }
    });
    yield put({
      type: ActionTypes.HIDE_SPINNER
    });
  }
}

function* setUserKYCStatusSagaConfig({ requestConfig, onSuccess }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  try {
    yield put({
      type: ActionTypes.SET_USER_KYC_STATUS_STARTED
    });

    let id = requestConfig.id.split('?')[0];
    let config = { ...requestConfig, id };
    const { response, request, status } = yield call(Api.User.setUserKYCStatus, config);
    if (
      request.status
      && (request.status === 200 || request.status === 201 || request.status === 204)
    ) {
      yield put({
        type: ActionTypes.SET_USER_KYC_STATUS_SUCCESS,
        payload: response
      });
      yield call(notification, {
        type: 'success',
        ...onSuccess.notification,
        status
      });
      yield call(onSuccess.redirectTo);
    } else if (request && request.status === 401) {
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST
      });
    } else {
      yield call(notification, { type: 'error', data: response, status });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.SET_USER_KYC_STATUS_FAILURE,
      message
    });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

const userCVCreateSagaConfig = {
  requestFunction: Api.User.createUserCV,
  requestUrl: `${utils.config.apiUserUrl}/user/cvs`,
  spinner: true,
  onSuccess: {
    notification: {
      staticNotification: true,
      data: {
        code: 9
      }
    }
  },
  onFailure: {
    notification: true
  }
};

const userCVUpdateSagaConfig = {
  requestFunction: Api.User.updateUserCV,
  requestUrl: `${utils.config.apiUserUrl}/user/cvs`,
  spinner: true,
  onSuccess: {
    notification: {
      staticNotification: true,
      data: {
        code: 9
      }
    }
  },
  onFailure: {
    notification: true
  }
};

const userCVGetSagaConfig = {
  requestFunction: Api.User.getUserCV,
  requestUrl: `${utils.config.apiUserUrl}/user/cvs`,
  spinner: true,
  onFailure: {
    notification: true
  }
};

const contactUsRequestConfig = {
  requestFunction: Api.User.contactUsRequest,
  requestUrl: utils.config.apiUserUrl,
  spinner: true,
  onSuccess: {
    notification: {
      staticNotification: true,
      data: {
        code: 19
      }
    }
  },
  onFailure: {
    notification: true
  }
};

// const userCVDocumentUploadConfig = {
//   requestFunction: Api.User.uploadCVFile,
//   requestUrl: `${utils.config.apiFilesUrl}/users?type=USER_CV_DOCUMENT`,
//   spinner: true,
//   onFailure: {
//     notification: true
//   }
// };

// const advisorCreateCVFileConfig = {
//   requestFunction: Api.User.createCVFile,
//   requestUrl: `${utils.config.apiUserUrl}/user/cvs/pdf`,
//   spinner: true,
//   onFailure: {
//     notification: true
//   }
// };

const userSetStepperStatusConfig = {
  requestFunction: Api.User.setStepperStatus,
  requestUrl: `${utils.config.apiUserUrl}/user/me/show-stepper`,
  spinner: true,
  onFailure: {
    notification: true
  }
};

const KYCDocumentsStatusConfig = {
  requestFunction: Api.User.KYCDocumentsStatus,
  requestUrl: `${utils.config.apiUserUrl}/mango-pay/kyc-documents`,
  spinner: true,
  onFailure: {
    notification: true
  }
};

const getPrivateBankersListConfig = {
  spinner: true,
  requestFunction: Api.User.getPrivateBankersList,
  requestUrl: `${utils.config.apiUserUrl}/user/me/private-bankers`,
  onFailure: {
    notification: true
  }
};

const deletePrivateBankerConfig = {
  spinner: true,
  requestFunction: Api.User.deletePrivateBanker,
  requestUrl: `${utils.config.apiUserUrl}/user/me/private-bankers`,
  onSuccess: {
    notification: {
      type: 'success',
      notificationType: 'notification',
      staticNotification: true,
      data: {
        code: '18'
      }
    },
    trigAction: [getPrivateBankersListAction()]
  },
  onFailure: {
    notification: true
  }
};

export function* watchUserData() {
  yield takeLatest(ActionTypes.KYC_USER_STATUS, KYCUserStatus);
  yield takeLatest(ActionTypes.SAVE_KYC_DOCUMENTS, KYCDocumentsSave);
  yield takeLatest(ActionTypes.KYC_DOCUMENTS_STATUS, patternSaga, KYCDocumentsStatusConfig);
  yield takeLatest(ActionTypes.SET_USER_STEPPER_STATUS, patternSaga, userSetStepperStatusConfig);
  yield takeLatest(sharedActionTypes.USER_INFORMATION_REQUEST, initialUserInformation);
  yield takeLatest(ActionTypes.USER_CV_CREATE_REQUEST, patternSaga, userCVCreateSagaConfig);
  yield takeLatest(ActionTypes.USER_CV_UPDATE_REQUEST, patternSaga, userCVUpdateSagaConfig);
  yield takeLatest(ActionTypes.USER_CV_GET_REQUEST, patternSaga, userCVGetSagaConfig);
  yield takeLatest(ActionTypes.ADVISOR_UPLOAD_CV_FILE_REQUEST, userCVDocumentUpload);
  // yield takeLatest(
  //   ActionTypes.ADVISOR_CREATE_CV_FILE_REQUEST,
  //   patternSaga,
  //   advisorCreateCVFil
  // );
  yield takeLatest(ActionTypes.SET_USER_KYC_STATUS, setUserKYCStatusSagaConfig);
  yield takeLatest(ActionTypes.GET_PRIVATE_BANKERS_LIST, patternSaga, getPrivateBankersListConfig);
  yield takeLatest(ActionTypes.DELETE_PRIVATE_BANKER, patternSaga, deletePrivateBankerConfig);
  yield takeLatest(sharedActionTypes.CONTACT_US_REQUEST, patternSaga, contactUsRequestConfig);
}
