import { ActionTypes } from '../constants';

export const fetchListSPWorkers = (
  page,
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.SP_WORKERS_LIST_REQUEST,
  requestConfig: {
    projection: 'admin',
    sort: `${sort.field},${sort.order}`,
    size: 20,
    page: (page && page.current && page.current - 1) || 0
  }
});

export const spWorkerCreate = values => ({
  type: ActionTypes.SP_WORKER_CREATE,
  requestConfig: {
    ...values,
    type: 'USER'
  }
});

export const spWorkerActivation = (id, enable, currentPage) => ({
  type: ActionTypes.SP_WORKER_ACTIVATION_REQUEST,
  requestConfig: {
    id,
    enable
  },
  onSuccessActions: {
    type: ActionTypes.SP_WORKERS_LIST_REQUEST,
    requestConfig: {
      projection: 'admin',
      sort: 'createdAt,desc',
      size: 20,
      page: currentPage || 0
    }
  }
});

export const spInvestorCreate = values => ({
  type: ActionTypes.SP_INVESTOR_REGISTRATION,
  requestConfig: {
    ...values,
    userType: 'INVESTOR_STRATEGIC_PARTNER'
  }
});

export const spInvestorsListFetch = (
  page,
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.SP_INVESTORS_LIST_FETCH,
  requestConfig: {
    sort: `${sort.field},${sort.order}`,
    size: 20,
    page: (page && page.current && page.current - 1) || 0
  }
});

export const spIssuerCreate = values => ({
  type: ActionTypes.SP_ISSUER_REGISTRATION,
  requestConfig: {
    ...values,
    userType: 'ISSUER_STRATEGIC_PARTNER'
  }
});

export const spIssuersListFetch = (
  page,
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.SP_ISSUERS_LIST_FETCH,
  requestConfig: {
    sort: `${sort.field},${sort.order}`,
    size: 20,
    page: (page && page.current && page.current - 1) || 0
  }
});

export const spComplianceOfficerApproveContact = (id, currentPage, usersType) => ({
  type: ActionTypes.SP_COMPLIANCE_OFFICER_APPROVE_CONTACT,
  requestConfig: {
    id
  },
  onSuccessActions: {
    type: ActionTypes[`SP_${usersType}_LIST_FETCH`],
    requestConfig: {
      sort: 'createdAt,desc',
      size: 20,
      page: currentPage || 0
    }
  }
});

export const spComplianceOfficerRejectContact = (id, reason, currentPage, usersType) => ({
  type: ActionTypes.SP_COMPLIANCE_OFFICER_REJECT_CONTACT,
  requestConfig: {
    id,
    reason
  },
  onSuccessActions: {
    type: ActionTypes[`SP_${usersType}_LIST_FETCH`],
    requestConfig: {
      sort: 'createdAt,desc',
      size: 20,
      page: currentPage || 0
    }
  }
});
