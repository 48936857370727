import { takeLatest, select } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import { ActionTypes as sharedActionTypes } from 'shared/constants';
import utils from '../utils';
import patternSaga from './patternSaga';
import Api from '../api';
import { call, put } from 'redux-saga/effects';
import notification from '../utils/notifications';
import mangoPay from 'mangopay-cardregistration-js-kit';
import moment from 'moment';
import store from '../store';
import {
  setInvestmentWithCodeDetailsAction,
  getPaymentBankAccountsListAction,
  getSubscriptionListAction
} from '../actions/paymentsActions';
import { setActiveModal } from 'shared/actions/activeModalActions';
import { getProjectDetails } from '../actions/projectsActions';
import { SubmissionError } from 'redux-form';

const getListOfCardsConfig = {
  spinner: true,
  requestFunction: Api.User.getPaymentsList,
  requestUrl: `${utils.config.apiPaymentsUrl}/cards`,
  onFailure: {
    notification: true
  }
};

function* addMoneyFromCardToWallet({ data }) {
  try {
    yield put({
      type: ActionTypes.ADD_MONEY_FROM_BANK_ACCOUNT_TO_WALLET_STARTED
    });
    yield put({ type: ActionTypes.SHOW_SPINNER });

    const { response, request, status } = yield call(
      Api.User.addMoneyFromCardToWallet,
      `${utils.config.apiPaymentsUrl}/cards/${data.id}/direct-payin`,
      { data }
    );

    if (request.status && request.status === 200) {
      if (response.status === 'CREATED') {
        // for escape default prompt
        window.onbeforeunload = null;
        // after this ${paymentProvider} will redirect us back in same window
        window.location.replace(response.secureModeRedirectUrl);
      } else {
        yield put({ type: ActionTypes.TOGGLE_INVEST_MODAL });
        yield put({ type: sharedActionTypes.USER_INFORMATION_REQUEST });
        yield put({ type: ActionTypes.GET_WALLET_BALANCE, id: data.id });
      }
    } else if (
      request.status
      && (request.status === 500 || request.status === 502 || request.status === 503)
    ) {
      yield call(notification, {
        type: 'error',
        data: response
      });
    } else {
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
    }
  } catch (e) {
    yield call(notification, {
      type: 'error',
      data: e.message
    });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({
      type: ActionTypes.ADD_MONEY_FROM_BANK_ACCOUNT_TO_WALLET_FINISHED
    });
  }
}

const addMoneyFromBankAccountToWalletConfig = {
  spinner: true,
  requestFunction: Api.User.addMoneyFromBankAccountToWallet,
  requestUrl: `${utils.config.apiPaymentsUrl}/bankwire/direct`,
  onSuccess: {
    trigAction: {
      type: sharedActionTypes.USER_INFORMATION_REQUEST
    }
  },
  onFailure: {
    notification: true
  }
};

const getSubscriptionList = {
  requestFunction: Api.User.getSubscriptionsList,
  requestUrl: `${utils.config.apiUserUrl}/subscriptions`,
  onFailure: {
    notification: true
  }
};

const getPaymentForPublishingConfig = {
  requestFunction: Api.User.getPaymentForPublishing,
  requestUrl: `${utils.config.apiPlatformConfigUrl}/project-publishing-price`,
  onFailure: {
    notification: true
  }
};

const getLastActiveSubscriptionConfig = {
  spinner: true,
  requestFunction: Api.User.getLastActiveSubscription,
  requestUrl: `${utils.config.apiUserUrl}/paid-subscriptions`,
  onFailure: {
    notification: true
  }
};

const getSubscriptionHistoryConfig = {
  requestFunction: Api.User.getSubscriptionHistory,
  requestUrl: `${utils.config.apiUserUrl}/paid-subscriptions/history`,
  onFailure: {
    notification: true
  }
};

const payForSubscriptionConfig = {
  spinner: true,
  requestFunction: Api.User.payForSubscription,
  requestUrl: `${utils.config.apiUserUrl}/subscriptions`,
  onFailure: {
    notification: true
  },
  onSuccess: {
    notification: {
      notificationType: 'notification',
      staticNotification: true,
      data: {
        code: '0'
      }
    },
    trigAction: [
      { type: sharedActionTypes.USER_INFORMATION_REQUEST },
      { type: ActionTypes.GET_LAST_ACTIVE_SUBSCRIPTION },
      { type: ActionTypes.GET_SUBSCRIPTION_HISTORY }
    ],
    callback: ({ user: { info: { subDomain = null } = {}} = {}}) => {
      store.dispatch(getSubscriptionListAction(subDomain));
    }
  }
};

const changeAutorenewableConfig = {
  spinner: true,
  requestFunction: Api.User.changeAutoRenewable,
  requestUrl: `${utils.config.apiPlatformConfigUrl}/auto-renewable-subscription`,
  onFailure: {
    notification: true
  },
  onSuccess: {
    notification: {
      notificationType: 'notification',
      staticNotification: true,
      data: {
        code: '0'
      }
    },
    trigAction: [
      { type: sharedActionTypes.USER_INFORMATION_REQUEST },
      { type: ActionTypes.GET_LAST_ACTIVE_SUBSCRIPTION }
    ],
    callback: ({ user: { info: { subDomain = null } = {}} = {}}) => {
      store.dispatch(getSubscriptionListAction(subDomain));
    }
  }
};

const investToProjectConfig = {
  spinner: true,
  requestFunction: Api.User.investToProject,
  requestUrl: `${utils.config.apiProjectUrl}/projects`,
  onFailure: {
    notification: true
  },
  onSuccess: {
    notification: {
      notificationType: 'notification',
      staticNotification: true,
      data: {
        code: '0'
      }
    }
  }
};

const investToProjectPrivateBankersConfig = {
  spinner: true,
  requestFunction: Api.User.investToProjectPrivateBankers,
  requestUrl: `${utils.config.apiProjectUrl}/projects`,
  onFailure: {
    notification: true
  },
  onSuccess: {
    notification: {
      notificationType: 'notification',
      staticNotification: true,
      data: {
        code: '0'
      }
    }
  }
};

const getListOfBankAccountsConfig = {
  spinner: true,
  requestFunction: Api.User.getPaymentsList,
  requestUrl: `${utils.config.apiPaymentsUrl}/bank-accounts`,
  onFailure: {
    notification: true
  }
};

const moneyRepayConfig = {
  spinner: true,
  requestFunction: Api.User.moneyRepay,
  requestUrl: `${utils.config.apiPaymentsUrl}/payouts/bankwire`,
  onSuccess: {
    notification: {
      notificationType: 'notification',
      staticNotification: true,
      data: {
        code: '8'
      }
    }
  },
  onFailure: {
    notification: {
      notificationType: 'notification',
      staticNotification: true,
      data: {
        code: '5'
      }
    }
  }
};

const getWalletBalanceConfig = {
  spinner: true,
  requestFunction: Api.User.getWalletBalance,
  requestUrl: `${utils.config.apiPaymentsUrl}/user/wallet/balance`,
  onFailure: {
    notification: true
  }
};

const createPaymentBankAccountConfig = {
  spinner: true,
  requestFunction: Api.User.createPaymentBankAccount,
  requestUrl: `${utils.config.apiPaymentsUrl}/bank-accounts`,
  onFailure: {
    notification: true
  },
  onSuccess: {
    notification: {
      notificationType: 'notification',
      staticNotification: true,
      data: {
        code: '0'
      }
    },
    trigAction: [
      {
        type: sharedActionTypes.USER_INFORMATION_REQUEST
      },
      getPaymentBankAccountsListAction(),
      setActiveModal()
    ]
  }
};

function* validateInvestmentCode({
  payload: { projectID, investmentCode, onAsyncValidionFailed }
}) {
  yield put({ type: ActionTypes.SHOW_SPINNER });

  const { response, request } = yield call(
    Api.User.validateInvestmentCode,
    `${utils.config.apiProjectsUrl}/${projectID}/investment-requests?investmentId=${investmentCode}`
  );

  if (request.status && request.status === 200) {
    yield put({ type: ActionTypes.TOGGLE_INVEST_CODE_MODAL });
    yield put(setInvestmentWithCodeDetailsAction({ ...response, investmentCode }));
    yield put({ type: ActionTypes.TOGGLE_INVEST_WITH_CODE_CONFIRM_MODAL });
  } else {
    onAsyncValidionFailed(
      new SubmissionError({
        code: "Reference code isn't valid. Please type another code."
      })
    );
  }

  yield put({ type: ActionTypes.HIDE_SPINNER });
}

function* investWithCode({ payload: { projectID, investmentCode } }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });

  const { response, request, status } = yield call(
    Api.User.investWithCode,
    `${utils.config.apiProjectsUrl}/${projectID}/investment-requests/invest`,
    { data: { investmentId: investmentCode } }
  );

  if (request.status && request.status === 200) {
    yield put(getProjectDetails(projectID));

    yield call(notification, {
      type: 'success',
      staticNotification: true,
      data: { code: 0 }
    });
  } else {
    yield call(notification, { type: 'error', data: response, status });
  }

  yield put({ type: ActionTypes.TOGGLE_INVEST_WITH_CODE_CONFIRM_MODAL });
  yield put({ type: ActionTypes.HIDE_SPINNER });
}

function* registerPaymentCard({ values }) {
  let data = {
    tag: values.tag,
    currency: values.currency
  };

  yield put({ type: ActionTypes.REGISTER_PAYMENT_CARD_STARTED });
  yield put({ type: ActionTypes.SHOW_SPINNER });
  try {
    const { response, request, status } = yield call(
      Api.User.paymentPostRequest,
      `${utils.config.apiPaymentsUrl}/cards`,
      data
    );
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.REGISTER_PAYMENT_CARD_SUCCESS
      });
      data = {
        ...data,
        ...values,
        ...response,
        data: response.preregistrationData,
        accessKeyRef: response.accessKey,
        cardExpirationDate: moment(values.cardExpirationDate).format('MMYY'),
        cardCvx: values.cardCvx,
        cardType: 'CB_VISA_MASTERCARD'
      };
      mangoPay.cardRegistration.baseURL = window.MANGOPAY_BASE_URL;
      mangoPay.cardRegistration.clientId = window.MANGOPAY_CLIENT_ID;
      mangoPay.cardRegistration.init({
        cardRegistrationURL: response.cardRegistrationUrl,
        preregistrationData: response.preregistrationData,
        accessKey: response.accessKey,
        Id: response.id
      });
      mangoPay.cardRegistration.registerCard(
        data,
        e => {
          console.log(e);
          store.dispatch({
            type: 'SAVE_PAYMENT_CARD',
            CardId: e.CardId,
            tag: data.tag
          });
          notification({
            type: 'success',
            data: { code: 0 }
          });
        },
        e => {
          console.log(e);
          notification({
            type: 'error',
            data: { code: 0 }
          });
        }
      );
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({ type: ActionTypes.REGISTER_PAYMENT_CARD_FAILURE });
      yield call(notification, { type: 'error', data: response });
      yield put({ type: ActionTypes.HIDE_SPINNER });
    }
  } catch (e) {
    yield put({ type: ActionTypes.REGISTER_PAYMENT_CARD_FAILURE });
    yield call(notification, { type: 'error', data: { code: 0 } });
    yield put({ type: ActionTypes.HIDE_SPINNER });
    console.log('error: ', e);
  } finally {
    yield put({ type: ActionTypes.REGISTER_PAYMENT_CARD_FINISHED });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* savePaymentCard({ CardId, tag }) {
  yield put({ type: ActionTypes.SAVE_PAYMENT_CARD_STARTED });
  const data = {
    tag
  };
  try {
    const { response, request, status } = yield call(
      Api.User.paymentPostRequest,
      `${utils.config.apiPaymentsUrl}/cards/${CardId}`,
      data
    );
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.SAVE_PAYMENT_CARD_SUCCESS
      });

      yield put(setActiveModal());

      yield put({
        type: sharedActionTypes.USER_INFORMATION_REQUEST
      });

      yield put({
        type: ActionTypes.GET_PAYMENT_CARDS_LIST
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.SAVE_PAYMENT_CARD_FAILURE });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    }
  } catch (e) {
    yield put({ type: ActionTypes.SAVE_PAYMENT_CARD_FAILURE });
  } finally {
    yield put({ type: ActionTypes.SAVE_PAYMENT_CARD_FINISHED });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

export default function* registerPaymentCardWatch() {
  yield takeLatest(ActionTypes.REGISTER_PAYMENT_CARD, registerPaymentCard);
  yield takeLatest(ActionTypes.SAVE_PAYMENT_CARD, savePaymentCard);
  yield takeLatest(ActionTypes.GET_PAYMENT_CARDS_LIST, patternSaga, getListOfCardsConfig);
  yield takeLatest(
    ActionTypes.CREATE_PAYMENT_BANK_ACCOUNT,
    patternSaga,
    createPaymentBankAccountConfig
  );
  yield takeLatest(
    ActionTypes.GET_PAYMENT_BANK_ACCOUNTS_LIST,
    patternSaga,
    getListOfBankAccountsConfig
  );
  yield takeLatest(ActionTypes.GET_WALLET_BALANCE, patternSaga, getWalletBalanceConfig);
  yield takeLatest(ActionTypes.INVEST_TO_PROJECT, patternSaga, investToProjectConfig);
  yield takeLatest(
    ActionTypes.INVEST_PROJECT_PRIVATE_BANKERS,
    patternSaga,
    investToProjectPrivateBankersConfig
  );
  yield takeLatest(ActionTypes.VALIDATE_INVESTMENT_CODE, validateInvestmentCode);
  yield takeLatest(ActionTypes.INVEST_WITH_CODE, investWithCode);
  yield takeLatest(ActionTypes.GET_SUBSCRIPTIONS_LIST, patternSaga, getSubscriptionList);
  yield takeLatest(ActionTypes.PAY_FOR_SUBSCRIPTION, patternSaga, payForSubscriptionConfig);
  yield takeLatest(
    ActionTypes.CHANGE_AUTORENEWABLE_SUBSCRIPTION,
    patternSaga,
    changeAutorenewableConfig
  );
  yield takeLatest(
    ActionTypes.GET_PAYMENT_FOR_PUBLISHING,
    patternSaga,
    getPaymentForPublishingConfig
  );
  yield takeLatest(ActionTypes.REPAY_MONEY, patternSaga, moneyRepayConfig);
  yield takeLatest(ActionTypes.ADD_MONEY_FROM_CARD_TO_WALLET, addMoneyFromCardToWallet);

  yield takeLatest(
    ActionTypes.ADD_MONEY_FROM_BANK_ACCOUNT_TO_WALLET,
    patternSaga,
    addMoneyFromBankAccountToWalletConfig
  );

  yield takeLatest(
    ActionTypes.GET_LAST_ACTIVE_SUBSCRIPTION,
    patternSaga,
    getLastActiveSubscriptionConfig
  );

  yield takeLatest(
    ActionTypes.GET_SUBSCRIPTION_HISTORY,
    patternSaga,
    getSubscriptionHistoryConfig
  );
}
