import { ActionTypes } from '../constants';
import initialState from '../store/initialState';

export default (state = initialState.search, { type, payload = {} }) => {
  switch (type) {
    case ActionTypes.PROJECTS_SEARCH_FILTERS_SET:
      return {
        ...state,
        projects: {
          ...state.projects,
          ...payload
        }
      };

    case ActionTypes.PROJECTS_SEARCH_SET_TAG:
      return {
        ...state,
        projects: {
          ...state.projects,
          ...payload
        }
      };

    case ActionTypes.PROJECTS_SEARCH_TOGGLE_SEARCH_BAR:
      return {
        ...state,
        projects: {
          ...state.projects,
          searchBarIsOpen: !state.projects.searchBarIsOpen
        }
      };

    default:
      return state;
  }
};
