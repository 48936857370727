import { ActionTypes } from '../constants';
import initialState from '../store/initialState';

export default (state = initialState.tags, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_TAGS_SUCCESS:
      return [...action.payload.content];

    default:
      return state;
  }
};
