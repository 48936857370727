import { takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import patternSaga from './patternSaga';
import * as pathname from 'shared/routes/urlLocations';

const GetOngoingSPProjectsConfig = {
  requestFunction: Api.Projects.getSpProjects,
  onFailure: {
    notification: true
  }
};

const GetFinishedSPProjectsConfig = {
  requestFunction: Api.Projects.getSpProjects,
  onFailure: {
    notification: true
  }
};

const GetRejectedSPProjectsSagaConfig = {
  requestFunction: Api.Projects.getSpProjects,
  onFailure: {
    notification: true
  }
};

const GetPendingSPProjectsSagaConfig = {
  requestFunction: Api.Projects.getSpProjects,
  onFailure: {
    notification: true
  }
};

const projectApproveSagaConfig = {
  spinner: true,
  requestFunction: Api.Projects.approveProject,
  requestUrl: `${utils.config.apiProjectUrl}/projects`,
  onSuccess: {
    redirectTo: [pathname.spProjects, { activeTab: '3' }],
    notification: {
      staticNotification: true,
      data: {
        code: "12"
      }
    }
  },
  onFailure: {
    notification: true
  }
};

const projectRejectSagaConfig = {
  spinner: true,
  requestFunction: Api.Projects.rejectProject,
  requestUrl: `${utils.config.apiProjectUrl}/projects`,
  onSuccess: {
    redirectTo: [pathname.spProjects, { activeTab: '3' }],
    notification: {
      staticNotification: true,
      data: {
        code: '13'
      }
    }
  },
  onFailure: {
    notification: true
  }
};

export default function* watchSPProjectsSaga() {
  yield takeLatest(ActionTypes.PROJECT_REJECT_REQUEST, patternSaga, projectRejectSagaConfig);
  yield takeLatest(ActionTypes.PROJECT_APPROVE_REQUEST, patternSaga, projectApproveSagaConfig);
  yield takeLatest(ActionTypes.GET_ONGOING_SP_PROJECTS, patternSaga, GetOngoingSPProjectsConfig);
  yield takeLatest(ActionTypes.GET_FINISHED_SP_PROJECTS, patternSaga, GetFinishedSPProjectsConfig);
  yield takeLatest(
    ActionTypes.GET_PENDING_SP_PROJECTS,
    patternSaga,
    GetPendingSPProjectsSagaConfig
  );
  yield takeLatest(
    ActionTypes.GET_REJECTED_SP_PROJECTS,
    patternSaga,
    GetRejectedSPProjectsSagaConfig
  );
}
