module.exports = {
  color: {
    green: '#64ea91',
    blue: '#8fc9fb',
    purple: '#d897eb',
    red: '#f69899',
    yellow: '#f8c82e',
    peach: '#f797d6',
    borderBase: '#e5e5e5',
    borderSplit: '#f4f4f4',
    grass: '#d6fbb5',
    sky: '#c1e0fc',
    arsenic: '#403F4C',
    desire: '#E84855',
    stil_de_gran_yellow: '#F9DC5C',
    brillant_azure: '#3185FC',
    portland_orange: '#F46036',
    jungle_green: '#1B998B',
    space_cadet: '#2E294E',
    red_purple: '#E40066',
    tumblewed: '#F1AB86',
    russian_green: '#5C946E',
    darky_blue: '#1d2a3a'
  }
};
