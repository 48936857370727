import { takeLatest } from 'redux-saga/effects';
import { call, put } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import NetworkLayer from '../utils/networkLayer';
import utils from '../utils';
import notification from '../utils/notifications';

function* getCountriesSaga() {
  try {
    const { response, request, status } = yield call(
      NetworkLayer.getJson,
      `${utils.config.apiUserUrl}/public/countries`
    );
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.GET_COUNTRIES_SUCCESS,
        payload: response
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    }
  } catch (e) {
    console.log('error: ', e);
  }
}

export default function* watchCountriesSaga() {
  yield takeLatest(ActionTypes.GET_COUNTRIES_REQUEST, getCountriesSaga);
}
