import ActiveRecord from './ActiveRecord';
import NetworkLayer from '../utils/networkLayer';

export default class extends ActiveRecord {
  getTemplate = (url, requestParams) => {
    return NetworkLayer.getJson(url, { ...requestParams })
      .then(response => {
        return response;
      })
      .catch(e => {
        return e;
      });
  };

  getNDATemplate = url => {
    const request = {
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf'
      },
      data: {},
      params: {},
      responseType: 'blob'
    };
    return NetworkLayer.getJson(url, request)
      .then(response => response)
      .catch(e => e);
  };
}
