import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import resources from 'shared/locales';

i18n
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'en',
    debug: false,
    resources,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
