import config from './config';
import menu from '../constants/menu';
import NetworkLayer from './networkLayer';
import { color } from './theme';
import moment from 'moment';

window.String.prototype.hyphenToHump = function () {
  return this.replace(/-(\w)/g, function () {
    return arguments[1].toUpperCase();
  });
};

window.String.prototype.humpToHyphen = function () {
  return this.replace(/([A-Z])/g, '-$1').toLowerCase();
};

window.Date.prototype.format = function (format) {
  const o = {
    'M+': this.getMonth() + 1,
    'd+': this.getDate(),
    'h+': this.getHours(),
    'H+': this.getHours(),
    'm+': this.getMinutes(),
    's+': this.getSeconds(),
    'q+': Math.floor((this.getMonth() + 3) / 3),
    S: this.getMilliseconds()
  };
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, `${this.getFullYear()}`.substr(4 - RegExp.$1.length));
  }

  for (const k in o) {
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
      );
    }
  }

  return format || null;
};

if (!String.prototype.padStart) {
  window.String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength >>= 0; // floor if number or convert non-number to 0;
    padString = String(padString || ' ');
    if (this.length > targetLength) {
      return String(this);
    }
    targetLength -= this.length;
    if (targetLength > padString.length) {
      padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
    }
    return padString.slice(0, targetLength) + String(this);
  };
}

export default {
  config,
  menu,
  color,
  NetworkLayer
};

export function formatDate(date, format = 'DD.MM.YYYY') {
  if (!date) {
    return null;
  }
  const dateUTC = moment.utc(date);
  return moment(dateUTC)
    .local()
    .format(format);
}

export function isAfter(date, formattedDate) {
  return date ? moment(date).isAfter(formattedDate) : '';
}

export function counterCropper(number) {
  function splice(str, start, rem, subStr) {
    return str.slice(0, start) + subStr + str.slice(start + Math.abs(rem));
  }

  function formatString(str, dif, suffix) {
    str = str.slice(0, str.length - dif);
    return splice(str, str.length - 1, 0, '.').replace('.0', '') + suffix;
  }

  if (number) {
    const str = String(number);

    return (
      (number >= 1000000000 && formatString(str, 8, 'B'))
      || (number >= 1000000 && formatString(str, 5, 'M'))
      || (number >= 1000 && formatString(str, 2, 'K'))
      || str
    );
  }
  return '0';
}

export function badgeStatus(date) {
  if (moment(date).isAfter(moment())) {
    return 'success';
  }
  if (moment(date).isBefore(moment())) {
    return 'error';
  }
  return 'default';
}

export function lowerCaseAllWordsExceptFirstLetters(status) {
  return status
    ? status.charAt(0)
        + status
          .substr(1)
          .toLowerCase()
          .replace(/_/g, ' ')
    : null;
}

export function labelFieldItem(fieldName) {
  return fieldName
    .split(/(?=[A-Z])/)
    .map((key, i) => (i > 0 ? key.toLowerCase() : key.charAt(0).toUpperCase() + key.substr(1)))
    .join(' ');
}

export function isEqual(value, other) {
  let type = Object.prototype.toString.call(value);

  if (type !== Object.prototype.toString.call(other)) return false;

  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  let valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  let otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  function compare(item1, item2) {
    let itemType = Object.prototype.toString.call(item1);

    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    } else {
      if (itemType !== Object.prototype.toString.call(item2)) return false;
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else if (item1 !== item2) return false;
    }
  }

  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  return true;
}

export function urlPrefixValidation(url) {
  if (url && !url.match(/^[a-zA-Z]+:\/\//)) return url.padStart(url.length + 8, 'https://');
  return url || '';
}

export function camelCaseToRegular(text) {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export function camelCaseAllWords(text) {
  const result = text.replace(/ /g, '_');
  const finalResult = result.toUpperCase();
  return finalResult;
}

// Usage example:
// [1,2,1,3,4,4,5].filter( onlyUnique ) => [1, 2, 3, 4, 5]
export function onlyUniqueArray(value, index, self) {
  return self.indexOf(value) === index;
}

export function getQueryStringValue(key) {
  return key
    ? decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          '^(?:.*[&\\?]'
              + encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')
              + '(?:\\=([^&]*))?)?.*$',
          'i'
        ),
        '$1'
      )
    )
    : false;
}

export function replaceFirstSpace(str, end = 's ') {
  return str.replace(/\s/, end);
}
