import { takeLatest } from 'redux-saga/effects';
import { call, put, select } from 'redux-saga/effects';
import { reset } from 'redux-form';
import { ActionTypes } from '../constants';
import utils from '../utils';
import notification from '../utils/notifications';
import Api from '../api';
import moment from 'moment';
import { history, projectEdit } from 'shared/routes/urlLocations';

export function* issuerProjectCreation({ values, goToNextStep }) {
  const {
    projects: { savingProject, editingProject }
  } = yield select();
  let formatedData = '';
  if (values.endDate && values.endDate.format) {
    formatedData = `${values.endDate.format('YYYY-MM-DD')}T23:59:59Z`;
  } else {
    formatedData = values.endDate;
  }

  const data = {
    ...values,
    targetedReturns: savingProject.targetedReturnsDocument ? null : values.targetedReturns,
    fullDescription: savingProject.fullDescriptionDocument ? null : values.fullDescription,
    targetedReturnsDocument: savingProject.targetedReturnsDocument
      ? savingProject.targetedReturnsDocument
      : editingProject.targetedReturnsDocument,
    fullDescriptionDocument: savingProject.fullDescriptionDocument
      ? savingProject.fullDescriptionDocument
      : editingProject.fullDescriptionDocument,
    warningAndDisclaimerDocument: savingProject.warningAndDisclaimerDocument
      ? savingProject.warningAndDisclaimerDocument
      : editingProject.warningAndDisclaimerDocument,
    endDate: formatedData,
    picture: savingProject.picture,
    pictureId: savingProject.pictureId,
    bankAccount: values.bankAccount ? JSON.parse(values.bankAccount) : null
  };
  try {
    yield put({ type: ActionTypes.SHOW_SPINNER });
    yield put({
      type: ActionTypes.ISSUER_PROJECT_CREATION_REQUEST_STARTED,
      savingStatus: ''
    });
    const { response, request, status } = yield call(
      Api.Projects.issuerCreationProject,
      `${utils.config.apiProjectUrl}/projects`,
      data
    );
    if (request.status && request.status === 201) {
      yield put(reset('issuerProjectCreation'));
      yield put({
        type: ActionTypes.ISSUER_PROJECT_CREATION_REQUEST_SUCCESS,
        data: Object.assign(
          {},
          { ...response, bankAccount: JSON.stringify(response.bankAccount) },
          { errorMessage: '' }
        ),
        savingStatus: 'saveSuccess'
      });

      if (response.id) {
        yield call(
          history.push,
          `${projectEdit}/${response.id}${goToNextStep ? '/data-room' : '/project-info'}`
        );
      }

      // yield put({ type: ActionTypes.ISSUER_PROJECT_GET_FULL_REQUEST, id: response.id });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_CREATION_REQUEST_FAILURE,
        savingStatus: 'saveError'
      });
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_CREATION_REQUEST_FAILURE,
      payload: {
        message: error.message,
        statusCode: error.status
      },
      savingStatus: 'saveError'
    });
    yield call(notification, {
      type: 'error',
      data: 'Project creation error'
    });
  } finally {
    yield put({ type: ActionTypes.ISSUER_PROJECT_CREATION_REQUEST_FINISHED });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

export function* issuerProjectCreateUploadFilesLinks() {
  const {
    projects: {
      savingProject: { files: data },
      savingProject
    }
  } = yield select();

  try {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS_STARTED
    });
    const { response, request, status } = yield call(
      Api.Projects.issuerSaveProjectLinks,
      `${utils.config.apiProjectUrl}/projects/${savingProject.id}`,
      data
    );
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS_SUCCESS,
        data: response,
        savingStatus: 'saveSuccess'
      });
      // yield put({ type: ActionTypes.ISSUER_PROJECT_GET_FULL_REQUEST, id: savingProject.id });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS_FAILURE,
        savingStatus: 'saveError'
      });
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS_FAILURE,
      payload: {
        message: error.message,
        statusCode: error.status
      },
      savingStatus: 'saveError'
    });
    yield call(notification, {
      type: 'error',
      data: 'Project update error'
    });
  } finally {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS_FINISHED
    });
  }
}

export function* issuerCreateProjectPublish() {
  const {
    projects: { savingProject, initialProjectValues }
  } = yield select();
  const data = {
    files: savingProject.files,
    published: true,
    approvedStatus: 'PENDING',
    publishedDate: moment.utc(moment().format())
  };
  delete data.rejectionsHistory;
  try {
    yield put({ type: ActionTypes.ISSUER_PROJECT_CREATE_PUBLISH_STARTED });
    const { response, request, status } = yield call(
      Api.Projects.issuerProjectPublish,
      `${utils.config.apiFilesUrl}/projects/${initialProjectValues.id}`,
      data
    );
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_CREATE_PUBLISH_SUCCESS,
        data: Object.assign({}, response, { errorMessage: 'ok' }),
        savingStatus: 'publishSuccess'
      });
    } else if (request.status && request.status === 401) {
      yield sessionStorage.clear();
      yield history.push('/');
      yield call(notification, {
        type: 'error',
        data: 'Please, authorize again!'
      });
    } else {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_CREATE_PUBLISH_FAILURE,
        payload: { message: response },
        savingStatus: 'publishError'
      });
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_CREATE_PUBLISH_FAILURE,
      payload: {
        message: error.message,
        statusCode: error.status
      },
      savingStatus: 'publishError'
    });
    yield call(notification, {
      type: 'error',
      data: 'Project update error'
    });
  } finally {
    yield put({ type: ActionTypes.ISSUER_PROJECT_CREATE_PUBLISH_FINISHED });
  }
}

export function* watchIssuerProjectCreation() {
  yield takeLatest(ActionTypes.ISSUER_PROJECT_CREATION_REQUEST, issuerProjectCreation);
}

export function* watchIssuerProjectCreateUploadFilesLinks() {
  yield takeLatest(
    ActionTypes.ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS,
    issuerProjectCreateUploadFilesLinks
  );
}

export function* watchIssuerCreateProjectPublish() {
  yield takeLatest(ActionTypes.ISSUER_PROJECT_CREATE_PUBLISH, issuerCreateProjectPublish);
}
