import { ActionTypes } from '../constants';

export const toggleInvestCodeModal = () => ({
  type: ActionTypes.TOGGLE_INVEST_CODE_MODAL
});

export const toggleInvestWithCodeConfirmModal = () => ({
  type: ActionTypes.TOGGLE_INVEST_WITH_CODE_CONFIRM_MODAL
});

export const toggleInvestModal = () => ({
  type: ActionTypes.TOGGLE_INVEST_MODAL
});

export const toggleInvestPrivateBankersModal = () => ({
  type: ActionTypes.TOGGLE_INVEST_PRIVATE_BANKERS_MODAL
});

export const toggleIssuerContactModal = () => ({
  type: ActionTypes.TOGGLE_ISSUER_CONTACT_MODAL
});

export const toggleSPModal = () => ({
  type: ActionTypes.TOGGLE_SP_REQUIRED_INFO_MODAL
});

export const toggleRepayModal = () => ({
  type: ActionTypes.TOGGLE_REPAY_MODAL
});

export const rejectProjectModalToggle = () => ({ // in shared
  type: ActionTypes.MODAL_REJECT_PROJECT_TOGGLE
});

export const toggleAccessDataroomModal = () => ({
  type: ActionTypes.TOGGLE_ACCESS_DATAROOM_MODAL
});

export const toggleFirstTimeViewModal = () => ({
  type: ActionTypes.TOGGLE_FIRST_TIME_VIEW_MODAL
});

export const togglePrivateBankerModal = () => ({
  type: ActionTypes.TOGGLE_PRIVATE_BANKER_MODAL
});
