import { ActionTypes } from '../constants';
import initialState from '../store/initialState';

export default (state = initialState.projects, action) => {
  switch (action.type) {
    case ActionTypes.ISSUER_PROJECT_REMOVE_PICTURE:
      return {
        ...state,
        editingProject: {
          ...state.editingProject,
          picture: {},
          pictureId: null
        },
        savingProject: {
          ...state.savingProject,
          picture: null,
          pictureId: null
        },
        savingStatus: ''
      };

    case ActionTypes.GET_INVESTORS_FINISHED_PROJECTS_SUCCESS:
    case ActionTypes.GET_ADVISOR_FINISHED_PROJECTS_SUCCESS:
    case ActionTypes.ISSUER_GET_FINISHED_PROJECTS_SUCCESS:
    case ActionTypes.SP_ISSUER_GET_FINISHED_PROJECTS_SUCCESS:
    case ActionTypes.GET_FINISHED_SP_PROJECTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          finished: action.payload
        }
      };

    case ActionTypes.GET_INVESTORS_FINISHED_FAVORITES_PROJECTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          finishedFavorites: action.payload
        }
      };

    case ActionTypes.GET_INVESTORS_SUCCESSFULLY_FINISHED_PROJECTS_SUCCESS:
    case ActionTypes.GET_ADVISOR_SUCCESSFULLY_FINISHED_PROJECTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          successfullyFinished: action.payload
        }
      };

    case ActionTypes.GET_INVESTORS_ONGOING_PROJECTS_SUCCESS:
    case ActionTypes.GET_ADVISOR_ONGOING_PROJECTS_SUCCESS:
    case ActionTypes.ISSUER_GET_ONGOING_PROJECTS_SUCCESS:
    case ActionTypes.SP_ISSUER_GET_ONGOING_PROJECTS_SUCCESS:
    case ActionTypes.GET_ONGOING_SP_PROJECTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ongoing: action.payload
        }
      };

    case ActionTypes.GET_INVESTORS_ONGOING_FAVORITES_PROJECTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ongoingFavorites: action.payload
        }
      };

    case ActionTypes.ISSUER_GET_SAVED_PROJECTS_SUCCESS:
    case ActionTypes.SP_ISSUER_GET_SAVED_PROJECTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          saved: action.payload
        }
      };

    case ActionTypes.ISSUER_GET_PENDING_PROJECTS_SUCCESS:
    case ActionTypes.SP_ISSUER_GET_PENDING_PROJECTS_SUCCESS:
    case ActionTypes.GET_PENDING_SP_PROJECTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          pending: action.payload
        }
      };

    case ActionTypes.GET_REJECTED_SP_PROJECTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          rejected: action.payload
        }
      };

    case ActionTypes.GET_PROJECT_DATA_ROOM_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        [`${action.payload.listType}RequestProjectDataRoomList`]: {
          ...action.payload
        }
      };
    
    case ActionTypes.GET_PROJECT_INVESTMENTS_LIST_SUCCESS:
      return {
        ...state,
        investmentsList: {
          ...action.payload
        }
      };

    case ActionTypes.ISSUER_PROJECT_CREATION_REQUEST_STARTED:
    case ActionTypes.ISSUER_PROJECT_EDIT_STARTED:
      return {
        ...state,
        savingStatus: action.savingStatus
      };
    case ActionTypes.ISSUER_PROJECT_CREATION_REQUEST_FAILURE:
    case ActionTypes.ISSUER_PROJECT_EDIT_FAILURE:
    case ActionTypes.ISSUER_PROJECT_EDIT_PUBLISH_SUCCESS:
      return {
        ...state,
        savingStatus: action.savingStatus
      };
    case ActionTypes.ISSUER_PROJECT_CREATION_REQUEST_SUCCESS:
    case ActionTypes.ISSUER_PROJECT_EDIT_SUCCESS:
      if (!action.data.regions) {
        action.data.regions = [];
      }
      return {
        ...state,
        initialProjectValues: {
          ...state.initialProjectValues,
          ...action.data
        },
        editingProject: { ...state.editingProject, ...action.data },
        editing: true,
        savingStatus: action.savingStatus
      };
    case ActionTypes.ISSUER_PROJECT_CREATE_PUBLISH_FAILURE:
    case ActionTypes.ISSUER_PROJECT_EDIT_PUBLISH_FAILURE:
      return {
        ...state,
        savingProject: {
          ...state.savingProject,
          errorMessage: action.payload.message
        },
        savingStatus: action.savingStatus
      };
    case ActionTypes.ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS_FAILURE:
    case ActionTypes.ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS_FAILURE:
    case ActionTypes.ISSUER_PROJECT_CREATE_PUBLISH_SUCCESS:
      return {
        ...state,
        savingStatus: action.savingStatus
      };
    case ActionTypes.ISSUER_PROJECT_CREATE_UPLOAD_FILES_LINKS_SUCCESS:
    case ActionTypes.ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS_SUCCESS:
      return {
        ...state,
        initialProjectValues: {
          ...state.initialProjectValues,
          ...action.data
        },
        savingStatus: action.savingStatus
      };
    case ActionTypes.ISSUER_PROJECT_CREATE_CLEAR_FIELDS:
      return {
        ...state,
        editingProject: initialState.projects.editingProject,
        initialProjectValues: initialState.projects.initialProjectValues,
        savingProject: {
          files: {
          }
        },
        editing: false
      };
    case ActionTypes.ISSUER_PROJECT_GET_FULL_REQUEST_SUCCESS:
      return {
        ...state,
        editingProject: {...initialState.projects.editingProject, ...action.project },
        initialProjectValues: {...initialState.projects.initialProjectValues, ...action.project },
        editing: action.editing
      };
    case ActionTypes.ISSUER_PROJECT_EDIT_LOCK:
      return {
        ...state,
        editing: action.editing,
        editingProject: initialState.projects.editingProject
      };
    case ActionTypes.ISSUER_PROJECT_CREATE_ADD_FILE_LINK:
      return {
        ...state,
        savingProject: {
          ...state.savingProject,
          picture: action.link,
          pictureId: action.link.id
        }
      };
    case ActionTypes.ISSUER_PROJECT_CREATE_ADD_FILES_LINKS:
      if (['PROJECT_DOCUMENT', 'PROJECT_PHOTO', 'PROJECT_VIDEO'].indexOf(action.fileType) !== -1) {
        return {
          ...state,
          savingProject: {
            ...state.savingProject,
            files: [
              ...state.savingProject.files,
              action.link
            ]
          },
          savingStatus: ''
        };
      }
      if (action.fileType === 'PROJECT_PDF_DOCUMENT') {
        return {
          ...state,
          savingProject: {
            ...state.savingProject,
            [action.field]: null,
            [`${action.field}Document`]: { ...action.link }
          },
          editingProject: {
            ...state.editingProject,
            [action.field]: null,
            [`${action.field}Document`]: { ...action.link }
          },
          savingStatus: ''
        };
      }
      break;
    case ActionTypes.ISSUER_PROJECT_EDIT_ADD_FILE_LINK:
      return {
        ...state,
        editingProject: {
          ...state.editingProject,
          picture: action.link,
          pictureId: action.link.id
        }
      };
    case ActionTypes.ISSUER_PROJECT_SAVE_FILE_LINKS:
      return {
        ...state,
        savingProject: {
          ...state.savingProject,
          [action.field]: state.savingProject[action.field].concat(action.link)
        }
      };
    case ActionTypes.ISSUER_PROJECT_EDIT_ADD_FILES_LINKS:
      if (['PROJECT_DOCUMENT', 'PROJECT_PHOTO', 'PROJECT_VIDEO'].indexOf(action.fileType) !== -1) {
        return {
          ...state,
          editingProject: {
            ...state.editingProject,
            files: [
              ...state.editingProject.files,
              action.link
            ]
          },
          savingStatus: ''
        };
      }
      if (action.fileType === 'PROJECT_PDF_DOCUMENT') {
        return {
          ...state,
          editingProject: {
            ...state.editingProject,
            [action.field]: null,
            [`${action.field}Document`]: { ...action.link }
          },
          savingProject: {
            ...state.savingProject,
            [action.field]: null,
            [`${action.field}Document`]: { ...action.link }
          },
          savingStatus: ''
        };
      }
    case ActionTypes.ISSUER_CLEAR_FILE_FIELD:
      return {
        ...state,
        editingProject: {
          ...state.editingProject,
          [`${action.fieldName}Document`]: null
        },
        savingProject: {
          ...state.savingProject,
          [`${action.fieldName}Document`]: null
        },
        savingStatus: ''
      };
    case ActionTypes.ISSUER_PROJECT_EDIT_DELETE_FILES:
      return {
        ...state,
        editingProject: {
          ...state.editingProject,
          files: action.files
        },
        savingStatus: ''
      };

    case ActionTypes.ISSUER_PROJECT_CREATE_DELETE_FILES:
      return {
        ...state,
        savingProject: {
          ...state.savingProject,
          files: action.files
        },
        savingStatus: ''
      };
    case ActionTypes.ISSUER_PROJECT_EDIT_ADD_VIDEO:
      return {
        ...state,
        editingProject: {
          ...state.editingProject,
          files: [
            ...state.editingProject.files,
            action.file
          ]
        },
        uploadedVideo: {},
        savingStatus: ''
      };
    case ActionTypes.ISSUER_PROJECT_CREATE_ADD_VIDEO:
      return {
        ...state,
        savingProject: {
          ...state.savingProject,
          files: [
            ...state.savingProject.files,
            action.file
          ]
        },
        uploadedVideo: {},
        savingStatus: ''
      };
    case ActionTypes.ISSUER_PROJECT_ADD_VIDEO_LINKS:
      return {
        ...state,
        uploadedVideo: {
          ...state.uploadedVideo,
          link: action.video.link,
          id: action.video.id
        }
      };
    case ActionTypes.ISSUER_PROJECT_ADD_PREVIEW_LINKS:
      return {
        ...state,
        uploadedVideo: {
          ...state.uploadedVideo,
          previewPicture: action.previewPicture
        }
      };
    case ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILE:
    case ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILE:
    case ActionTypes.ISSUER_RESET_SAVING_STATUS:
      return { ...state, savingStatus: '' };
    case ActionTypes.PROJECT_ALL_DATA_GET_REQUEST_SUCCESS:
      return { ...state, allProjectData: action.payload };
    case ActionTypes.PROJECT_ALL_DATA_CLEAR:
      return { ...state, allProjectData: {} };
    case ActionTypes.PROJECT_REJECTIONS_HISTORY_GET_REQUEST_SUCCESS:
      return { ...state, rejectionsHistory: action.payload };
    case ActionTypes.PROJECT_REJECTIONS_HISTORY_CLEAR:
      return { ...state, rejectionsHistory: {} };
    case ActionTypes.ISSUER_RESET_FILES_SECTION:
      return {
        ...state,
        editingProject: { ...state.editingProject, files: action.files }
      };
    case ActionTypes.PROJECTS_SEARCH_GET_RESULT_SUCCESS:
      return { ...state, allProjectsList: action.payload };
    case ActionTypes.GET_PAYMENT_FOR_PUBLISHING_SUCCESS:
      return {
        ...state,
        publishingPrice: {
          ...state.publishingPrice,
          currency: action.payload.currency,
          price: action.payload.price
        }
      };

    default:
      return state;
  }
};
