const projectTypes = {
  SAVED: 'saved',
  ONGOING: 'ongoing',
  FINISHED: 'finished',
  PENDING: 'pending',
  SUCCESSFULLY_FINISHED: 'successfully-finished',
  ONGOING_FAVORITES: 'ongoing-favorites',
  FINISHED_FAVORITES: 'finished-favorites',
};

export default projectTypes;
