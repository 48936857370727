import { ActionTypes as ActionTypesLocal } from '../constants/';
import { ActionTypes as ActionTypesShared } from 'shared/constants/';

import initialState from '../store/initialState';

export default (state = initialState.payments, action) => {
  switch (action.type) {
    case ActionTypesLocal.ADD_MONEY_FROM_BANK_ACCOUNT_TO_WALLET_SUCCESS:
      return {
        ...state,
        bankCredetials: action.payload
      };
    case ActionTypesLocal.GET_PAYMENT_CARDS_LIST_SUCCESS:
      return {
        ...state,
        cards: action.payload
      };
    case ActionTypesLocal.GET_PAYMENT_BANK_ACCOUNTS_LIST_SUCCESS:
      return {
        ...state,
        bankAccounts: action.payload
      };
    case ActionTypesLocal.GET_SUBSCRIPTIONS_LIST_SUCCESS:
      return {
        ...state,
        subscription: action.payload
      };
    case ActionTypesLocal.GET_LAST_ACTIVE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        lastActiveSubscription: action.payload
      };
    case ActionTypesLocal.GET_SUBSCRIPTION_HISTORY_SUCCESS:
      return {
        ...state,
        subscriptionHistory: action.payload
      };
    case ActionTypesShared.GET_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        wallet: action.payload
      };
    case ActionTypesLocal.REST_BANK_CREDENTIALS:
      return {
        ...state,
        bankCredetials: {}
      };
    case ActionTypesShared.GET_USER_PLATFORM_COMMISSION_SUCCESS:
      return {
        ...state,
        platformCommission: action.payload
      };
    default:
      return state;
  }
};
