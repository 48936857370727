import React from 'react';
import userStatus from 'shared/constants/userStatus';
import { userProfile, SPWorkerCreate, spSettings } from 'shared/routes/urlLocations';
import { Link } from 'react-router-dom';

export const RenderSPAdminGuideContent = {
  icon: 'pc',
  header: 'Please fill the following information to be able to work on the platform.',
  successSubTitle: 'Congratulations!',
  successText: () => 'You have successfully finished all steps and can proceed working on the platform.',
  steps: [
    {
      title: 'Fill KYC data',
      description: 'Pleae fill your user data to be shared with KYC provider.',
      link: `${spSettings}#kyc-check`
    },
    {
      title: 'signNDA',
      description: 'signNDADescription',
      link: `${spSettings}#nda`,
      pendingDescription: 'signNDAPendingDescription'
    },
    /*     {
      title: 'KYC documents',
      description: 'Attach KYC documents to confirm your person.',
      link: `${spSettings}#kyc-documents`
    },
    {
      title: 'Add payment method',
      description: 'Add your bank card or bank account to start work with payments.',
      link: `${spSettings}#payment-methods`
    },
    {
      title: 'Fill wallet',
      description: 'Fill your wallet to minimize platform commission.',
      link: `${spSettings}#payment-methods`
    }, */
    {
      title: 'Buy subscription',
      description: 'Buy a subscription to create your own platform.',
      link: `${spSettings}#subscription`
    },
    {
      title: 'Add Compliance Officer',
      description: 'Please add your platform Compliance Officer.',
      link: SPWorkerCreate
    }
  ]
};

export const renderSPAdminStepStatus = status => {
  switch (status) {
    case userStatus.CONFIRMED:
      return { step: 1, status: 'active' };
    case userStatus.USER_DATA_FILLED:
      return { step: 2, status: 'active' };
    case userStatus.SIGNED:
      return { step: 2, status: 'pending' };
    case userStatus.KYC_PASSED:
    case userStatus.ACCOUNT_CREATED:
    case userStatus.KYC_DOCUMENT_ADDED:
    case userStatus.KYC_DOCUMENT_VALIDATED:
    case userStatus.PAYMENT_METHOD_ADDED:
    case userStatus.WAITING_FOR_WALLET_REPLENISHMENT:
    case userStatus.WALLET_FILLED:
      return { step: 3, status: 'active' };
    case userStatus.SUBSCRIPTION_BOUGHT:
      return { step: 4, status: 'active' };
    case userStatus.COMPLIANCE_OFFICER_ADDED:
      return { step: 5, status: 'active' };
    default:
      return {
        step: 1,
        status: 'active'
      };
  }
  /* switch (status) {
    case userStatus.CONFIRMED:
      return { step: 1, status: 'active' };
    case userStatus.USER_DATA_FILLED:
      return { step: 2, status: 'active' };
    case userStatus.SIGNED:
      return { step: 2, status: 'pending' };
    case userStatus.KYC_PASSED:
      return { step: 2, status: 'pending' };
    case userStatus.ACCOUNT_CREATED:
      return { step: 3, status: 'active' };
    case userStatus.KYC_DOCUMENT_ADDED:
      return { step: 3, status: 'pending' };
    case userStatus.KYC_DOCUMENT_VALIDATED:
      return { step: 4, status: 'active' };
    case userStatus.PAYMENT_METHOD_ADDED:
      return { step: 5, status: 'active' };
    case userStatus.WAITING_FOR_WALLET_REPLENISHMENT:
      return { step: 5, status: 'pending' };
    case userStatus.WALLET_FILLED:
      return { step: 6, status: 'active' };
    case userStatus.SUBSCRIPTION_BOUGHT:
      return { step: 7, status: 'active' };
    case userStatus.COMPLIANCE_OFFICER_ADDED:
      return { step: 8, status: 'active' };
    default:
      return {
        step: 1,
        status: 'active'
      };
  } */
};
