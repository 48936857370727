import React from 'react';
import userStatus from 'shared/constants/userStatus';
import { userProfile, projectsAll } from 'shared/routes/urlLocations';
import { Link } from 'react-router-dom';

export const RenderSPInvestorGuideContent = {
  icon: 'wallet',
  header:
    'Please fill the following information to be able to directly invest into a project or instruct your account manager.',
  successSubTitle: 'Congratulations!',
  successText: action => (
    <React.Fragment>
      You have successfully completed all steps and you can now invest.
    </React.Fragment>
  ),
  steps: [
    /*  {
      title: 'KYC documents',
      description: 'Attach KYC documents to confirm your person.',
      link: `${userProfile}#account`,
      scrollTo: 'kyc'
    },
    {
      title: 'Add payment method',
      description: 'Add your bank card or bank account to start work with payments.',
      link: `${userProfile}#payment-methods`
    },
    {
      title: 'Fill wallet',
      description: 'Fill your wallet to minimize platform commission.',
      pendingDescription:
        'Your transaction to fill wallet was started. We will inform you, when it is successfully finished and wallet is filled.',
      link: `${userProfile}#payment-methods`
    } */
  ]
};

export const renderSPInvestorStepStatus = status => {
  switch (status) {
    case userStatus.ACCOUNT_CREATED:
    case userStatus.KYC_DOCUMENT_ADDED:
    case userStatus.KYC_DOCUMENT_VALIDATED:
    case userStatus.PAYMENT_METHOD_ADDED:
    case userStatus.WAITING_FOR_WALLET_REPLENISHMENT:
    case userStatus.WALLET_FILLED:
      return {
        step: 1,
        status: 'active'
      };
    default:
      return {
        step: 1,
        status: 'active'
      };
  }
};
