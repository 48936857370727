import { ActionTypes } from '../constants';
import initialState from '../store/initialState';

export default (state = initialState.statistic, { type, payload = {} }) => {
  switch (type) {
    case ActionTypes.GET_ALL_STATISTIC_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
