import { put, takeLatest, call } from 'redux-saga/effects';
import { ActionTypes } from '../constants';

const minSpinnerDelay = 500;

const delay = ms => new Promise(res => setTimeout(res, ms));

function* delaySpinner() {
  yield call(delay, minSpinnerDelay);
  yield put({ type: ActionTypes.HIDE_DELAY_SPINNER });
}

export default function* watchSpinnerSaga() {
  yield takeLatest(ActionTypes.HIDE_SPINNER, delaySpinner);
}
