import ActiveRecord from './ActiveRecord';
import NetworkLayer from '../utils/networkLayer';
import urlsConfig from '../utils/config';
import sharedUrlsConfig from 'shared/utils/config';

class Registration extends ActiveRecord {
  constructor() {
    super();
    this._logPromise = Promise.resolve();
  }

  userRegistration(url, data) {
    return NetworkLayer.postJson(url, data)
      .then(response => response)
      .catch(e => e);
  }

  confirmationEmail(url, data) {
    const request = { data };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  }

  checkConfirmToken(url) {
    return NetworkLayer.getJson(url, {})
      .then(response => response)
      .catch(e => e);
  }

  fullInvestorRegistration(url, values) {
    const request = { data: { ...values } };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  }

  confirmInvestor(url) {
    return NetworkLayer.postJson(url, {})
      .then(response => response)
      .catch(e => e);
  }

  SPContactRegistration(url, data) {
    return NetworkLayer.postJson(url, { data })
      .then(response => response)
      .catch(e => e);
  }

  SPComplianceOfficerApproveContact(url, { id }) {
    return NetworkLayer.postJson(`${url}/${id}/approve`)
      .then(response => response)
      .catch(e => e);
  }

  uploadSPPlatformConfigFile(fileType, file) {
    const formData = new FormData();
    formData.append('fileUrl', file);

    return NetworkLayer.postJson(`${urlsConfig.apiFilesUrl}/users?type=${fileType}`, {
      data: formData
    })
      .then(response => response)
      .catch(e => e);
  }

  updateSpPlatformConfig(config) {
    return NetworkLayer.patchJSON(
      `${sharedUrlsConfig.apiPlatformConfigUrl}/white-label-product-config`,
      {
        data: config
      }
    )
      .then(response => response)
      .catch(e => e);
  }

  SPComplianceOfficerRejectContact(url, { id, reason }) {
    return NetworkLayer.postJson(`${url}/${id}/reject`, { data: { reason } })
      .then(response => response)
      .catch(e => e);
  }
}

export default Registration;
