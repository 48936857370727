import { ActionTypes } from '../constants';
import initialState from '../store/initialState';

export default (state = initialState.prompt, { type, isVisibility }) => {
  switch (type) {
    case ActionTypes.PROMPT_VISIBILITY:
      return { ...state, visibility: isVisibility };
    default:
      return state;
  }
};
