import { takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import Api from '../api';
import patternSaga from './patternSaga';
import { getProjectDetails } from '../actions/projectsActions';

const projectAcceptWarningAndDisclaimerConfig = {
  spinner: true,
  requestFunction: Api.Projects.acceptWarningAndDisclaimer,
  onSuccess: {
    notification: false,
    trigAction: [({ projectId }) => getProjectDetails(projectId)]
  },
  onFailure: {
    notification: false
  }
};

export default function* watchProjectAcceptWarningAndDisclaimer() {
  yield takeLatest(
    ActionTypes.PROJECT_ACCEPT_WARNING_AND_DISCLAIMER,
    patternSaga,
    projectAcceptWarningAndDisclaimerConfig
  );
}
