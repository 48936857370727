import React from "react";
import PropTypes from "prop-types";

const ErrorLayout = ({ children }) => <React.Fragment>{children}</React.Fragment>;

ErrorLayout.defaultProps = {
  children: ''
};

ErrorLayout.propTypes = {
  children: PropTypes.node
};

export default ErrorLayout;
