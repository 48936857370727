import axios from 'axios';
import * as pathname from 'shared/routes/urlLocations';
import { history } from 'shared/routes/urlLocations';
import notifications from './notifications';

export const GET_METHOD = 'GET';
export const POST_METHOD = 'POST';
export const PUT_METHOD = 'PUT';
export const DELETE_METHOD = 'DELETE';
export const PATCH_METHOD = 'PATCH';

class NetworkLayer {
  constructor() {
    this._token = '';
    this.JsonHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Cache-control': 'no-cache'
    };
    this.response = [
      {
        code: 0,
        data: {},
        description: 'Internal Server Error'
      }
    ];
  }

  parseResponse = ({ response = undefined, request, data = this.response }, status) => {
    let result = '';
    if (response) result = response.data || response;

    // parse sorting
    const matches = /sort=([^,&]+),?([^&]+)?/gi.exec(request.responseURL);
    if (matches && typeof matches[1] !== 'undefined' && typeof matches[2] !== 'undefined') {
      data.sort = {
        field: matches[1],
        order: matches[2]
      };
    }
    if (request.status === 403) {
      history.push(pathname.projectsAll);
      notifications({
        type: 'error',
        data: [{ description: "User doesn't have access", code: 4002 }]
      });
    }

    if (/^5.{2}$/i.test(request.status)) {
      sessionStorage.setItem('referrerPage', window.location.pathname);
      history.replace(pathname.lostConnection);
    }

    return {
      response: request.status === 500 ? this.response : result || data,
      request,
      status
    };
  };

  get authToken() {
    return this._token;
  }

  set authToken(token = null) {
    this._token = token;
  }

  /**
   * Make request
   *
   * @param {String} url
   * @param {Object} params
   * @param {Object} headers
   * @returns {*}
   */

  getJson = (url, { params = {}, headers = {}, responseType = 'json' } = {}) => {
    return this.json(GET_METHOD, url, {
      headers,
      params,
      responseType
    });
  };

  postJson = (url, { data = {}, headers = {}, params = {} } = {}) => {
    return this.json(POST_METHOD, url, {
      data,
      headers,
      params
    });
  };

  putJson = (url, { data = {}, headers = {}, params = {} } = {}) => {
    return this.json(PUT_METHOD, url, {
      data,
      headers,
      params
    });
  };

  removeJson = (url, data = {}) => {
    return this.json(DELETE_METHOD, url, {
      body: JSON.stringify(data)
    });
  };

  patchJSON = (url, { data = {}, headers = {}, params = {} } = {}) => {
    return this.json(PATCH_METHOD, url, {
      data,
      headers,
      params
    });
  };

  /**
   * Make json call to server
   *
   * @param method
   * @param url
   * @param headers
   * @param body
   * @returns {*}
   */

  json = (
    method = GET_METHOD,
    url = '/',
    {
      headers = {}, data = {}, params = {}, responseType = 'json'
    } = {}
  ) => {
    let customHeaders = { ...this.JsonHeaders, ...headers };
    return this.request(method, url, {
      headers: customHeaders,
      data,
      params,
      responseType
    });
  };

  request = (
    method = GET_METHOD,
    url = null,
    {
      headers = {}, data = {}, params = {}, responseType = 'json'
    } = {}
  ) => {
    let isPublic = url.includes('public');
    if (this._token && !headers['X-Authorization'] && !isPublic && sessionStorage.length > 0) {
      headers['X-Authorization'] = this.authToken;
    }
    return axios({
      url,
      headers,
      method,
      params,
      data,
      // onUploadProgress: progressEvent => {
      //   // Do whatever you want with the native progress event
      //   console.log(
      //     "onUploadProgress",
      //     Math.floor(progressEvent.loaded * 100 / progressEvent.total)
      //   );
      // },
      // onDownloadProgress: progressEvent => {
      //   // let percentCompleted = Math.floor(progressEvent.loaded * 100 / progressEvent.total);
      //   // do whatever you like with the percentage complete
      //   // maybe dispatch an action that will update a progress bar or something
      //   console.log("onDownloadProgress", progressEvent);
      // },
      responseType
    })
      .then(response => this.parseResponse(response, 'success'))
      .catch(error => this.parseResponse(error, 'error'));
  };

  remove = url => {
    return this.request(DELETE_METHOD, url);
  };

  post = url => {
    return this.request(POST_METHOD, url);
  };

  postFormData = (url, data) => {
    return this._formData(POST_METHOD, url, data);
  };

  putFormData = (url, data) => {
    return this._formData(PUT_METHOD, url, data);
  };

  patchFormData = (url, data) => {
    return this._formData(PATCH_METHOD, url, data);
  };

  /**
   *
   * @param method
   * @param url
   * @param data
   * @returns {*}
   * @private
   */

  _formData = (method, url, { data: { file, files }, ...rest }) => {
    var formData = new FormData();
    if (file) {
      formData.append('file', file);
    } else {
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
        formData.append('upload_preset', files[i].name);
        formData.append('timestamp', (Date.now() / 1000) | 0);
      }
    }
    // return this.request(method, url, { data: formData, rest });
    return this.request(method, url, {
      data: formData,
      rest
    });
  };

  patch = (url, data) => {
    return this.request(PATCH_METHOD, url, data);
  };

  prepareParams = params => {
    return Object.keys(params)
      .map(key => params[key] instanceof Array
        ? params[key].map(value => `${key}[]=${value}`).join('&')
        : `${key}=${params[key]}`)
      .join('&');
  };
}

export default new NetworkLayer();
