import { takeLatest } from 'redux-saga/effects';
import {
  call, put, select, all
} from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import notification from '../utils/notifications';
import Api from '../api';
import { history, stubPage } from 'shared/routes/urlLocations';
import patternSaga from './patternSaga';
import { getPrivateBankersListAction } from '../actions/userDataActions';
import { togglePrivateBankerModal } from '../actions/modalsActions';
import { ActionTypes as sharedActionTypes } from 'shared/constants';
import { getSpPlatformConfig } from 'shared/actions/spPlatformConfigActions';
import _ from 'lodash';

// const notificationText = email => `Confirmation e-mail will be sent to ${email}`;
const notificationText = email => `Confirmation link will be sent to your e-mail soon. 
  If you have not received the message to confirm your e-mail address, 
  please check your spam or junk mail folder.`;

function* issuerRegistration(data) {
  yield put({ type: ActionTypes.SHOW_SPINNER });

  try {
    const { response, request, status } = yield call(
      Api.Registration.userRegistration,
      `${utils.config.apiUserUrl}/public/register`,
      { data: { ...data.value, type: 'COMPANY', userType: 'ISSUER' } }
    );
    if (request.status && (request.status === 200 || request.status === 204)) {
      const description = notificationText(data.value.email);
      yield call(notification, {
        type: 'success',
        data: { description }
      });
      yield history.push('/login');
    } else {
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* investorRegistration(data) {
  yield put({ type: ActionTypes.SHOW_SPINNER });

  try {
    const { response, request, status } = yield call(
      Api.Registration.userRegistration,
      `${utils.config.apiUserUrl}/public/register`,
      { data: { ...data.value, type: 'USER' } }
    );
    if (request.status && (request.status === 200 || request.status === 204)) {
      const description = notificationText(data.value.email);
      yield call(notification, {
        type: 'success',
        data: { description }
      });
      yield history.push('/login');
    } else {
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* investorCompanyRegistration(action) {
  try {
    yield put({
      type: ActionTypes.INVESTOR_COMPANY_REGISTRATION_REQUEST_STARTED
    });
    yield put({ type: ActionTypes.SHOW_SPINNER });

    const { values } = action;
    const {
      user: { info }
    } = yield select();
    values.userType = info.userType;
    values.type = 'COMPANY';
    const params = { ...values };
    const { response, request, status } = yield call(
      Api.Registration.fullInvestorRegistration,
      `${utils.config.apiUserUrl}/register`,
      params
    );
    if (request.status && (request.status === 200 || request.status === 204)) {
      const description = notificationText(values.email);
      yield call(notification, {
        type: 'success',
        data: { description: 'Information was saved.' }
      });
      yield put({
        type: sharedActionTypes.USER_INFORMATION_REQUEST
      });
    } else {
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({
      type: ActionTypes.INVESTOR_COMPANY_REGISTRATION_REQUEST_FINISHED
    });
  }
}

function* investorIndividualRegistration(action) {
  try {
    yield put({
      type: ActionTypes.INVESTOR_INDIVIDUAL_REGISTRATION_REQUEST_STARTED
    });
    yield put({ type: ActionTypes.SHOW_SPINNER });

    const { values } = action;
    const {
      user: { info }
    } = yield select();
    values.userType = info.userType;
    values.type = 'INDIVIDUAL';
    const params = { ...values };
    params.birthDate = values.birthDate.format('YYYY-MM-DD');
    if (values.passportExpiry) {
      params.passportExpiry = values.passportExpiry.format('YYYY-MM-DD');
    }
    const { response, request, status } = yield call(
      Api.Registration.fullInvestorRegistration,
      `${utils.config.apiUserUrl}/register`,
      params
    );
    if (request.status && (request.status === 200 || request.status === 204)) {
      const description = notificationText(values.email);
      yield call(notification, {
        type: 'success',
        data: { description: 'Information was saved.' }
      });
      yield put({
        type: sharedActionTypes.USER_INFORMATION_REQUEST
      });
    } else {
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({
      type: ActionTypes.INVESTOR_INDIVIDUAL_REGISTRATION_REQUEST_FINISHED
    });
  }
}

function* advisorRegistration(data) {
  yield put({ type: ActionTypes.SHOW_SPINNER });

  try {
    const { response, request, status } = yield call(
      Api.Registration.userRegistration,
      `${utils.config.apiUserUrl}/public/register`,
      { data: { ...data.values } }
    );
    if (
      request.status
      && (request.status === 200 || request.status === 201 || request.status === 204)
    ) {
      yield call(notification, {
        type: 'success',
        data: {
          field: data.values.email,
          code: 6
        },
        staticNotification: true
      });
      yield history.push('/login');
    } else {
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

const privateBankerRegistrationConfig = {
  spinner: true,
  requestFunction: Api.Registration.userRegistration,
  requestUrl: `${utils.config.apiUserUrl}/user/me/private-bankers`,
  onSuccess: {
    notification: {
      type: 'success',
      notificationType: 'notification',
      staticNotification: true,
      data: {
        code: '17'
      }
    },
    trigAction: [getPrivateBankersListAction(), togglePrivateBankerModal()]
  },
  onFailure: {
    notification: true,
    trigAction: [togglePrivateBankerModal()]
  }
};

function* strategicPartnerRegistration(data) {
  yield put({ type: ActionTypes.SHOW_SPINNER });

  try {
    const { response, request, status } = yield call(
      Api.Registration.userRegistration,
      `${utils.config.apiUserUrl}/public/apply`,
      {
        data: {
          ...data.value,
          type: 'COMPANY',
          status: 'APPLIED',
          userType: 'ADMIN_STRATEGIC_PARTNER'
        }
      }
    );
    if (request.status && (request.status === 200 || request.status === 204)) {
      const description = 'Information is sent to FinBursa Admin user';
      yield call(notification, {
        type: 'success',
        data: { description }
      });
      yield history.push(stubPage);
    } else {
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

const FILES_NAMES = {
  BACKGROUND_PICTURE: 'BACKGROUND_PICTURE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  LOGO: 'LOGO'
};

const fieldFileTypeMap = {
  backgroundPicture: FILES_NAMES.BACKGROUND_PICTURE,
  privacyPolicyDocument: FILES_NAMES.PRIVACY_POLICY,
  termsOfServiceDocument: FILES_NAMES.TERMS_OF_SERVICE,
  logo: FILES_NAMES.LOGO
};

function* strategicPartnerFilesUpload({ values, successAction }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });

  const uploadTasks = Object.entries(values.files).reduce((acc, [field, file]) => {
    if (file) {
      acc.push(call(Api.Registration.uploadSPPlatformConfigFile, fieldFileTypeMap[field], file));
    }

    return acc;
  }, []);

  try {
    const results = yield all(uploadTasks);

    const isFilesUploadSuccess = _.every(
      results,
      ({ request }) => request.status && (request.status === 200 || request.status === 204)
    );

    if (isFilesUploadSuccess) {
      const filesData = results.reduce((acc, { response }) => {
        const fieldName = [_.invert(fieldFileTypeMap)[response.type]];
        return {
          ...acc,
          [fieldName]: response
        };
      }, {});

      values.files = undefined;

      const strategicPartnerModel = {
        ...values,
        ...filesData
      };

      yield put(successAction(strategicPartnerModel));
    } else {
      const errorTasks = [];

      results.forEach(({ request, response }) => {
        if (!(request.status && (request.status === 200 || request.status === 204))) {
          errorTasks.push(
            call(notification, {
              type: 'error',
              data: response
            })
          );
        }
      });

      yield all(errorTasks);
    }
  } catch (e) {
    console.log(e);
  }

  yield put({ type: ActionTypes.HIDE_SPINNER });
}

function* updateSpPlatformConfig({ config }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });

  try {
    const { response, request } = yield call(Api.Registration.updateSpPlatformConfig, config);

    if (request.status && (request.status === 200 || request.status === 204)) {
      yield put({
        type: ActionTypes.UPDATE_SP_PLATFORM_CONFIG_SUCCESS
      });

      const { user } = yield select();
      const subDomain = user && user.info && user.info.subDomain;

      if (subDomain) {
        yield put(getSpPlatformConfig(subDomain));
      }

      const description = 'Platform settings have been successfully updated';
      yield call(notification, {
        type: 'success',
        data: { description }
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_SP_PLATFORM_CONFIG_FAILURE
      });

      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

export default function* registrationWatch() {
  yield takeLatest(ActionTypes.ISSUER_REGISTRATION_REQUEST, issuerRegistration);
  yield takeLatest(ActionTypes.INVESTOR_REGISTRATION_REQUEST, investorRegistration);
  yield takeLatest(ActionTypes.INVESTOR_COMPANY_REGISTRATION_REQUEST, investorCompanyRegistration);
  yield takeLatest(
    ActionTypes.INVESTOR_INDIVIDUAL_REGISTRATION_REQUEST,
    investorIndividualRegistration
  );
  yield takeLatest(ActionTypes.ADVISOR_REGISTRATION_REQUEST, advisorRegistration);
  yield takeLatest(ActionTypes.STRATEGIC_PARTNER_REQUEST, strategicPartnerRegistration);
  yield takeLatest(ActionTypes.STRATEGIC_PARTNER_FILES_UPLOAD, strategicPartnerFilesUpload);
  yield takeLatest(ActionTypes.UPDATE_SP_PLATFORM_CONFIG, updateSpPlatformConfig);
  yield takeLatest(
    ActionTypes.PRIVATE_BANKER_REGISTRATION_REQUEST,
    patternSaga,
    privateBankerRegistrationConfig
  );
}
