import networkLayer from './networkLayer';

export default data => {
  if (data && Object.keys(data).length !== 0) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sessionStorage.setItem(key, data[key]);
      }
    }

    networkLayer.authToken = `${data.token_type} ${data.access_token}`;
  } else {
    if (Object.keys(sessionStorage).length !== 0) {
      sessionStorage.clear();
    }

    networkLayer.authToken = null;
  }
};
