import ActiveRecord from './ActiveRecord';
import NetworkLayer from '../utils/networkLayer';

class Notifications extends ActiveRecord {
  constructor() {
    super();
    this._logPromise = Promise.resolve();
  }

  getNotifications = (url, params) => {
    const request = { params };
    return NetworkLayer.getJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  markNotificationAsRead = (url, params) => {
    const request = { params };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  deleteNotification = (url, params) => {
    const request = { params };
    return NetworkLayer.removeJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  getUnreadNotifications = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };
}

export default Notifications;
