import React from 'react';
import userStatus from 'shared/constants/userStatus';
import { projectsMy, userProfile } from 'shared/routes/urlLocations';
import { Link } from 'react-router-dom';

export const renderIssuerStepStatus = status => {
  switch (status) {
    case userStatus.USER_DATA_FILLED:
      return { step: 1, status: 'active' };
    case userStatus.SIGNED:
      return { step: 1, status: 'pending' };
    case userStatus.KYC_PASSED:
    case userStatus.ACCOUNT_CREATED:
    case userStatus.KYC_DOCUMENT_VALIDATED:
    case userStatus.PAYMENT_METHOD_ADDED:
    case userStatus.WAITING_FOR_WALLET_REPLENISHMENT:
    case userStatus.WALLET_FILLED:
    case userStatus.KYC_DOCUMENT_ADDED:
      return { step: 2, status: 'active' };

    default:
      return {
        step: 1,
        status: 'active'
      };
  }
  /*  switch (status) {
    case userStatus.USER_DATA_FILLED:
      return { step: 1, status: 'active' };
    case userStatus.SIGNED:
      return { step: 1, status: 'pending' };
    case userStatus.KYC_PASSED:
      return { step: 1, status: 'pending' };
    case userStatus.ACCOUNT_CREATED:
      return { step: 2, status: 'active' };
    case userStatus.KYC_DOCUMENT_ADDED:
      return { step: 2, status: 'pending' };
    case userStatus.KYC_DOCUMENT_VALIDATED:
      return { step: 3, status: 'active' };
    case userStatus.PAYMENT_METHOD_ADDED:
      return { step: 4, status: 'active' };
    case userStatus.WAITING_FOR_WALLET_REPLENISHMENT:
      return { step: 4, status: 'pending' };
    case userStatus.WALLET_FILLED:
      return { step: 5, status: 'active' };
    default:
      return {
        step: 1,
        status: 'active'
      };
  } */
};

export const RenderIssuerGuideContent = {
  header: 'Please fill the following information in order to publish projects',
  icon: 'wallet',
  successSubTitle: 'Congratulations!',
  successText: action => (
    <React.Fragment>
      You have successfully finished all required steps. You can now publish your{' '}
      <Link onClick={() => action()}
          to={projectsMy}>
        projects
      </Link>
      .
    </React.Fragment>
  ),
  steps: [
    {
      title: 'signNDA',
      description: 'signNDADescription',
      pendingDescription: 'signNDAPendingDescription_issuer',
      link: `${userProfile}#account`,
      scrollTo: 'nda'
    }
    /* {
      title: 'KYC documents',
      description: 'Attach KYC documents to confirm your person.',
      link: `${userProfile}#account`,
      scrollTo: 'kyc'
    },
    {
      title: 'Add payment method',
      description: 'Add your bank card or bank account to start work with project publishing.',
      link: `${userProfile}#payment-methods`
    },
    {
      title: 'Fill wallet',
      description: 'Fill your wallet to minimize platform commission.',
      pendingDescription:
        'Your transaction to fill wallet was started. We will inform you, when it is successfully finished and wallet is filled.',
      link: `${userProfile}#payment-methods`
    } */
  ]
};
