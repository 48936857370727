import { takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import { history } from 'shared/routes/urlLocations';

export function* confirmInvestor({ token }) {
  try {
    yield put({ type: ActionTypes.INVESTOR_CONFIRM_REQUEST_STARTED });

    const { response, request, status } = yield call(
      Api.Registration.confirmInvestor,
      `${utils.config.apiUserUrl}/public/${token}`
    );
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.INVESTOR_CONFIRM_REQUEST_SUCCESS,
        message: response
      });
      yield delay(4000);
      yield history.push('/login');
    } else {
      yield put({
        type: ActionTypes.INVESTOR_CONFIRM_REQUEST_FAILURE,
        message: response.error.message
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.INVESTOR_CONFIRM_REQUEST_FAILURE,
      message: error.message,
      statusCode: error.status
    });
  } finally {
    yield put({ type: ActionTypes.INVESTOR_CONFIRM_REQUEST_FINISHED });
  }
}

export function* watchConfirmInvestor() {
  yield takeLatest(ActionTypes.INVESTOR_CONFIRM_REQUEST, confirmInvestor);
}
