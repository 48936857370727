import { ActionTypes } from '../constants';
import initialState from '../store/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_COUNTRIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
