import { takeLatest, takeEvery } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import Api from '../api';
import patternSaga from './patternSaga';
import { toggleAccessDataroomModal } from '../actions/modalsActions';
import { getProjectDetails } from '../actions/projectsActions';

const projectDataRoomRequestSagaConfig = {
  requestFunction: Api.Projects.getDataRoomRequestList,
  onFailure: {
    notification: true
  }
};

const projectDataRoomRequestApproveSagaConfig = {
  requestFunction: Api.Projects.approveDataRoomReqeust,
  onSuccess: {
    notification: {
      notificationType: 'notification',
      staticNotification: true,
      data: {
        code: '15'
      }
    }
  },
  onFailure: {
    notification: false
  }
};

const projectDataRoomRequestRejectSagaConfig = {
  requestFunction: Api.Projects.rejectDataRoomRequest,
  onSuccess: {
    notification: {
      notificationType: 'notification',
      staticNotification: true,
      data: {
        code: '16'
      }
    }
  },
  onFailure: {
    notification: false
  }
};

const sendDataRoomAccessRequestSagaConfig = {
  spinner: true,
  requestFunction: Api.Projects.sendDataroomAccessRequest,
  onFailure: {
    notification: false,
    trigAction: [toggleAccessDataroomModal()]
  },
  onSuccess: {
    notification: {
      data: { code: 14 },
      staticNotification: true
    },
    trigAction: [
      toggleAccessDataroomModal(),
      function (payload) {
        return getProjectDetails(payload.projectId);
      }
    ]
  }
};

export default function* watchProjectDataRoomRequest() {
  yield takeEvery(
    ActionTypes.GET_PROJECT_DATA_ROOM_REQUEST_LIST,
    patternSaga,
    projectDataRoomRequestSagaConfig
  );
  yield takeLatest(
    ActionTypes.PROJECT_DATA_ROOM_REQUEST_APPROVE,
    patternSaga,
    projectDataRoomRequestApproveSagaConfig
  );
  yield takeLatest(
    ActionTypes.PROJECT_DATA_ROOM_REQUEST_REJECT,
    patternSaga,
    projectDataRoomRequestRejectSagaConfig
  );
  yield takeLatest(
    ActionTypes.SEND_DATA_ROOM_ACCESS_REQUEST,
    patternSaga,
    sendDataRoomAccessRequestSagaConfig
  );
}
