import THEMES from 'shared/constants/themes';

export default {
  user: {
    isFetching: false,
    info: {
      theme: THEMES.WHITE
    },
    cv: {},
    kyc: {
      documents: []
    },
    asideNav: {
      isOpen: true
    },
    investor: {
      publishedProjects: {},
      projectDetails: {
        firstTimeView: null
      }
    },
    strategicPartners: {
      exists: false,
      loading: false,
      detailsFetched: false,
      adminsList: {},
      workersList: {},
      investorsList: {},
      issuersList: {},
      investorsCreationDocumentsList: {},
      issuersCreationDocumentsList: {},
      companyKYC: {}
    },
    privateBankers: []
  },
  statistic: {
    userStatistic: {
      totalUserCount: 0,
      investmentStatistic: {
        count: 0,
        sum: 0
      },
      userTypeCounts: [
        {
          userType: 'ISSUER',
          count: 0
        },
        {
          userType: 'INVESTOR',
          count: 0
        },
        {
          userType: 'ADVISOR',
          count: 0
        },
        {
          userType: 'ADMIN_STRATEGIC_PARTNER',
          count: 0
        }
      ]
    },
    adminStatistic: {
      totalAum: 0,
      dryPowder: 0
    },
    projectsStatistic: {
      percentagesByCategory: [
        {
          productCategory: 'Equity - private company',
          percentage: 0
        },
        {
          productCategory: 'Structured products',
          percentage: 0
        },
        {
          productCategory: 'Equity - venture capital',
          percentage: 0
        },
        {
          productCategory: 'Debt',
          percentage: 0
        },
        {
          productCategory: 'Managed funds',
          percentage: 0
        },
        {
          productCategory: 'Other',
          percentage: 0
        }
      ]
    }
  },
  confirmInvestor: {
    message: null
  },
  investmentWithCodeDetails: null,
  countries: [],
  regions: [],
  currencies: [],
  notifications: {
    unread: {}
  },
  prompt: {
    visibility: true
  },
  tags: [],
  target: {},
  spPlatform: {
    loading: false,
    fetched: false,
    config: null
  },
  projects: {
    editing: false,
    list: {
      saved: {},
      ongoing: {},
      pending: {},
      rejected: {},
      finished: {},
      finishedFavorites: {},
      ongoingFavorites: {},
      successfullyFinished: {}
    },
    savingProject: {
      files: []
    },
    editingProject: {
      endDate: null,
      fullDescription: null,
      maxAmount: null,
      ndaForDataRoom: false,
      ndaForDataRoomWithApproval: false,
      picture: null,
      shortDescription: null,
      target: null,
      currency: null,
      targetAmount: null,
      targetedReturns: null,
      title: null,
      rejectionsHistory: [],
      files: [],
      published: false
    },
    initialProjectValues: {
      endDate: null,
      fullDescription: null,
      maxAmount: null,
      ndaForDataRoom: false,
      ndaForDataRoomWithApproval: false,
      picture: null,
      shortDescription: null,
      target: null,
      currency: null,
      targetAmount: null,
      targetedReturns: null,
      title: null,
      rejectionsHistory: [],
      files: [],
      published: false
    },
    uploadedVideo: {
      link: null,
      previewPicture: null
    },
    allProjectData: {},
    rejectionsHistory: {},
    allProjectsList: {},
    investmentsList: {},
    accessRequestProjectDataRoomList: {},
    approvalRequestProjectDataRoomList: {}
  },
  modals: {
    investModalIsOpen: false,
    investPrivateBankersModalIsOpen: false,
    issuerContactModalIsOpen: false,
    isInvestCodeModalOpened: false,
    isInvestWithCodeConfirmModalOpened: false,
    privateBankerModalIsOpen: false,
    spModalIsOpen: false,
    repayModalIsOpen: false,
    isAccessDataroomModalOpened: false,
    isFirstTimeViewModal: {
      isOpen: false,
      projectId: null
    },
    spModalToggle: {
      isOpen: false,
      selectedItemId: null
    }
  },
  NDA: {},
  payments: {
    platformCommission: {
      percent: null
    },
    cards: [],
    bankAccounts: [],
    subscription: null,
    wallet: [
      {
        id: null,
        currency: null,
        amount: 0
      }
    ],
    bankCredetials: {},
    subscriptionHistory: {}
  },
  search: {
    projects: {
      filters: [],
      labels: [],
      requestData: {},
      lastRequestedFilters: [],
      searchBarIsOpen: false
    }
  }
};
