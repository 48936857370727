import { takeLatest } from 'redux-saga/effects';
import { call, put } from 'redux-saga/effects';

import Api from '../api';
import notification from '../utils/notifications';
import { ActionTypes } from 'shared/constants';

function* fetchCurrenciesSaga() {
  yield put({
    type: ActionTypes.FETCH_CURRENCIES_STARTED
  });

  try {
    const { response, request } = yield call(Api.Projects.fetchCurrencies);

    if (request.status && (request.status === 200 || request.status === 204)) {
      yield put({
        type: ActionTypes.FETCH_CURRENCIES_SUCCESS,
        payload: {
          currencies: response.content
        }
      });
    } else {
      yield call(notification, { type: 'error', data: response });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.FETCH_CURRENCIES_FAILURE
    });
  }
}

export default function* watchCurrencies() {
  yield takeLatest(ActionTypes.FETCH_CURRENCIES, fetchCurrenciesSaga);
}
