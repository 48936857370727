import { ActionTypes } from '../constants';
import initialState from '../store/initialState';
import THEMES from 'shared/constants/themes';
import { ActionTypes as sharedActionTypes } from 'shared/constants';

export default (state = initialState.user, action) => {
  switch (action.type) {
    case sharedActionTypes.USER_INFORMATION_REQUEST:
    case sharedActionTypes.USER_INFORMATION_REQUEST_STARTED:
      return {
        ...state,
        isFetching: true
      };
    case sharedActionTypes.USER_INFORMATION_REQUEST_FAILURE:
    case sharedActionTypes.USER_INFORMATION_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        info: action.message
      };
    case ActionTypes.ASIDE_NAV_TOGGLE:
      return {
        ...state,
        asideNav: {
          ...state.asideNav,
          isOpen: !state.asideNav.isOpen
        }
      };
    case ActionTypes.ASIDE_NAV_SET_STATUS:
      return {
        ...state,
        asideNav: {
          ...state.asideNav,
          isOpen: action.status
        }
      };
    case ActionTypes.INVESTOR_GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        investor: {
          ...state.investor,
          projectDetails: action.payload
        }
      };

    case ActionTypes.INVESTOR_CLEAR_PROJECT_DETAILS:
      return {
        ...state,
        investor: {
          ...state.investor,
          projectDetails: {
            title: state.investor.projectDetails.title,
            pendingDataRoomAccessRequestCount:
              state.investor.projectDetails.pendingDataRoomAccessRequestCount
          }
        }
      };

    case ActionTypes.INVESTOR_UPDATE_LIKE_PROJECT_STARTED:
      return {
        ...state,
        investor: {
          ...state.investor,
          projectDetails: {
            ...state.investor.projectDetails,
            likesCounter:
              action.param === 'like'
                ? state.investor.projectDetails.likesCounter + 1
                : state.investor.projectDetails.likesCounter - 1,
            liked: !state.investor.projectDetails.liked
          }
        }
      };

    case ActionTypes.INVESTOR_UPDATE_FAVORITE_PROJECT_STARTED:
      return {
        ...state,
        investor: {
          ...state.investor,
          projectDetails: {
            ...state.investor.projectDetails,
            favorited: !state.investor.projectDetails.favorited
          }
        }
      };

    case ActionTypes.GET_SP_ISSUERS_CREATION_DOCUMENTS_SUCCESS:
      return {
        ...state,
        strategicPartners: {
          ...state.strategicPartners,
          issuersCreationDocumentsList: action.payload
        }
      };

    case ActionTypes.GET_SP_INVESTORS_CREATION_DOCUMENTS_SUCCESS:
      return {
        ...state,
        strategicPartners: {
          ...state.strategicPartners,
          investorsCreationDocumentsList: action.payload
        }
      };

    case ActionTypes.SP_ADMINS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        strategicPartners: {
          ...state.strategicPartners,
          adminsList: action.payload
        }
      };

    case ActionTypes.SP_WORKERS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        strategicPartners: {
          ...state.strategicPartners,
          workersList: action.payload
        }
      };

    case ActionTypes.SP_INVESTORS_LIST_FETCH_SUCCESS:
      return {
        ...state,
        strategicPartners: {
          ...state.strategicPartners,
          investorsList: action.payload
        }
      };

    case ActionTypes.SP_ISSUERS_LIST_FETCH_SUCCESS:
      return {
        ...state,
        strategicPartners: {
          ...state.strategicPartners,
          issuersList: action.payload
        }
      };

    case ActionTypes.GET_PRIVATE_BANKERS_LIST_SUCCESS:
      return {
        ...state,
        privateBankers: action.payload
      };

    case ActionTypes.GET_PLATFORM_DETAILS_STARTED:
      return {
        ...state,
        strategicPartners: {
          ...state.strategicPartners,
          loading: true,
          detailsFetched: false
        }
      };

    case ActionTypes.GET_PLATFORM_DETAILS_SUCCESS:
      return {
        ...state,
        strategicPartners: {
          ...state.strategicPartners,
          exists: action.payload.exists,
          loading: false,
          detailsFetched: true
        }
      };

    case ActionTypes.GET_PLATFORM_DETAILS_FAILURE:
      return {
        ...state,
        strategicPartners: {
          ...state.strategicPartners,
          loading: false,
          detailsFetched: true
        }
      };

    case ActionTypes.USER_LOGOUT_REQUEST:
      return {
        ...state,
        /*   asideNav: {
          ...state.asideNav,
          isOpen: false
        }, */
        info: {
          theme: THEMES.WHITE
        },
        cv: {},
        investor: {
          publishedProjects: {},
          projectDetails: {}
        },
        strategicPartners: {
          ...state.strategicPartners,
          adminsList: {},
          workersList: {},
          investorsList: {},
          issuersList: {}
        }
      };

    case ActionTypes.USER_CV_GET_REQUEST_SUCCESS:
      return {
        ...state,
        cv: action.payload
      };

    case ActionTypes.KYC_USER_STATUS_SUCCESS:
      return {
        ...state,
        KYCStatus: action.payload
      };

    case ActionTypes.KYC_DOCUMENTS_STATUS_SUCCESS:
      return {
        ...state,
        kyc: {
          documents: action.payload
        }
      };

    case ActionTypes.SP_ADMIN_KYC_REQUEST_SUCCESS:
      return {
        ...state,
        strategicPartners: {
          ...state.strategicPartners,
          companyKYC: action.payload
        }
      };

    default:
      return state;
  }
};
