import { takeLatest } from 'redux-saga/effects';
import { call, put } from 'redux-saga/effects';

import Api from '../api';
import notification from '../utils/notifications';
import { ActionTypes } from 'shared/constants';

function* getRegionsSaga() {
  yield put({
    type: ActionTypes.GET_REGIONS_STARTED
  });

  try {
    const { response, request } = yield call(Api.User.getRegions);

    if (request.status && (request.status === 200 || request.status === 204)) {
      yield put({
        type: ActionTypes.GET_REGIONS_SUCCESS,
        payload: {
          regions: response
        }
      });
    } else {
      yield call(notification, { type: 'error', data: response });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.GET_REGIONS_FAILURE
    });
  }
}

export default function* watchGetRegions() {
  yield takeLatest(ActionTypes.GET_REGIONS, getRegionsSaga);
}
