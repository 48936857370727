import { takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import patternSaga from './patternSaga';

const projectsSearchGetResult = {
  spinner: true,
  requestFunction: Api.Search.getProjectsSearchResult,
  requestUrl: `${utils.config.apiProjectUrl}/projects/search`,
  onFailure: {
    notification: true
  }
};

export default function* watchSearchSaga() {
  yield takeLatest(ActionTypes.PROJECTS_SEARCH_GET_RESULT, patternSaga, projectsSearchGetResult);
}
