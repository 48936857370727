import { takeLatest } from 'redux-saga/effects';
import { call, put } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import notification from '../utils/notifications';
import { ActionTypes as sharedActionTypes } from 'shared/constants';

function* signNDA({ url = 'mutual-agreement/documents', id = '' }) {
  yield put({
    type: ActionTypes.SHOW_SPINNER
  });
  try {
    yield put({
      type: ActionTypes.SIGN_NDA_STARTED,
      payload: {
        requestFrom: 'SignNDA'
      }
    });
    let signUrl = `${utils.config.apiPandaDocUrl}/${url}/${id}`;
    const { response, request, status } = yield call(Api.User.signNDA, signUrl);
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.SIGN_NDA_SUCCESS,
        payload: response
      });
    } else if (request && request.status === 401) {
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST
      });
    } else {
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
      yield put({
        type: ActionTypes.SIGN_NDA_FAILURE,
        payload: response
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.SIGN_NDA_FAILURE,
      payload: message
    });
  } finally {
    yield put({
      type: ActionTypes.SIGN_NDA_FINISHED,
      payload: {
        requestFrom: 'signNDA'
      }
    });
    yield put({
      type: ActionTypes.HIDE_SPINNER
    });
  }
}

function* isSignNDA({ url = 'mutual-agreement/documents', id = '' }) {
  yield put({
    type: ActionTypes.SHOW_SPINNER
  });
  try {
    yield put({
      type: ActionTypes.IS_SIGN_NDA_STARTED,
      payload: {
        requestFrom: 'isSignNDA'
      }
    });
    let isSignUrl = `${utils.config.apiPandaDocUrl}/${url}/${id}/is-signed`;

    const { response, request, status } = yield call(Api.User.isSignNDA, isSignUrl);
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.IS_SIGN_NDA_SUCCESS,
        payload: response
      });
      yield put({
        type: sharedActionTypes.USER_INFORMATION_REQUEST
      });
    } else if (request && request.status === 401) {
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
      yield put({
        type: ActionTypes.USER_LOGOUT_REQUEST
      });
    } else {
      yield call(notification, {
        type: 'error',
        data: response,
        status
      });
      yield put({
        type: ActionTypes.IS_SIGN_NDA_FAILURE,
        payload: response
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.IS_SIGN_NDA_FAILURE,
      payload: message
    });
  } finally {
    yield put({
      type: ActionTypes.IS_SIGN_NDA_FINISHED,
      payload: {
        requestFrom: 'isSignNDA'
      }
    });
    if (id) {
      yield put({
        type: ActionTypes.INVESTOR_GET_PROJECT_DETAILS,
        id
      });
    }
    yield put({
      type: ActionTypes.HIDE_SPINNER
    });
  }
}

export function* watchSignNDA() {
  yield takeLatest(ActionTypes.IS_SIGN_NDA, isSignNDA);
  yield takeLatest(ActionTypes.SIGN_NDA, signNDA);
}
