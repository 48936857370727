import React from 'react';
import { ThemedSvg } from 'shared/styled/components';

export default ({ styles }) => (
  <ThemedSvg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
  >
    <g id="project-list-1200">
      <g
          id="_x31_.6.2-Payment-flow-_x28_company_x29_"
          transform="translate(-868.000000, -649.000000)"
      >
        <g id="stepper"
            transform="translate(868.000000, 168.000000)">
          <g id="Group-19"
              transform="translate(0.000000, 270.000000)">
            <g id="Group-3-Copy-4"
                transform="translate(0.000000, 211.000000)">
              <polygon points="12.9,4.9 11.9,4.9 11.9,12.7 7.9,12.7 7.9,13.7 12.9,13.7 					" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </ThemedSvg>
);
