import AsideNav from './AsideNav';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  asideNavToggleAction,
  setUserStepperStatusAction,
  asideNavSetStatusAction
} from '../../actions/userDataActions';

const mapDispatchToProps = dispatch => ({
  asideNavToggle: () => dispatch(asideNavToggleAction()),
  setUserStepperStatus: () => dispatch(setUserStepperStatusAction()),
  asideNavSetStatus: status => dispatch(asideNavSetStatusAction(status))
});

const mapStateToProps = ({
  user: {
    asideNav,
    info: { status }
  }
}) => ({
  asideNav,
  status
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AsideNav);
