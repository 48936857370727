import { ActionTypes } from '../constants';

export const loginIssuerAction = data => ({
  type: ActionTypes.USER_LOGIN_REQUEST,
  data
});

export const signOut = () => ({
  type: ActionTypes.USER_LOGOUT_REQUEST
});
