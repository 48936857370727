import { ActionTypes } from '../constants';
import PROJECT_TYPES from '../constants/projectTypes';
// Issuers projects actions

export const getIssuerSavedProjects = (
  page = {},
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.ISSUER_GET_SAVED_PROJECTS,
  requestConfig: {
    projectsType: PROJECT_TYPES.SAVED,
    projection: 'issuerSaved',
    sort: `${sort.field},${sort.order}`,
    page: (page && page.current && page.current - 1) || 0,
    size: 10
  }
});

export const getIssuerOngoingProjects = (
  page = {},
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.ISSUER_GET_ONGOING_PROJECTS,
  requestConfig: {
    projectsType: PROJECT_TYPES.ONGOING,
    projection: 'issuerPublished',
    sort: `${sort.field},${sort.order}`,
    page: (page && page.current && page.current - 1) || 0,
    size: 10
  }
});

export const getIssuerFinishedProjects = (
  page = {},
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.ISSUER_GET_FINISHED_PROJECTS,
  requestConfig: {
    projectsType: PROJECT_TYPES.FINISHED,
    projection: 'issuerPublished',
    sort: `${sort.field},${sort.order}`,
    page: (page && page.current && page.current - 1) || 0,
    size: 10
  }
});

export const getIssuerPendingProjects = (
  page = {},
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.ISSUER_GET_PENDING_PROJECTS,
  requestConfig: {
    projectsType: PROJECT_TYPES.PENDING,
    projection: 'pending',
    sort: `${sort.field},${sort.order}`,
    page: (page && page.current && page.current - 1) || 0,
    size: 10
  }
});

// SP Issuer projects action

export const getSPIssuerSavedProjects = (
  page = {},
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.SP_ISSUER_GET_SAVED_PROJECTS,
  requestConfig: {
    projectsType: 'saved',
    projection: 'issuerSaved',
    sort: `${sort.field},${sort.order}`,
    page: (page && page.current && page.current - 1) || 0,
    size: 10
  }
});

export const getSPIssuerOngoingProjects = (
  page = {},
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.SP_ISSUER_GET_ONGOING_PROJECTS,
  requestConfig: {
    projectsType: 'ongoing',
    projection: 'issuerPublished',
    sort: `${sort.field},${sort.order}`,
    page: (page && page.current && page.current - 1) || 0,
    size: 10
  }
});

export const getSPIssuerFinishedProjects = (
  page = {},
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.SP_ISSUER_GET_FINISHED_PROJECTS,
  requestConfig: {
    projectsType: 'finished',
    projection: 'issuerPublished',
    sort: `${sort.field},${sort.order}`,
    page: (page && page.current && page.current - 1) || 0,
    size: 10
  }
});

export const getSPIssuerPendingProjects = (
  page = {},
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.SP_ISSUER_GET_PENDING_PROJECTS,
  requestConfig: {
    projectsType: 'pending',
    projection: 'pending',
    sort: `${sort.field},${sort.order}`,
    page: (page && page.current && page.current - 1) || 0,
    size: 10
  }
});

// SP projects actions

export const getSPSavedProjects = (
  page,
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.GET_SAVED_SP_PROJECTS,
  requestConfig: {
    page: (page && page.current && page.current - 1) || 0,
    sort: `${sort.field},${sort.order}`,
    size: 20,
    projection: 'spSaved',
    url: 'saved'
  }
});

export const getSPOngoingProjects = (
  page,
  filters,
  sort = { field: 'publishedDate', order: 'desc' }
) => ({
  type: ActionTypes.GET_ONGOING_SP_PROJECTS,
  requestConfig: {
    page: (page && page.current && page.current - 1) || 0,
    sort: `${sort.field},${sort.order}`,
    size: 20,
    projection: 'spPublished',
    url: 'ongoing'
  }
});

export const getSPFinishedProjects = (
  page,
  filters,
  sort = { field: 'publishedDate', order: 'desc' }
) => ({
  type: ActionTypes.GET_FINISHED_SP_PROJECTS,
  requestConfig: {
    page: (page && page.current && page.current - 1) || 0,
    sort: `${sort.field},${sort.order}`,
    size: 20,
    projection: 'spPublished',
    url: 'finished'
  }
});

export const getSPPendingProjects = (
  page,
  filters,
  sort = { field: 'publishedDate', order: 'desc' }
) => ({
  type: ActionTypes.GET_PENDING_SP_PROJECTS,
  requestConfig: {
    sort: `${sort.field},${sort.order}`,
    projection: 'pending',
    size: 20,
    page: (page && page.current && page.current - 1) || 0,
    url: 'pending'
  }
});

export const getSPRejectedProjects = (
  page,
  filters,
  sort = { field: 'publishedDate', order: 'desc' }
) => ({
  type: ActionTypes.GET_REJECTED_SP_PROJECTS,
  requestConfig: {
    projection: 'rejected',
    sort: `${sort.field},${sort.order}`,
    size: 20,
    page: (page && page.current && page.current - 1) || 0,
    url: 'rejected'
  }
});

// Project details actions

export const getProjectDetails = id => ({
  type: ActionTypes.INVESTOR_GET_PROJECT_DETAILS,
  id
});

export const clearProjectDetails = () => ({
  type: ActionTypes.INVESTOR_CLEAR_PROJECT_DETAILS
});

export const approveProject = id => ({
  type: ActionTypes.PROJECT_APPROVE_REQUEST,
  requestConfig: {
    id
  }
});

export const rejectProject = (id, data) => ({
  type: ActionTypes.PROJECT_REJECT_REQUEST,
  requestConfig: {
    id,
    data
  }
});

// Project rejections history actions

export const getRejectionsHistory = id => ({
  type: ActionTypes.PROJECT_REJECTIONS_HISTORY_GET_REQUEST,
  requestConfig: {
    id,
    projection: 'rejectionsHistory'
  }
});

export const clearRejectionsHistory = id => ({
  type: ActionTypes.PROJECT_REJECTIONS_HISTORY_CLEAR
});

// Other actions

export const removeProject = (id, page) => ({
  type: ActionTypes.REMOVE_PROJECT,
  id,
  page
});

export const getAllStatistic = () => ({
  type: ActionTypes.GET_ALL_STATISTIC
});

export const getAllProjectData = id => ({
  type: ActionTypes.PROJECT_ALL_DATA_GET_REQUEST,
  requestConfig: {
    id,
    projection: 'all'
  }
});

export const clearAllProjectData = () => ({
  type: ActionTypes.PROJECT_ALL_DATA_CLEAR
});

export const getInvestorsProjectsAction = (
  projectsType,
  { size = 20, current = 1 } = {},
  { field = 'executionDate', order = 'desc' } = {}
) => ({
  type: ActionTypes[`GET_INVESTORS_${projectsType}_PROJECTS`],
  requestConfig: {
    projectsType: PROJECT_TYPES[projectsType],
    sort: `${field},${order}`,
    projection: PROJECT_TYPES[projectsType],
    size: size,
    page: current - 1
  }
});

export const getAdvisorProjetsAction = (
  projectsType,
  page,
  sort = { field: 'executionDate', order: 'desc' }
) => ({
  type: ActionTypes[`GET_ADVISOR_${projectsType}_PROJECTS`],
  requestConfig: {
    projectsType: PROJECT_TYPES[projectsType],
    sort: `${sort.field},${sort.order}`,
    projection: PROJECT_TYPES[projectsType],
    size: 20,
    page: (page && page.current && page.current - 1) || 0
  }
});

export const getDataRoomRequestList = (projectId, listType, page) => {
  return {
    type: ActionTypes.GET_PROJECT_DATA_ROOM_REQUEST_LIST,
    requestConfig: {
      projectId,
      sort: 'createdAt,desc',
      size: 20,
      page: (page && page.current && page.current - 1) || 0,
      listType,
    }
  };
};

export const projectDataRoomRequestApprove = (projectId, userId, listType, callBackOnSuccess = null) => {
  return {
    type: ActionTypes.PROJECT_DATA_ROOM_REQUEST_APPROVE,
    requestConfig: {
      projectId,
      userId,
      listType
    },
    dispatchCallBack: {
      onSuccess: callBackOnSuccess
    }
  };
};

export const projectDataRoomRequestReject = (
  projectId,
  userId,
  listType,
  reason,
  callBackOnSuccess = null
) => {
  return {
    type: ActionTypes.PROJECT_DATA_ROOM_REQUEST_REJECT,
    requestConfig: {
      projectId,
      userId,
      listType,
      reason
    },
    dispatchCallBack: {
      onSuccess: callBackOnSuccess
    }
  };
};

export const sendDataroomAccessRequest = (projectId, data) => {
  return {
    type: ActionTypes.SEND_DATA_ROOM_ACCESS_REQUEST,
    requestConfig: {
      projectId,
      data
    }
  };
};

export const acceptWarningAndDisclaimer = (projectId) => {
  return {
    type: ActionTypes.PROJECT_ACCEPT_WARNING_AND_DISCLAIMER,
    requestConfig: {
      projectId
    }
  };
};

export const sendIssuerContactRequest = (projectId, data) => {
  return {
    type: ActionTypes.SEND_ISSUER_CONTACT_REQUEST,
    requestConfig: {
      projectId,
      data
    }
  };
};

export const isFirstTimeView = projectId => ({
  // in shared
  type: ActionTypes.PROJECT_FIRST_TIME_VIEW_CHECK,
  payload: { projectId }
});

export const updateLike = (projectId, param) => ({
  type: ActionTypes.INVESTOR_UPDATE_LIKE_PROJECT,
  projectId,
  param
});

export const updateFavorite = (projectId, param) => ({
  type: ActionTypes.INVESTOR_UPDATE_FAVORITE_PROJECT,
  projectId,
  param
});

export const getInvestmentsList = (
  projectId,
  page = {},
  sort = { field: 'createdAt', order: 'desc' }
) => {
  return ({
    type: ActionTypes.GET_PROJECT_INVESTMENTS_LIST,
    requestConfig: {
      projectId,
      sort: `${sort.field},${sort.order}`,
      page: (page && page.current && page.current - 1) || 0,
      size: 20
    }
  });
};
