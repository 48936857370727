import { takeLatest } from 'redux-saga/effects';
import { call, put } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import Api from '../api';
import notification from '../utils/notifications';
import { apiUrl, version } from '../utils/config';

const apiUsersUrl = `${apiUrl}/user/api/${version}/public/statistics`;

function* projectsStatistic() {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  try {
    yield put({ type: ActionTypes.GET_ALL_STATISTIC_STARTED });

    const { response, request, status } = yield call(Api.Projects.getProjectsStatistic, apiUsersUrl);
    if (request.status && (request.status === 200 || request.status === 204)) {
      yield put({
        type: ActionTypes.GET_ALL_STATISTIC_SUCCESS,
        payload: response
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield call(notification, { type: 'error', data: response });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.GET_ALL_STATISTIC_FAILURE,
      message
    });
  } finally {
    yield put({ type: ActionTypes.GET_ALL_STATISTIC_FINISHED });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

export default function* watchStatistics() {
  yield takeLatest(ActionTypes.GET_ALL_STATISTIC, projectsStatistic);
}
