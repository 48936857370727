import { ActionTypes } from '../constants';
import initialState from '../store/initialState';

export default (state = initialState.investmentWithCodeDetails, action) => {
  switch (action.type) {
    case ActionTypes.SET_INVESTMENT_WITH_CODE_DETAILS: {
      const { details } = action.payload;

      return { ...state, ...details };
    }

    case ActionTypes.CLEAR_INVESTMENT_WITH_CODE_DETAILS: {
      return null;
    }

    default:
      return state;
  }
};
