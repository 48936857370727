import { ActionTypes } from '../constants/index';

export const pushNotificationsConnectAction = () => ({
  type: ActionTypes.CONNECT_WEBSOCKET_NOTIFICATIONS
});

export const pushNotificationsDisconnectAction = () => ({
  type: ActionTypes.DISCONNECT_WEBSOCKET_NOTIFICATIONS
});

export const getUnreadNotificationsCountAction = () => ({
  type: ActionTypes.GET_UNREAD_NOTIFICATIONS_COUNT
});
