import { ActionTypes } from 'shared/constants';
import initialState from '../store/initialState';

export default (state = initialState.spPlatform, action) => {
  switch (action.type) {
    case ActionTypes.GET_SP_PLATFORM_CONFIG_STARTED: {
      return {
        ...state,
        loading: true,
        fetched: false,
        config: null
      };
    }
    case ActionTypes.GET_SP_PLATFORM_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        config: action.payload,
        fetched: true
      };
    case ActionTypes.GET_SP_PLATFORM_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        config: null,
        fetched: true
      };
    }
    default:
      return state;
  }
};
