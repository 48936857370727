import { takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import Api from '../api';
import patternSaga from './patternSaga';
import { toggleIssuerContactModal } from '../actions/modalsActions';

const sendIssuerContactRequestSaga = {
    spinner: true,
    requestFunction: Api.Projects.sendIssuerContactRequest,
    onFailure: {
        notification: true,
        trigAction: [
            toggleIssuerContactModal()
        ]
    },
    onSuccess: {
        notification: {
            data: { code: 14 },
            staticNotification: true
        },
        trigAction: [
            toggleIssuerContactModal(),
        ]
    }
};

export default function* watchIssuerContactDirectly() {
    yield takeLatest(
        ActionTypes.SEND_ISSUER_CONTACT_REQUEST,
        patternSaga,
        sendIssuerContactRequestSaga
    );
}
