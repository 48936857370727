import { takeLatest } from 'redux-saga/effects';
import { call, put } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import { ActionTypes as sharedActionTypes } from 'shared/constants';
import utils, { urlPrefixValidation } from '../utils';
import Api from '../api';
import notification from '../utils/notifications';

const apiUrl = `${utils.config.apiFilesUrl}/users?type=LOGO`;
const apiLogoUrl = `${utils.config.apiUserUrl}/user/me/logo`;
const apiUpdateUserInfo = `${utils.config.apiUserUrl}/user/me`;

function* SPUpdateLogo({ file }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  try {
    yield put({ type: ActionTypes.SP_UPDATE_LOGO_STARTED });

    const { response, request, status } = yield call(Api.User.uploadLogo, apiUrl, file);
    if (request.status && (request.status === 200 || request.status === 204)) {
      yield put({
        type: ActionTypes.SP_UPDATE_LOGO_SUCCESS,
        payload: response
      });
      yield put({
        type: ActionTypes.SP_PUT_LOGO_URL,
        fileId: response.id,
        link: response.link,
        width: response.width,
        height: response.height,
        showNotification: true
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield call(notification, { type: 'error', data: response });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.SP_UPDATE_LOGO_FAILURE,
      message
    });
  } finally {
    yield put({ type: ActionTypes.SP_UPDATE_LOGO_FINISHED });
  }
}

function* SPPutLogoUrl({
  fileId = null, link = null, width = null, height = null, showNotification = false
}) {
  let data = {
    fileId,
    link,
    width,
    height
  };
  try {
    yield put({ type: ActionTypes.SP_PUT_LOGO_URL_STARTED });
    const { response, request, status } = yield call(Api.User.updateLogoUrl, apiLogoUrl, data);
    if (request.status && (request.status === 200 || request.status === 204)) {
      const description = {
        description: 'Your logo has been changed!'
      };
      if (showNotification) {
        yield call(notification, {
          type: 'success',
          data: { ...description }
        });
      }
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield call(notification, { type: 'error', data: response });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.SP_PUT_LOGO_URL_FAILURE,
      message
    });
  } finally {
    yield put({ type: ActionTypes.SP_PUT_LOGO_URL_FINISHED });
    yield put({ type: sharedActionTypes.USER_INFORMATION_REQUEST });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* SPKYCCHeck({ value }) {
  try {
    value = { ...value, website: urlPrefixValidation(value.website) };
    yield put({ type: ActionTypes.STRATEGIC_PARTNER_KYC_CHECK_REQUEST_STARTED });
    yield put({ type: ActionTypes.SHOW_SPINNER });
    const { response, request, status } = yield call(Api.User.SPKYCcheck, apiUpdateUserInfo, value);
    if (request.status && (request.status === 200 || request.status === 204)) {
      yield put({ type: sharedActionTypes.USER_INFORMATION_REQUEST });
      const description = 'Information was saved.';
      yield call(notification, {
        type: 'success',
        data: { description }
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield call(notification, { type: 'error', data: response });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.STRATEGIC_PARTNER_KYC_CHECK_REQUEST_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.STRATEGIC_PARTNER_KYC_CHECK_REQUEST_FINISHED
    });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

export default function* watchSPUpdateLogo() {
  yield takeLatest(ActionTypes.SP_UPDATE_LOGO, SPUpdateLogo);
  yield takeLatest(ActionTypes.SP_PUT_LOGO_URL, SPPutLogoUrl);
  yield takeLatest(ActionTypes.STRATEGIC_PARTNER_KYC_CHECK_REQUEST, SPKYCCHeck);
}
