import { ActionTypes } from 'shared/constants';
import initialState from '../store/initialState';

export default (state = initialState.currencies, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CURRENCIES_SUCCESS:
      return action.payload.currencies;

    default:
      return state;
  }
};
