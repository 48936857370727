import { ActionTypes } from '../constants';
import initialState from '../store/initialState';

export default (state = initialState.confirmInvestor, { type, message }) => {
  switch (type) {
    case ActionTypes.INVESTOR_CONFIRM_REQUEST_FAILURE:
    case ActionTypes.INVESTOR_CONFIRM_REQUEST_SUCCESS:
      return { ...state, message };
    default:
      return state;
  }
};
