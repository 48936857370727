import { ActionTypes } from 'shared/constants';
import initialState from '../store/initialState';

export default (state = initialState.regions, action) => {
  switch (action.type) {
    case ActionTypes.GET_REGIONS_SUCCESS:
      return action.payload.regions;

    default:
      return state;
  }
};
