import { takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import patternSaga from './patternSaga';

const tagsSagaConfig = {
  requestFunction: Api.Projects.getAllTags,
  requestUrl: `${utils.config.apiProjectUrl}/tags`,
  onFailure: {
    notification: true
  }
};

export function* watchTagsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_TAGS, patternSaga, tagsSagaConfig);
}
