import { takeLatest } from 'redux-saga/effects';
import { call, put } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import NetworkLayer from '../utils/networkLayer';
import utils from '../utils';
import { getSpPlatformConfig } from 'shared/actions/spPlatformConfigActions';

// TO mock subdomain
const subDomain = /* 'spp-qa' || */ window.location.host.split('.')[0];

function* getPlatformDetails() {
  try {
    yield put({
      type: ActionTypes.SHOW_SPINNER
    });

    yield put({ type: ActionTypes.GET_PLATFORM_DETAILS_STARTED });

    let { response, request } = yield call(
      NetworkLayer.getJson,
      `${utils.config.apiUserUrl}/public/user/subdomain/exists`
    );

    // TO mock subdomain
    /*  response = {
      exists: true
    }; */

    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.GET_PLATFORM_DETAILS_SUCCESS,
        payload: response
      });

      if (response && response.exists) {
        yield put(getSpPlatformConfig(subDomain));
      }
    }
  } catch (error) {
    yield put({
      type: ActionTypes.GET_PLATFORM_DETAILS_FAILURE,
      message: error.message,
      statusCode: error.status
    });
  } finally {
    yield put({ type: ActionTypes.GET_PLATFORM_DETAILS_FINISHED });
    yield put({
      type: ActionTypes.HIDE_SPINNER
    });
  }
}

export default function* watchGetPlatformDetails() {
  yield takeLatest(ActionTypes.GET_PLATFORM_DETAILS, getPlatformDetails);
}
