import { takeLatest } from 'redux-saga/effects';
import { call, put, select } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import { convertCodeToMessage } from '../utils/responseCodeToMessage';
import { history } from 'shared/routes/urlLocations';
import notification from '../utils/notifications';

function* saveCreateProjectFile({ link, field, fileType, onFailure }) {
  const saveFileUrl = `${utils.config.apiProjectFilesUrl}/temp?type=${fileType}`;
  try {
    yield put({ type: ActionTypes.SHOW_SPINNER });
    yield put({
      type: ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILE_STARTED,
      payload: {
        requestFrom: 'saveFile'
      }
    });

    const { response, request, status } = yield call(Api.User.saveFile, saveFileUrl, link);
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILE_SUCCESS
      });
      yield put({
        type: ActionTypes.ISSUER_PROJECT_CREATE_ADD_FILE_LINK,
        link: response,
        field
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield call(notification, {
        type: 'error',
        data: response
      });
      yield call(onFailure);
      yield put({
        type: ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILE_FAILURE,
        message: convertCodeToMessage(response.error.code)
      });
    }
  } catch ({ message }) {
    yield call(onFailure);
    yield put({
      type: ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILE_FAILURE,
      message
    });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({
      type: ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILE_FINISHED,
      payload: {
        requestFrom: 'saveFile'
      }
    });
  }
}

function* saveEditProjectFile({ link, field, fileType, id, onFailure }) {
  const saveFileUrl = `${utils.config.apiProjectFilesUrl}/${id}?type=${fileType}`;

  try {
    yield put({ type: ActionTypes.SHOW_SPINNER });
    yield put({
      type: ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILE_STARTED,
      payload: {
        requestFrom: 'saveFile'
      }
    });
    const { response, request, status } = yield call(Api.User.saveFile, saveFileUrl, link);
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILE_SUCCESS
        // ...result.data,
      });
      yield put({
        type: ActionTypes.ISSUER_PROJECT_EDIT_ADD_FILE_LINK,
        link: response,
        field
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield call(notification, {
        type: 'error',
        data: response
      });
      yield call(onFailure);
      yield put({
        type: ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILE_FAILURE,
        message: convertCodeToMessage(response.error.code)
      });
    }
  } catch ({ message }) {
    yield call(onFailure);
    yield put({
      type: ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILE_FAILURE,
      message
    });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({
      type: ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILE_FINISHED,
      payload: {
        requestFrom: 'saveFile'
      }
    });
  }
}

function* issuerProjectRemoveFileSaga({ id }) {
  yield put({
    type: ActionTypes.ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE_STARTED
  });
  const {
    projects: { initialProjectValues }
  } = yield select();
  try {
    const url = `${utils.config.apiFilesUrl}/projects/${initialProjectValues.id}/files/${id}`;
    const result = yield call(Api.Projects.issuerProjectDeletFile, url);
    if (result.status && result.status === 204) {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE_SUCCESS
      });
    } else if (result && result.status === 401) {
      yield sessionStorage.clear();
      yield history.push('/');
      yield call(notification, {
        type: 'error',
        data: 'Please, authorize again!'
      });
    } else {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE_FAILURE,
        message: convertCodeToMessage(result.data.error.code)
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE_FINISHED
    });
  }
}

export function* watchSaveCreateProjectFile() {
  yield takeLatest(ActionTypes.ISSUER_PROJECT_CREATE_SAVE_FILE, saveCreateProjectFile);
}

export function* watchSaveEditProjectFile() {
  yield takeLatest(ActionTypes.ISSUER_PROJECT_EDIT_SAVE_FILE, saveEditProjectFile);
}

export function* watchIssuerProjectRemoveFile() {
  yield takeLatest(
    ActionTypes.ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE,
    issuerProjectRemoveFileSaga
  );
}
