import { takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import Api from '../api';
import patternSaga from './patternSaga';

const projectInvestmentsSagaConfig = {
  requestFunction: Api.Projects.getInvestmentsList,
  onFailure: {
    notification: true
  }
};

export default function* watchProjectInvestments() {
  yield takeLatest(
    ActionTypes.GET_PROJECT_INVESTMENTS_LIST,
    patternSaga,
    projectInvestmentsSagaConfig
  );
}
