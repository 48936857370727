export default type => {
  if (type) {
    let registrationCardTitle = '';
    type.split('_').forEach(item => {
      registrationCardTitle += item[0] + item.substring(1).toLowerCase() + ' ';
    });
    sessionStorage.setItem('userType', type);
    sessionStorage.setItem('registrationCardTitle', registrationCardTitle);
  } else {
    sessionStorage.removeItem('userType');
  }
};
