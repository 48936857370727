import ActiveRecord from './ActiveRecord';
import Auth from './auth';
import Registration from './registration';
import User from './user';
import Project from './project';
import Notifications from './notifications';
import Search from './search';
import Template from './template';
import networkLayer from '../utils/networkLayer';

class Api extends ActiveRecord {
  constructor() {
    super();
    this._auth = new Auth();
    this._registration = new Registration();
    this._user = new User();
    this._creation = new Project();
    this._notifications = new Notifications();
    this._search = new Search();
    this._template = new Template();
    networkLayer.authToken = sessionStorage.getItem('isAuthenticated')
      && `${sessionStorage.token_type} ${sessionStorage.access_token}`;
  }

  get Auth() {
    return this._auth;
  }

  get Registration() {
    return this._registration;
  }

  get User() {
    return this._user;
  }

  get Projects() {
    return this._creation;
  }

  get Notifications() {
    return this._notifications;
  }

  get Search() {
    return this._search;
  }

  get Template() {
    return this._template;
  }

  set store(store) {
    this._store = store;

    // this._store.subscribe(() => this._updateToken())
    // this._updateToken()
  }

  get store() {
    return this._store;
  }

  get dispatch() {
    return this._store.dispatch;
  }

  // _updateToken() {
  //   let { auth: { token } } = this._store.getState()
  //   this._networkLayer.authToken = token
  // }
}

export default new Api();
