import React from 'react';
import { Router } from 'react-router';
import { Link } from 'react-router-dom';
import { Client } from '@stomp/stompjs/esm6';
import notification from 'shared/utils/notification';
import hash from 'object-hash';
import { ActionTypes } from '../constants';
import utils, { formatDate } from '../utils';
import {
  notifications as notificationsUrl,
  projectsMy,
  history,
  spSettings,
  spProjects,
  userProfile
} from 'shared/routes/urlLocations';
import { getNotification, notificationGroup } from 'shared/constants/notifications';
import { getUnreadNotificationsCountAction } from '../actions/pushNotificationsActions';
import {
  getSPPendingProjects,
  getIssuerOngoingProjects,
  getIssuerSavedProjects,
  getIssuerPendingProjects
} from '../actions/projectsActions';
import { fetchUserInformation } from 'shared/actions/userDataActions';
import { getWalletBalance } from '../actions/paymentsActions';
import { spInvestorsListFetch, spIssuersListFetch } from '../actions/strategicPartnersActions';
import IsNotification from 'shared/utils/isNotification';
import IsUser from 'shared/utils/user/IsUser';
import { signOut } from '../actions/authActions';

let stompClient = null;
const reconnectDelay = 5000;
const heartbeatIncoming = 5000;
const heartbeatOutgoing = 5000;

function pushNotificationsConnect(store) {
  let accessToken = sessionStorage.getItem('access_token');
  let userId = sessionStorage.getItem('userId');
  let tokenType = sessionStorage.getItem('token_type');
  const connectHeaders = { Authorization: `${tokenType} ${accessToken}` };

  store.dispatch(getUnreadNotificationsCountAction());

  stompClient = new Client({
    brokerURL: utils.config.pushNotificationUrl,
    connectHeaders,
    reconnectDelay,
    heartbeatIncoming,
    heartbeatOutgoing
  });

  stompClient.onConnect = frame => onConnect(store, userId, frame);
  stompClient.onStompError = function () {
    store.dispatch(signOut());
    pushNotificationsDisconnect();
  };

  stompClient.onDisconnect = function (onDisconnect) {
    stompClient && stompClient.deactivate();
  };

  stompClient.onStompError = function (onStompError) {
    stompClient && stompClient.deactivate();
  };

  stompClient.onWebSocketClose = function (onWebSocketClose) {
    stompClient && stompClient.deactivate();
  };

  stompClient.onWebSocketError = function (onWebSocketError) {
    stompClient && stompClient.deactivate();
  };

  // debugger;
  stompClient.activate();
}

function pushNotificationsDisconnect() {
  stompClient && stompClient.deactivate();
}

function onConnect(store, userId, frame) {
  const {
    user: {
      info: { userType }
    }
  } = store.getState();
  let userNotify = `/topic/notify.${userId}`;
  stompClient.subscribe(userNotify, req => {
    const { type, attributes } = JSON.parse(req.body);
    const notif = getNotification(type, userType);
    const tabName = notif.group === notificationGroup.USER
      ? `#${notificationGroup.USER}`
      : `#${notificationGroup.PROJECT}`;

    notification.open({
      id: hash({ type, attributes }),
      duration: 0,
      type: 'success',
      status: 'success',
      className: 'ant-notification-success',
      description: (
        <Router history={history}>
          <Link to={`${notificationsUrl}${tabName}`}>
            {`${formatDate(new Date(), 'DD.MM HH:mm')} : ${notif.pushTitle(attributes)}`}
          </Link>
        </Router>
      )
    });

    store.dispatch(getUnreadNotificationsCountAction());

    const isNotification = new IsNotification(type);

    if (isNotification.spSubscriptionActivated) {
      store.dispatch(fetchUserInformation());
    }

    if (
      isNotification.payInTransactionSuccessful
      || isNotification.payInTransactionFailed
      || isNotification.passedKyc
      || isNotification.kycDocumentRefused
      || isNotification.kycDocumentValidated
      || isNotification.mangopayAccountCreated
    ) {
      store.dispatch(fetchUserInformation());
    }

    if (isNotification.spUsersCreationSuccessful) {
      const isUser = new IsUser(attributes.userType);
      if (isUser.investorSP) {
        store.dispatch(spInvestorsListFetch());
      } else {
        store.dispatch(spIssuersListFetch());
      }
    }

    if (
      history.location.pathname === projectsMy
      && (isNotification.projectApproved || isNotification.projectRejected)
    ) {
      store.dispatch(getIssuerPendingProjects());
      store.dispatch(getIssuerOngoingProjects());
      store.dispatch(getIssuerSavedProjects());
    }

    if (
      (history.location.pathname === spSettings || history.location.pathname === userProfile)
      && history.location.hash === '#payment-methods'
      && isNotification.payInTransactionSuccessful
    ) {
      store.dispatch(getWalletBalance());
    }

    if (history.location.pathname === spProjects && isNotification.projectPublishedForReview) {
      store.dispatch(getSPPendingProjects());
    }
  });
}

function handleConnectionChange(event) {
  if (event.type === 'offline') {
    pushNotificationsDisconnect();
  }
  if (event.type === 'online') {
    document.location.reload(true);
  }
}

window.addEventListener('online', handleConnectionChange);
window.addEventListener('offline', handleConnectionChange);

const wsMiddleware = store => next => action => {
  switch (action.type) {
    case ActionTypes.CONNECT_WEBSOCKET_NOTIFICATIONS:
      pushNotificationsConnect(store);
      next(action);
      break;
    case ActionTypes.USER_LOGOUT_REQUEST:
    case ActionTypes.DISCONNECT_WEBSOCKET_NOTIFICATIONS:
      pushNotificationsDisconnect();
      next(action);
      break;
    default:
      next(action);
  }
  return store;
};

export default wsMiddleware;
